import React, { Component } from 'react'
import { Link } from 'react-router-dom';

const menu = [
    {
        title: 'Все мероприятия',
        section: '/',
        url: '/',
    },
    {
        title: 'Отчеты',
        section: '/reports',
        url: '/reports',
    },
    {
        title: 'Аналитика',
        section: '/3',
        url: '/analytics',
    },
    {
        title: 'Заказы',
        section: '/4',
        url: '/event-orders',
    },
    // {
    //     title: 'Проходная система',
    //     section: '/5',
    //     url: '/',
    // },
];

export default class ListLink extends Component {
    state = {
        page: false
    }

    componentDidMount() {
       this.setToLink();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
        this.setState({
            page: false
        })
    }
        this.setToLink();
    }

    setToLink = () => {
        menu.forEach(item => {
            if(item.section === this.props.location.pathname && (this.props.location.pathname !== this.state.page)) {
                this.setState({
                    page: item.section,
                })
            }
        })
    }

    render() {
        const { isShowMenuModal } = this.props;
        return (
            <nav className={`top-menu ${isShowMenuModal ? 'show' : ''}`}>
                <ul>
                    {menu &&
                        menu.map((item, i) =>
                            <li key={i}>
                                <Link to={item.url} className={`${this.state.page === item.section && "active"}`}>
                                    {item.title}
                                </Link>
                            </li>
                        )
                    }
                </ul>
            </nav>
        )
    }
}
