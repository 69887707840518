import { combineReducers } from 'redux';
import auth from './auth';
import event from './event';
import places from './places';
import cities from './cities';
import categories from './category';
import seatScheme from './seatScheme';
import orders from './orders';
import report from './report';

export default combineReducers({
    auth,
    event,
    places,
    cities,
    categories,
    seatScheme,
    orders,
    report
})