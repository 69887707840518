import React, { Component } from 'react';
import './style.scss';
import './media.scss';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';
import 'moment/locale/ru';
import { setSpecialBasket } from '../../api/special'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Spinner from "../Spinner";

class OrderReservation extends Component {
  constructor(props) {
    super(props);

    this.handleDayChange = this.handleDayChange.bind(this);

    this.state = {
      selectedDay: undefined,
      error: false,
      loader: false,
    }
  }

  handleDayChange = (day) => {
    this.setState({
      selectedDay: day
    })
  };

  render() {
    const { selectedDay } = this.state;
    const validateForm = Yup.object().shape({
      phone: Yup.string()
        .required('Обязательное поле')
        .nullable(),
      email: Yup.string()
        .required('Обязательное поле')
        .nullable(),
      customer_name: Yup.string()
        .required('Обязательное поле')
        .nullable(),
      description: Yup.string()
        .required('Обязательное поле')
        .nullable(),
    })

    return (
      <>
        <div className="order-reservation">
          <div className="modal-title">Введите данные для бронирования</div>
          <Formik
            validationSchema={validateForm}
            onSubmit={(values, formik) => {
              this.setState({
                error: false,
                loader: true,
              })
              this.props.activeLoader()
              setSpecialBasket({
                whole_basket: true,
                create_special_basket: true,
                phone: values.phone,
                email: values.email,
                customer_name: values.customer_name,
                description: values.description,
              })
                .then(res => {
                  this.props.setAccessSteps(3)
                  res.data.description = values.description
                  this.props.setReserved(res.data)
                  this.props.showOrderStep3()
                })
                .catch(er => {
                  this.setState({
                    error: er.response.data.text ? er.response.data.text : `Ошибка - ${er.response.data.error}`,
                    loader: false,
                  })
                })
                .finally(() => {
                  this.props.hideLoader()
                })
            }}
            initialValues={{
              phone: this.props.reserved.phone ? this.props.reserved.phone : '',
              email: this.props.reserved.email ? this.props.reserved.email : '',
              customer_name: this.props.reserved.customer_name ? this.props.reserved.customer_name : '',
              description: this.props.reserved.description ? this.props.reserved.description : '',
              date: null,
            }}
            render={({
              values,
              errors,
              setFieldValue,
              handleSubmit,
              isSubmitting,
              touched
              /* and other goodies */
            }) => (
                <Form
                  className="form-control-content-contacts"
                  onSubmit={handleSubmit}
                >
                  <div className="form-group form-flex">
                    <label>ФИО</label>
                    <div className="form-field">
                      <input
                        className={(touched.customer_name && errors.customer_name) ? 'form-field-error' : ''}
                        onChange={(e) => setFieldValue('customer_name', e.target.value)}
                        value={values.customer_name}
                        disabled={this.state.loader}
                        type="text" />
                    </div>
                  </div>
                  <div className="form-group form-flex">
                    <label>Email</label>
                    <div className="form-field">
                      <input
                        className={(touched.email && errors.email) ? 'form-field-error' : ''}
                        onChange={(e) => setFieldValue('email', e.target.value)}
                        disabled={this.state.loader}
                        value={values.email}
                        type="email" />
                    </div>
                  </div>
                  <div className="form-group form-flex">
                    <label>Телефон</label>
                    <div className="form-field">
                      <input
                        className={(touched.phone && errors.phone) ? 'form-field-error' : ''}
                        onChange={(e) => setFieldValue('phone', e.target.value)}
                        value={values.phone}
                        disabled={this.state.loader}
                        type="tel" />
                    </div>
                  </div>
                  <div className="form-group form-flex">
                    <label>Описание</label>
                    <div className="form-field">
                      <input
                        className={(touched.description && errors.description) ? 'form-field-error' : ''}
                        onChange={(e) => setFieldValue('description', e.target.value)}
                        disabled={this.state.loader}
                        value={values.description}
                        type="text" />
                    </div>
                  </div>
                  <div className="form-group form-flex">
                    <label>Срок действия (активна до)</label>
                    <div className="form-field">
                      <DayPickerInput
                        className={(touched.date && errors.date) ? 'form-field-error' : ''}
                        formatDate={formatDate}
                        parseDate={parseDate}
                        inputProps={{ disabled: this.state.loader }}
                        format="DD.MM.YYYY"
                        value={values.date}
                        placeholder="ДД.ММ.ГГГГ"
                        // onDayChange={this.handleDayChange}
                        onDayChange={(e) => {
                          setFieldValue('date', e)
                        }}
                        dayPickerProps={{
                          selectedDays: selectedDay,
                          locale: 'ru',
                          localeUtils: MomentLocaleUtils,
                          showOutsideDays: true
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-info">Все поля являются обязательными для заполнения</div>
                  {
                    this.state.error && (
                      <div className="form-error">
                        {this.state.error}
                      </div>
                    )
                  }
                  <div className="form-button">
                    <button className="btn"
                      type="submit"
                      disabled={this.state.loader || this.props.reserved.email}
                      onClick={!this.props.reserved.email && handleSubmit}
                    >Создать бронь
                        </button>
                  </div>
                </Form>
              )}
          />
        </div>
      </>
    )
  }
}

export default OrderReservation;
