import store from "../store/store";
import { error } from "../containers/const";
import serviceApi from "./service";
import testData from './testdata';
import moment from "moment";

export const getEventsList = (data) =>
  serviceApi(`/ticket-wp-api/v1/get-events-list/`, data)
    .then(function (response) {
      return response.data;
    })

export const getCategoriesList = () => {
  return serviceApi(`/ticket-wp-api/v1/get-event-categories-list/`, {
    min: 1,
    max: 500
  });
};

export const getPlacesList = () => {
  return serviceApi(`/ticket-wp-api/v1/get-places-list/`, {
    min: 1,
    max: 500
  });
};

export const getCitiesList = () => {
  return serviceApi(`/ticket-wp-api/v1/get-cities-list/`, {
    min: 1,
    max: 500
  });
};

export const svgTotalEvent = event_id => {
  let path = `/ticket-wp-api/v1/get-promoter-svg-full/`;
  return serviceApi(path, {
    event_id: event_id
  });
};

export const getSchemeForSeats = () => {
  let path = `/ticket-wp-api/v1/get-plan-obj-types-list/`;
  return serviceApi(path)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      return error;
    });
};

export const getSvg = event_id => {
  let path = `ticket-wp-api/v1/get-promoter-svg/`;
  return serviceApi(path, {
    event_id
  })
    .then(res => {
      return res.data;
    })
    .catch(error => {
      return error;
    });
};

export const getEventStats = (event_id) => {
  let path = '/ticket-wp-api/v1/get-event-stat/';
  return serviceApi(path, {
    event_id: event_id
  })
}

export const getPromoterStatics = (data) => {
  let path = '/ticket-wp-api/v1/get-promoter-stat/';
  return serviceApi(path, data)
}
export const getEventSalesByPeriod = (data) => {
  return serviceApi('/ticket-wp-api/v1/get-event-sales-by-period/', data)
}

export const getEventDynamicSales = (data) => {
  return serviceApi('/ticket-wp-api/v1/get-event-sales-by-date/', data)
}

//​/get-event-sales-by-price

export const getEventByPrice = (data) => {
  return serviceApi('/ticket-wp-api/v1/get-event-sales-by-price/', data)
}


//get-event-sales-by-source

export const getEventBySource = (data) => {
  return serviceApi('/ticket-wp-api/v1/get-event-sales-by-source/', data)
}

export const getEventByCountry = (data) => {
  return serviceApi('/ticket-wp-api/v1/get-event-sales-by-geography/', data)
  .then(res => {
    res.data = testData;
    return res;
  })
}

export const getEventPlace = (data) => {
  return serviceApi(`/ticket-wp-api/v1/get-seat-stats-report/`, data)
}