import React, {Component} from 'react';
import './style.scss';
import './media.scss';

class EventBox extends Component{
  render() {
    const { time, date, name, location } = this.props;
     return (
      <>
        <div className="event-box">
          <div className="event-box__title">{name}</div>
          <div className="event-box__date">{`${date} ${time}`}</div>
          <div className="event-box__place">{location}</div>
        </div>
      </>
    )
  }
}

export default EventBox;
