import React, {Component} from 'react';
import './style.scss';
import './media.scss';
import Select from 'react-select';

const options = [
  {value: 'oplata', label: 'Оплата'},
  {value: 'price', label: 'Изготовление с ценой'},
  {value: 'pricezero', label: 'Изготовление с ценой 0,00 BYN'},
  {value: 'cashless', label: 'Безнальный заказ'},
  {value: 'reserve', label: 'Бронирование'}
];

class OrderAction extends Component{
  constructor(props) {
    super(props);

    this.state = {
      selectedOption: false,
    }
  }

  onChangePrint = selectedOption => {
    this.setState({ selectedOption });
    this.props.typeOrder(selectedOption)
  };

  render() {
    const {selectedOption} = this.state;

    return (
      <>
        <div className="order-action">
          <div className="order-action__title">Выберите дальнейшие действия</div>
          <div className="order-action__select">
            <Select
              classNamePrefix="select"
              placeholder="Выберите из списка"
              isSearchable={false}
              options={options}
              value={selectedOption}
              onChange={this.onChangePrint}
            />
          </div>
        </div>
      </>
    )
  }
}

export default OrderAction;
