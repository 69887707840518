import React, { Component } from "react";

export default class LegendScheme extends Component {
  render() {
    const { legendScheme, legendSchemeHide } = this.props;

    // if (legendScheme?.name) {
      return (
        <div className={`legend legend-${!legendSchemeHide && legendScheme?.name ? 'active' : "disabled"}`}>
          <div className="event-ticket__box-body">
            <div className="event-ticket__box-place">
              <b>
                {legendScheme?.name[0]
                  ? `${legendScheme?.name[0][0]} ${legendScheme?.name[0][1]}`
                  : null}
              </b>
            </div>
            <div className="event-ticket__box-place">
              <b>
                {legendScheme?.name[1]
                  ? `${legendScheme?.name[1][0]} ${legendScheme?.name[1][1]}`
                  : null}
              </b>
            </div>
            <div className="event-ticket__box-place">
              {legendScheme?.name[2]
                ? `${legendScheme?.name[2][0]} ${legendScheme?.name[2][1]}`
                : null}
            </div>
            <div className="event-ticket__box-place">
              {legendScheme?.name[3]
                ? `${legendScheme?.name[3][0]} ${legendScheme?.name[3][1]}`
                : null}
            </div>
          </div>
          <div className="event-ticket__box-body">
            <div className="event-ticket__box-place">
              <b>
                {legendScheme?.price ? (
                  <>цена - {legendScheme?.price} BYN</>
                ) : null}
              </b>
            </div>
          </div>
        </div>
      );
    // } else {
    //   return null;
    // }
  }
}
