import moment from "moment";

const parser = new DOMParser();

export const encodeXML = string => encodeURIComponent(string);

export const parseXML = (string, querySelector) => {
  const html = parser.parseFromString(string, "text/html");
  if (html) {
    if (querySelector) {
      const result = html.querySelector(querySelector);
      return result && result.textContent;
    }
    return html;
  }
};

export const paramsSerializer = function (params) {
  if (typeof params === "object") {
    return Object.keys(params)
      .map(
        key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      )
      .join("&");
  }
};

export const getCancelablePromise = promise => {
  let __hasCanceled = false;

  const wrappedPromise = new Promise((res, rej) => {
    promise.then(
      data => {
        if (__hasCanceled) {
          rej({ isCanceled: true });
        }
        res(data);
      },
      err => {
        if (__hasCanceled) {
          rej({ isCanceled: true });
        }
        rej(err);
      }
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      __hasCanceled = true;
    }
  };
};

export const parseNameAttribute = (scheme, name) => {
  if (name && scheme) {
    let parsedFields = name.split("/");
    let fields = [];
    parsedFields.forEach(field => {
      let split = field.split(":");
      let fieldName = "";
      let fieldValue = split[1] ? split[1] : "";
      // scheme.forEach((scheme) => {
      //   if (parseInt(split[0]) === scheme.id) {
      //     fieldName = scheme.names[0].name
      //   }
      // });
      let fieldItem = scheme.find(item => item.id === parseInt(split[0]));
      fieldName = fieldItem ? fieldItem.names[0].name : '';
      if (fieldValue) {
        // fields[parseInt(split[0] - 1)] = [fieldName, fieldValue];
        fields.push([fieldName, fieldValue]);
      }
    });
    return fields;
  }
  return null;
};
export const parseNameAttributeFull = (schemeObj, name) => {
  let names = [];
  let seatDetails = name.split("/");
  seatDetails.forEach((det, index) => {
    let split = det.split(":");
    // Generates names array of [ { attr: `Name of part of the event parsed from event schema`, data: `value of 'name' attribute parsed from svg` }, ... ]
    schemeObj.forEach(scheme => {
      if (parseInt(split[0]) === scheme.id && split[1]) {
        names.push({
          attr: scheme.names[0].name,
          data: split[1]
        });
      }
    });
  });
  return names;
};

export const getWidthAndHeightOfSvg = svg => {
  // TODO: change fallback svg parameters if needed
  let match = [null, 500, 500],
    regEx = /xmlns:xlink="http:\/\/www\.w3\.org\/1999\/xlink" width="(\d+)" height="(\d+)"/;
  try {
    match = svg.match(regEx);
  } catch (err) {
    console.error("Parsing width and height from svg failse: ", err);
  }
  return {
    width: match[1],
    height: match[2]
  };
};

export const formatedData = date => {
  if (date) {
    return (
      date.substring(8, 10) +
      " " +
      moment(date).locale('ru')
        .format("MMMM")
        .substring(0, 3) +
      ","
    );
  } else {
    return null;
  }
};