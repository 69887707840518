import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import Events from "./containers/Events/Events";
import Authenticated from "./containers/Authenticated/Authenticated";
import Header from "./components/Header/Header";
import withSubscriptionApi from "./HOC/SubscriptionApiHOC";
import EventPlan from "./containers/EventPlan/EventPlan";
import EventReserve from "./containers/EventReserve/EventReserve";
import EventOrders from "./containers/EventOrders/EventOrders";
import Reports from "./modules/ReportsEvent/pages/ReportEvent";
import Login from "./containers/Login/Login";
import Order from "./containers/Order/Order";
import ChoiceReports from './modules/ChoiceReports/pages/ChoiceReport';
import ReportData from './modules/ReportData/pages/ReportData';
import ReportsCategories from './modules/ReportsCategories/pages/Report';
import ReportDynamic from './modules/ReportDynamic/Pages/Report';
import ReportGeo from './modules/ReportGeo/pages/Report';
import ReportSeats from './modules/ReportSeats/pages/Report';
import Analytics from './modules/Analytics/pages/AnalyticsTotal'
import AnalyticsChoice from './modules/Analytics/pages/AnalyticsChoice'

// import { registerLocale } from "react-datepicker";
// import ru from "date-fns/locale/ru";
// registerLocale("ru", ru);

class App extends Component {
  render() {
    return (
      <Router>
        <Authenticated>
          <div className="wrapper">
            <Switch>
              <EnhancedComponentApi />
            </Switch>
            <Switch>
              <Route path="/" exact component={Events} />
              <Route path="/event/:eventID" component={EventPlan} />
              <Route path="/login" component={Login} />
              <Route path="/event-reserve/:eventID" component={EventReserve} />
              <Route path="/event-orders/:eventID?" component={EventOrders} />
              <Route path="/order/:id" component={Order} />

              <Route path="/analytics" exact component={AnalyticsChoice} />
              <Route path="/analytics/:id" exact component={Analytics} />

              <Route path="/reports/category" exact component={ReportsCategories} />

              <Route path="/reports/event" exact component={Reports} />

              <Route path="/reports/dynamic" exact component={ReportDynamic} />

              <Route path="/reports/geo" exact component={ReportGeo} />

              <Route path="/reports/seats" exact component={ReportSeats} />
              {/* <Route path="/reports/seats/:id?" exact component={ReportData} /> */}

              <Route path="/report-data/:event?/:id?" exact component={ReportData} />
              <Route path="/reports" component={ChoiceReports} />
            </Switch>
          </div>
        </Authenticated>
      </Router>
    );
  }
}

const EnhancedComponentApi = withSubscriptionApi(Header);

export default App;
