import { createSelector } from "reselect";
import moment from "moment";
import _ from 'lodash';

const getSearchInput = state => state.orders.currentFilter;
const getPlacesArray = state => state.orders.orders;
export const newFilteredArrayOrders = () => {
    try {
        return createSelector(
            [getSearchInput, getPlacesArray],
            (filter, arrayOrders = []) => {
                //filter id => name
                let sortedCities = (arrayOrders?.filter(str => String(str.id).includes(filter.searchInput.toLowerCase())));
                sortedCities = (sortedCities?.filter(str => String(str.id).includes((String(filter?.id_event?.id || '')).toLowerCase())));

                // filter type => 
                if (filter.selectorTypes?.value && filter.selectorTypes?.value !== 'all') {
                    if (filter.selectorTypes?.value === "is_manufacturing") {
                        sortedCities = sortedCities?.filter(str => str.is_manufacturing);
                    } else if (filter.selectorTypes?.value === "is_invitation") {
                        sortedCities = sortedCities?.filter(str => str.is_invitation);
                    } else if (filter.selectorTypes?.value === "is_salles") {
                        sortedCities = sortedCities?.filter(str => !str.is_cashless && !str.is_invitation && !str.is_manufacturing && str.email === null);
                    } else if (filter.selectorTypes?.value === "is_mail") {
                        sortedCities = sortedCities?.filter(str => str.email !== null);
                    } else if (filter.selectorTypes?.value === "is_cashless") {
                        sortedCities = sortedCities?.filter(str => str.is_cashless);
                    }
                }

                // multi filter type => 
                if (filter.multiSelectorTypes?.length) {
                    sortedCities = sortedCities?.filter(str => {
                        if (str.is_invitation && _.some(filter.multiSelectorTypes, { value: 'type2' })) {
                            return str;
                        } else if (str.is_manufacturing && str.sale_amount > 0 && _.some(filter.multiSelectorTypes, { value: 'type3' })) {
                            return str;
                        }
                        else if (!str.is_cashless && !str.is_manufacturing && !str.is_invitation && _.some(filter.multiSelectorTypes, { value: 'type1' })) {
                            return str;
                        }
                        else if (str.is_cashless && _.some(filter.multiSelectorTypes, { value: 'type4' }) && str.status_id && str.status_id === 4) {
                            return str
                        } else if (str.is_cashless && _.some(filter.multiSelectorTypes, { value: 'type5' }) && str.status_id === 1) {
                            return str
                        } else if (_.some(filter.multiSelectorTypes, { value: 'type7' }) && _.some(str.tickets, { status_id: -4 })) {
                            return str
                        } else if (_.some(filter.multiSelectorTypes, { value: 'type6' }) && str.status_id === -1) {
                            return str
                        } else if (_.some(filter.multiSelectorTypes, { value: 'type8' }) && str.status_id === -2) {
                            return str
                        } else if (_.some(filter.multiSelectorTypes, { value: 'type11' }) && str.is_mailsent) {

                        }
                        return false
                    });
                }

                // filter date
                if ((filter.date?.to || filter.date?.from) && (
                    moment(filter.date?.to, 'YYYY-MM-DD').isValid() ||
                    moment(filter.date?.from, 'YYYY-MM-DD').isValid()
                )) {
                    let startDate =
                        moment(filter.date?.from).format("YYYY-MM-DD")
                    let endDate = moment(filter.date?.to, 'YYYY-MM-DD').isValid() ?
                        moment(filter.date?.to).format("YYYY-MM-DD")
                        : moment().format('YYYY-MM-DD')
                    sortedCities = sortedCities.filter(a => {
                        let date = moment((a.inserted)).format("YYYY-MM-DD");
                        return (date >= startDate && date <= endDate);
                    });
                }

                return sortedCities;
            }
        )
    } catch (er) { }
}
