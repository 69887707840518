import React, { Component } from 'react';
import './style.scss';
import './media.scss';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { getPromoterStatics } from '../../api/events';
import { connect } from 'react-redux';
import Spinner from "../Spinner";
import { withRouter } from 'react-router-dom';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import { deletePrintOrderId, deletePrintOrderTickets, printOrder, cancelOrderMnf, cancelOrderEntity, cancelOrderCancel, cancelOrderMnfEntity, setSuccessOrderCashless } from '../../api/order';
import _ from 'lodash';
import OrderListPrintModal from '../OrderListPrintModal/OrderListPrintModal';
import {
  setCurrentOrder
} from '../../store/actions/orders';

class OrdersSidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showEventsSidebar: false,
      loader: false,
      infoStatistics: {},
      activeSals: 'sales_today',
      activeGain: 'daily_gain',
      error: false,
      openModal: false,
    }
  }


  _handleModal = () => {
    this.setState({
      openModal: !this.state.openModal
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.openModal !== this.state.openModal && this.state.openModal === false) {
      this._handleSuccess();
    }
  }

  _handleSuccess = () => {
    try {
      this.props.setCurrentOrder(this.props.match?.params?.id);
    } catch (er) {
      console.error(er)
    }
  }

  successCashlessOrder = () => {
    this.setState({
      loader: true,
    })
    setSuccessOrderCashless({
      order_id: this.props.match.params.id
    })
      .then(res => {
        this._handleSuccess();
        ToastsStore.success('Заказ успешно подвержен')
      })
      .catch(er => {
        ToastsStore.error(er.response?.data?.text || er.response?.data?.error)
      })
      .finally(() => {
        this.setState({
          loader: false,
        })
      })
  }

  _cancelOrderMnf = () => {
    let promises = []
    if (this.props.selection.length) {
      this.setState({
        loader: true,
      })
      if (this.props.order.is_manufacturing || this.props.order.is_invitation) {
        this.props.selection.map(item => {
          promises.push(
            cancelOrderMnfEntity({
              order_entity_id: item
            })
          )
        })
      } else {
        this.props.selection.map(item => {
          promises.push(
            cancelOrderEntity({
              order_entity_id: item,
              ident_type_id: 'CASH',
              is_cash_active: false,
            })
          )
        })
      }
      Promise.allSettled(promises)
        .then(async (res) => {
          this.setState({
            loader: false,
          })
          if (!_.some(res, { status: 'rejected' })) {
          }
          if (_.some(res, { status: 'rejected' })) {
            res.map(item => {
              ToastsStore.error(item?.reason?.response?.data?.text || item?.reason?.response?.data?.error)
            })
          }
          this._handleSuccess();
        })
    } else {
      ToastsStore.error('Нет выбранных билетов!');
    }
  }

  _handleStorDelete = () => {
    this.setState({
      loader: true,
    })
    if (this.props.order.is_manufacturing || this.props.order.is_invitation) {
      cancelOrderMnf({
        order_id: this.props.match.params.id
      })
        .then(res => {
          this._handleSuccess();
        })
        .catch(er => {
          ToastsStore.error(er.response?.data?.text || er.response?.data?.error)
        })
        .finally(() => {
          this.setState({
            loader: false,
          })
        })
    } else {
      cancelOrderCancel({
        order_id: this.props.match.params.id,
        copy_order: false,
        is_cash_active: false,
      })
        .then(res => {
          this._handleSuccess();
        })
        .catch(er => {
          ToastsStore.error(er.response?.data?.text || er.response?.data?.error)
        })
        .finally(() => {
          this.setState({
            loader: false,
          })
        })
    }
    // cancelOrderCancel({
    //   order_id: this.props.match.params.id,
    //   copy_order: false,
    //   is_cash_active: false,
    // })
  }


  _handlePrintOrder = () => {
    printOrder({ order_id: this.props.match.params.id })
  }

  orderDeleteTicketsOrder = () => {
    this.setState({
      loader: true,
    })
    deletePrintOrderId({ order_id: this.props.match.params.id })
      .then(res => {
        this._handleSuccess();
      })
      .catch(er => {
        ToastsStore.error(er.response?.data?.text || er.response?.data?.error)
      })
      .finally(() => {
        this.setState({
          loader: false,
        })
      })
  }

  orderDeleteTicket = () => {
    // order_entity_id
    let promises = [];
    if (this.props.selection.length) {
      this.setState({
        loader: true,
      })
      this.props.selection.map(item => {
        promises.push(
          deletePrintOrderTickets({
            order_entity_id: item
          })
        )
      })

      Promise.allSettled(promises).then((res) => {
        this.setState({
          loader: false,
        })
        if (!_.some(res, { status: 'rejected' })) {
        }
        if (_.some(res, { status: 'rejected' })) {
          res.map(item => {
            ToastsStore.error(item?.reason?.response?.data?.text || item?.reason?.response?.data?.error)
          })
        }
        this._handleSuccess();
      })
    } else {
      ToastsStore.error('Нет выбранных билетов!');
    }
  }


  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, false);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, false);
    this.setState({
      loader: false,
    }, () => {
      console.log(this)
    })
  }

  handleClickOutside = (e) => {
    let path = e.path;
    if (!e.path) {
      path = e.composedPath();
    }

    const eventsSidebar = document.getElementsByClassName('orders-sidebar')[0];
    if (!path.includes(eventsSidebar)) {
      const eventsSidebarToggle = document.getElementsByClassName('orders-sidebar__toggle')[0];
      if (!path.includes(eventsSidebarToggle)) {
        this.setState({
          showEventsSidebar: false
        })
      }
    }
  };

  toggleEventsSidebar = () => {
    this.setState({
      showEventsSidebar: !this.state.showEventsSidebar
    })
  };

  render() {
    const { showEventsSidebar, infoStatistics, activeSals, activeGain, loader, error } = this.state;

    return (
      <>
        <aside className={`orders-sidebar ${showEventsSidebar ? 'show' : ''}`}>
          <div className="orders-sidebar__toggle" onClick={this.toggleEventsSidebar}>
            <span className="icon icon-statistics"></span>
          </div>
          <div className="orders-sidebar__scroll">
            <PerfectScrollbar
              options={{
                wheelSpeed: 0.3,
                wheelPropagation: false
              }}
            >
              <>
                {
                  loader && (
                    <div className="events-sidebar__spinner">
                      <Spinner />
                    </div>
                  )
                }
                <div className="orders-sidebar__box">
                  <div className="orders-sidebar__box-head">Печать</div>
                  <div className="orders-sidebar__box-body">
                    <button
                      disabled={loader || !this.props.selection.length}
                      onClick={this.orderDeleteTicket}
                      className="btn orders-button">
                      Отменить печать выбранных
          </button>
                    <button disabled={loader ||
                      !_.filter(this.props.orderTickets, { 'status_id': 3 }).length
                      || this.props.order?.status_id === undefined

                    } onClick={this.orderDeleteTicketsOrder} className="btn orders-button">
                      Отменить печать всего заказа
          </button>
                    <button
                      disabled={loader || this.props.order?.status_id === undefined ||
                        _.filter(this.props.orderTickets, { 'status_id': 3 }).length
                      }
                      onClick={this._handleModal}
                      className="btn orders-button">
                      Печать доступных для печати
          </button>
                  </div>
                </div>
                <div className="orders-sidebar__box">
                  <div className="orders-sidebar__box-head">Оплаты</div>
                  <div className="orders-sidebar__box-body">
                    <button disabled={loader
                      || !this.props.selection.length
                      || this.props.order.is_cashless
                    } onClick={this._cancelOrderMnf} className="btn orders-button">
                      Вернуть в продажу
          </button>
                    <button
                      disabled={
                        !(this.props.order.status_id === 4 && !this.props.order.is_cashless) ||
                        loader}
                      onClick={this._handleStorDelete} className="btn orders-button">
                      Сторнировать оплату
          </button>

                  </div>
                </div>
                <div className="orders-sidebar__box">
                  <div className="orders-sidebar__box-head">Безналичные платежы</div>
                  <div className="orders-sidebar__box-body">
                    <button disabled={loader
                      || !(this.props.order.status_id === 1 && this.props.order.is_cashless) ||
                      this.props.order?.status_id === undefined
                    } onClick={this.successCashlessOrder} className="btn orders-button">
                      Подвердить оплату
          </button>

                  </div>
                </div>
              </>
            </PerfectScrollbar>
          </div>
        </aside>
        {
          this.state.openModal && (
            <OrderListPrintModal
              _handleModal={this._handleModal}
              order={this.props.order}
              order_id={this.props.match.params.id}
            />
          )
        }
        <ToastsContainer position={ToastsContainerPosition.TOP_RIGHT} store={ToastsStore} />
        {
          console.log(this.props.order.status_id)
        }
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  eventsList: state.event.events,
  order: state.orders.currentOrder.data,
  orderTickets: state.orders.currentOrder.data.tickets,
})

const mapDispatchToProps = {
  setCurrentOrder
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrdersSidebar))
