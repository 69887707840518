import React, { Component } from 'react';
import './style.scss';
import './media.scss';

class ChoiceReports extends Component {
    render() {
        return (
            <>
                <main className="main">
                    <div className="container">
                        <section className="reports-choice">
                            <div className="reports-choice_container">
                                <button onClick={() => { this.props.history.push('/reports/event') }} className="btn orders-button">
                                    Отчет по мероприятию
          </button>
                                <button onClick={() => { this.props.history.push('/reports/category') }} className="btn orders-button">
                                    Отчет по категориям мероприятий
          </button>
                                <button onClick={() => { this.props.history.push('/reports/dynamic') }} className="btn orders-button">
                                    Динамика продаж
          </button>
                                <button onClick={() => { this.props.history.push('/reports/geo') }} className="btn orders-button">
                                    География продаж
          </button>
                                <button onClick={() => { this.props.history.push('/reports/seats') }} className="btn orders-button">
                                    Статистика по местам
          </button>
                            </div>
                        </section>
                    </div>
                </main>
            </>
        )
    }
}

export default ChoiceReports;
