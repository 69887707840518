export default {
    "datetime": "2021-04-13 12:51:27",
    "row_count": 131,
    "row_list": [
        {
            "country_name": "Australia",
            "region_name": "New South Wales",
            "city_name": "Yarrawarrah",
            "ticket_count": 1
        },
        {
            "country_name": "Austria",
            "region_name": "Lower+Austria",
            "city_name": "Perchtoldsdorf",
            "ticket_count": 1
        },
        {
            "country_name": "Austria",
            "region_name": "Vienna",
            "city_name": "Vienna",
            "ticket_count": 5
        },
        {
            "country_name": "Belarus",
            "region_name": "Brest Region",
            "city_name": "Brest",
            "ticket_count": 99
        },
        {
            "country_name": "Belarus",
            "region_name": "Brest Region",
            "city_name": "Kobryn",
            "ticket_count": 2
        },
        {
            "country_name": "Belarus",
            "region_name": "Brest Region",
            "city_name": "Pinsk",
            "ticket_count": 3
        },
        {
            "country_name": "Belarus",
            "region_name": "Brest+Region",
            "city_name": "Baranovichi",
            "ticket_count": 8
        },
        {
            "country_name": "Belarus",
            "region_name": "Gomel Region",
            "city_name": "Gomel",
            "ticket_count": 8
        },
        {
            "country_name": "Belarus",
            "region_name": "Gomel Region",
            "city_name": "Mazyr",
            "ticket_count": 2
        },
        {
            "country_name": "Belarus",
            "region_name": "Gomel+Region",
            "city_name": "Gomel",
            "ticket_count": 15
        },
        {
            "country_name": "Belarus",
            "region_name": "Gomel+Region",
            "city_name": "Rečyca",
            "ticket_count": 2
        },
        {
            "country_name": "Belarus",
            "region_name": "Gomel+Region",
            "city_name": "Žlobin",
            "ticket_count": 1
        },
        {
            "country_name": "Belarus",
            "region_name": "Hrodna Region",
            "city_name": "Grodno",
            "ticket_count": 52
        },
        {
            "country_name": "Belarus",
            "region_name": "Hrodna Region",
            "city_name": "Lida",
            "ticket_count": 38
        },
        {
            "country_name": "Belarus",
            "region_name": "Hrodna Region",
            "city_name": "Shchuchyn",
            "ticket_count": 1
        },
        {
            "country_name": "Belarus",
            "region_name": "Hrodna Region",
            "city_name": "Slonim",
            "ticket_count": 3
        },
        {
            "country_name": "Belarus",
            "region_name": "Hrodna+Region",
            "city_name": "Grodno",
            "ticket_count": 11
        },
        {
            "country_name": "Belarus",
            "region_name": "Hrodna+Region",
            "city_name": "Lida",
            "ticket_count": 7
        },
        {
            "country_name": "Belarus",
            "region_name": "Hrodna+Region",
            "city_name": "Navahrudak",
            "ticket_count": 2
        },
        {
            "country_name": "Belarus",
            "region_name": "Minsk Region",
            "city_name": "Barysaw",
            "ticket_count": 1
        },
        {
            "country_name": "Belarus",
            "region_name": "Minsk Region",
            "city_name": "Druzhnyy",
            "ticket_count": 4
        },
        {
            "country_name": "Belarus",
            "region_name": "Minsk Region",
            "city_name": "Fanipaĺ",
            "ticket_count": 1
        },
        {
            "country_name": "Belarus",
            "region_name": "Minsk Region",
            "city_name": "Kolodishchi",
            "ticket_count": 3
        },
        {
            "country_name": "Belarus",
            "region_name": "Minsk Region",
            "city_name": "Liasny",
            "ticket_count": 4
        },
        {
            "country_name": "Belarus",
            "region_name": "Minsk Region",
            "city_name": "Maladzyechna",
            "ticket_count": 39
        },
        {
            "country_name": "Belarus",
            "region_name": "Minsk Region",
            "city_name": "Minsk",
            "ticket_count": 3531
        },
        {
            "country_name": "Belarus",
            "region_name": "Minsk Region",
            "city_name": "Papyernya",
            "ticket_count": 7
        },
        {
            "country_name": "Belarus",
            "region_name": "Minsk Region",
            "city_name": "Pukhovshchina",
            "ticket_count": 6
        },
        {
            "country_name": "Belarus",
            "region_name": "Minsk Region",
            "city_name": "Rudzensk",
            "ticket_count": 5
        },
        {
            "country_name": "Belarus",
            "region_name": "Minsk Region",
            "city_name": "Salihorsk",
            "ticket_count": 4
        },
        {
            "country_name": "Belarus",
            "region_name": "Minsk Region",
            "city_name": "Samakhvalavichy",
            "ticket_count": 5
        },
        {
            "country_name": "Belarus",
            "region_name": "Minsk Region",
            "city_name": "Smalyavichy",
            "ticket_count": 6
        },
        {
            "country_name": "Belarus",
            "region_name": "Minsk Region",
            "city_name": "Stowbtsy",
            "ticket_count": 1
        },
        {
            "country_name": "Belarus",
            "region_name": "Minsk Region",
            "city_name": "Trostenets",
            "ticket_count": 1
        },
        {
            "country_name": "Belarus",
            "region_name": "Minsk Region",
            "city_name": "Tsesino",
            "ticket_count": 5
        },
        {
            "country_name": "Belarus",
            "region_name": "Minsk Region",
            "city_name": "Zhodzina",
            "ticket_count": 10
        },
        {
            "country_name": "Belarus",
            "region_name": "Minsk+Region",
            "city_name": "Barysaw",
            "ticket_count": 6
        },
        {
            "country_name": "Belarus",
            "region_name": "Minsk+Region",
            "city_name": "Chudenichi",
            "ticket_count": 2
        },
        {
            "country_name": "Belarus",
            "region_name": "Minsk+Region",
            "city_name": "Liasny",
            "ticket_count": 2
        },
        {
            "country_name": "Belarus",
            "region_name": "Minsk+Region",
            "city_name": "Minsk",
            "ticket_count": 3250
        },
        {
            "country_name": "Belarus",
            "region_name": "Minsk+Region",
            "city_name": "Slabada",
            "ticket_count": 6
        },
        {
            "country_name": "Belarus",
            "region_name": "Minsk+Region",
            "city_name": "Slutsk",
            "ticket_count": 7
        },
        {
            "country_name": "Belarus",
            "region_name": "Minsk+Region",
            "city_name": "Stowbtsy",
            "ticket_count": 11
        },
        {
            "country_name": "Belarus",
            "region_name": "Mogilev Region",
            "city_name": "Asipovichy",
            "ticket_count": 1
        },
        {
            "country_name": "Belarus",
            "region_name": "Mogilev Region",
            "city_name": "Cherykaw",
            "ticket_count": 2
        },
        {
            "country_name": "Belarus",
            "region_name": "Mogilev Region",
            "city_name": "Mogilev",
            "ticket_count": 12
        },
        {
            "country_name": "Belarus",
            "region_name": "Mogilev+Region",
            "city_name": "Babruysk",
            "ticket_count": 7
        },
        {
            "country_name": "Belarus",
            "region_name": "Mogilev+Region",
            "city_name": "Klichaw",
            "ticket_count": 7
        },
        {
            "country_name": "Belarus",
            "region_name": "Mogilev+Region",
            "city_name": "Mogilev",
            "ticket_count": 6
        },
        {
            "country_name": "Belarus",
            "region_name": "Vitebsk Region",
            "city_name": "Bolbasovo",
            "ticket_count": 2
        },
        {
            "country_name": "Belarus",
            "region_name": "Vitebsk Region",
            "city_name": "Dokšycy",
            "ticket_count": 2
        },
        {
            "country_name": "Belarus",
            "region_name": "Vitebsk Region",
            "city_name": "Orsha",
            "ticket_count": 55
        },
        {
            "country_name": "Belarus",
            "region_name": "Vitebsk Region",
            "city_name": "Polatsk",
            "ticket_count": 1
        },
        {
            "country_name": "Belarus",
            "region_name": "Vitebsk Region",
            "city_name": "Viciebsk",
            "ticket_count": 5
        },
        {
            "country_name": "Belarus",
            "region_name": "Vitebsk Region",
            "city_name": "Šumilina",
            "ticket_count": 3
        },
        {
            "country_name": "Belarus",
            "region_name": "Vitebsk+Region",
            "city_name": "Orsha",
            "ticket_count": 18
        },
        {
            "country_name": "Belarus",
            "region_name": "Vitebsk+Region",
            "city_name": "Viciebsk",
            "ticket_count": 17
        },
        {
            "country_name": "Belgium",
            "region_name": "Brussels",
            "city_name": "Evere",
            "ticket_count": 1
        },
        {
            "country_name": "Belgium",
            "region_name": "Walloon+Region",
            "city_name": "Ohey",
            "ticket_count": 2
        },
        {
            "country_name": "Czech Republic",
            "region_name": "Zlin Region",
            "city_name": "Valasske Mezirici",
            "ticket_count": 1
        },
        {
            "country_name": "Czech+Republic",
            "region_name": "Prague",
            "city_name": "Prague",
            "ticket_count": 4
        },
        {
            "country_name": "Czech+Republic",
            "region_name": "South+Moravian+Region",
            "city_name": "Brno",
            "ticket_count": 2
        },
        {
            "country_name": "Denmark",
            "region_name": "Capital Region of Denmark",
            "city_name": "Ballerup",
            "ticket_count": 1
        },
        {
            "country_name": "Denmark",
            "region_name": "Capital+Region+of+Denmark",
            "city_name": "Copenhagen",
            "ticket_count": 2
        },
        {
            "country_name": "Finland",
            "region_name": "Uusimaa",
            "city_name": "Vantaa",
            "ticket_count": 2
        },
        {
            "country_name": "France",
            "region_name": "Alsace-Champagne-Ardenne-Lorraine",
            "city_name": "Charleville-Mézières",
            "ticket_count": 2
        },
        {
            "country_name": "France",
            "region_name": "Île-de-France",
            "city_name": "Meudon",
            "ticket_count": 1
        },
        {
            "country_name": "France",
            "region_name": "Île-de-France",
            "city_name": "Paris",
            "ticket_count": 1
        },
        {
            "country_name": "France",
            "region_name": "Île-de-France",
            "city_name": "Vélizy-Villacoublay",
            "ticket_count": 1
        },
        {
            "country_name": "Germany",
            "region_name": "Bavaria",
            "city_name": "Munich",
            "ticket_count": 8
        },
        {
            "country_name": "Germany",
            "region_name": "Bavaria",
            "city_name": "Nuremberg",
            "ticket_count": 2
        },
        {
            "country_name": "Germany",
            "region_name": "Bavaria",
            "city_name": "Regen",
            "ticket_count": 1
        },
        {
            "country_name": "Germany",
            "region_name": "Berlin",
            "city_name": "Berlin",
            "ticket_count": 2
        },
        {
            "country_name": "Germany",
            "region_name": "Hessen",
            "city_name": "Frankfurt+am+Main",
            "ticket_count": 5
        },
        {
            "country_name": "Germany",
            "region_name": "North Rhine-Westphalia",
            "city_name": "Düsseldorf",
            "ticket_count": 1
        },
        {
            "country_name": "Germany",
            "region_name": "North+Rhine-Westphalia",
            "city_name": "Cologne",
            "ticket_count": 10
        },
        {
            "country_name": "Germany",
            "region_name": "North+Rhine-Westphalia",
            "city_name": "Steinfurt",
            "ticket_count": 1
        },
        {
            "country_name": "Iceland",
            "region_name": "Capital Region",
            "city_name": "Reykjavík",
            "ticket_count": 7
        },
        {
            "country_name": "Israel",
            "region_name": "Tel Aviv District",
            "city_name": "Tel Aviv-Yafo",
            "ticket_count": 3
        },
        {
            "country_name": "Italy",
            "region_name": "Lombardy",
            "city_name": "Milan",
            "ticket_count": 4
        },
        {
            "country_name": "Latvia",
            "region_name": "Riga",
            "city_name": "Riga",
            "ticket_count": 6
        },
        {
            "country_name": "Luxembourg",
            "region_name": "District de Luxembourg",
            "city_name": "Luxembourg",
            "ticket_count": 2
        },
        {
            "country_name": "Netherlands",
            "region_name": "Limburg",
            "city_name": "Eijsden",
            "ticket_count": 1
        },
        {
            "country_name": "Netherlands",
            "region_name": "North Holland",
            "city_name": "Amsterdam",
            "ticket_count": 3
        },
        {
            "country_name": "Netherlands",
            "region_name": "North Holland",
            "city_name": "Haarlem",
            "ticket_count": 1
        },
        {
            "country_name": "Netherlands",
            "region_name": "North+Holland",
            "city_name": "Amsterdam",
            "ticket_count": 3
        },
        {
            "country_name": "Netherlands",
            "region_name": "South Holland",
            "city_name": "Schiedam",
            "ticket_count": 4
        },
        {
            "country_name": "Netherlands",
            "region_name": "South+Holland",
            "city_name": "Leiden",
            "ticket_count": 2
        },
        {
            "country_name": "Poland",
            "region_name": "Greater Poland Voivodeship",
            "city_name": "Poznań",
            "ticket_count": 1
        },
        {
            "country_name": "Poland",
            "region_name": "Masovian Voivodeship",
            "city_name": "Warsaw",
            "ticket_count": 5
        },
        {
            "country_name": "Poland",
            "region_name": "Masovian+Voivodeship",
            "city_name": "Warsaw",
            "ticket_count": 8
        },
        {
            "country_name": "Republic of Lithuania",
            "region_name": "Vilnius County",
            "city_name": "Vilnius",
            "ticket_count": 2
        },
        {
            "country_name": "Republic+of+Lithuania",
            "region_name": "Klaipėdos+apskritis",
            "city_name": "Klaipėda",
            "ticket_count": 1
        },
        {
            "country_name": "Romania",
            "region_name": "Bucharest",
            "city_name": "Bucharest",
            "ticket_count": 1
        },
        {
            "country_name": "Russia",
            "region_name": "Kursk+Oblast",
            "city_name": "Kursk",
            "ticket_count": 1
        },
        {
            "country_name": "Russia",
            "region_name": "Moscow",
            "city_name": "Moscow",
            "ticket_count": 58
        },
        {
            "country_name": "Russia",
            "region_name": "Moscow Oblast",
            "city_name": "Lyubertsy",
            "ticket_count": 2
        },
        {
            "country_name": "Russia",
            "region_name": "Moscow Oblast",
            "city_name": "Serpukhov",
            "ticket_count": 2
        },
        {
            "country_name": "Russia",
            "region_name": "Moscow+Oblast",
            "city_name": "Khimki",
            "ticket_count": 7
        },
        {
            "country_name": "Russia",
            "region_name": "Rostov Oblast",
            "city_name": "Rostov-on-Don",
            "ticket_count": 2
        },
        {
            "country_name": "Russia",
            "region_name": "Saint Petersburg",
            "city_name": "Saint Petersburg",
            "ticket_count": 3
        },
        {
            "country_name": "Russia",
            "region_name": "Saint+Petersburg",
            "city_name": "Saint+Petersburg",
            "ticket_count": 7
        },
        {
            "country_name": "Russia",
            "region_name": "Stavropol Krai",
            "city_name": "Stavropol",
            "ticket_count": 2
        },
        {
            "country_name": "Russia",
            "region_name": "Sverdlovsk Oblast",
            "city_name": "Nizhny Tagil",
            "ticket_count": 1
        },
        {
            "country_name": "Russia",
            "region_name": "Sverdlovsk+Oblast",
            "city_name": "Yekaterinburg",
            "ticket_count": 2
        },
        {
            "country_name": "Russia",
            "region_name": "Tver Oblast",
            "city_name": "Tver",
            "ticket_count": 1
        },
        {
            "country_name": "Spain",
            "region_name": "Valencian Community",
            "city_name": "Mutxamel",
            "ticket_count": 1
        },
        {
            "country_name": "Sweden",
            "region_name": "Östergötland County",
            "city_name": "Linköping",
            "ticket_count": 9
        },
        {
            "country_name": "Sweden",
            "region_name": "Östergötland+County",
            "city_name": "Linköping",
            "ticket_count": 17
        },
        {
            "country_name": "Switzerland",
            "region_name": "Canton+of+Bern",
            "city_name": "Lyssach",
            "ticket_count": 2
        },
        {
            "country_name": "Ukraine",
            "region_name": "Kharkiv+Oblast",
            "city_name": "Kharkiv",
            "ticket_count": 1
        },
        {
            "country_name": "Ukraine",
            "region_name": "Kyiv city",
            "city_name": "Kiev",
            "ticket_count": 2
        },
        {
            "country_name": "Ukraine",
            "region_name": "Kyivs'ka oblast",
            "city_name": "Brovary",
            "ticket_count": 2
        },
        {
            "country_name": "Ukraine",
            "region_name": "Lviv+Oblast",
            "city_name": "Lviv",
            "ticket_count": 1
        },
        {
            "country_name": "United Kingdom",
            "region_name": "England",
            "city_name": "Gloucester",
            "ticket_count": 3
        },
        {
            "country_name": "United Kingdom",
            "region_name": "England",
            "city_name": "London",
            "ticket_count": 2
        },
        {
            "country_name": "United Kingdom",
            "region_name": "England",
            "city_name": "Slough",
            "ticket_count": 2
        },
        {
            "country_name": "United States",
            "region_name": "California",
            "city_name": "El Segundo",
            "ticket_count": 2
        },
        {
            "country_name": "United States",
            "region_name": "California",
            "city_name": "Los Angeles",
            "ticket_count": 4
        },
        {
            "country_name": "United States",
            "region_name": "California",
            "city_name": "San Francisco",
            "ticket_count": 2
        },
        {
            "country_name": "United States",
            "region_name": "California",
            "city_name": "San Jose",
            "ticket_count": 2
        },
        {
            "country_name": "United States",
            "region_name": "Illinois",
            "city_name": "Downers Grove",
            "ticket_count": 1
        },
        {
            "country_name": "United States",
            "region_name": "New Jersey",
            "city_name": "Newark",
            "ticket_count": 2
        },
        {
            "country_name": "United States",
            "region_name": "New York",
            "city_name": "New York",
            "ticket_count": 1
        },
        {
            "country_name": "United States",
            "region_name": "Washington",
            "city_name": "Seattle",
            "ticket_count": 3
        },
        {
            "country_name": "United+Kingdom",
            "region_name": "England",
            "city_name": "Earley",
            "ticket_count": 2
        },
        {
            "country_name": "United+Kingdom",
            "region_name": "England",
            "city_name": "London",
            "ticket_count": 2
        },
        {
            "country_name": "United+Kingdom",
            "region_name": "Scotland",
            "city_name": "Ellon",
            "ticket_count": 2
        },
        {
            "country_name": "United+States",
            "region_name": "District+of+Columbia",
            "city_name": "Washington",
            "ticket_count": 3
        },
        {
            "country_name": "United+States",
            "region_name": "New+Jersey",
            "city_name": "Raritan",
            "ticket_count": 10
        },
        {
            "country_name": "Неизвестна",
            "region_name": "Неизвестен",
            "city_name": "Неизвестен",
            "ticket_count": 75664
        }
    ]
}