import React, { Component } from "react";
import "./style.scss";
import "./media.scss";
import EventTicket from "../EventTicket/EventTicket";
import PerfectScrollbar from "react-perfect-scrollbar";
import { parseNameAttribute } from "../../helpers/index";
class EventPlanTickets extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowTickets: true
    };
  }

  ticketsToggle = () => {
    this.setState({
      isShowTickets: !this.state.isShowTickets
    });
  };

  render() {
    const { isShowTickets } = this.state;
    const {
      tickets,
      planList,
      deleteTicket,
      listIdTickets,
      loaderTickets,
      spinner
    } = this.props;
    return (
      <>
        <div className="event-map__sidebar-tickets">
          <div className="event-map__sidebar-tickets-head">
            Билеты{" "}
            <button
              className={`event-map__sidebar-tickets-toggle ${
                isShowTickets ? "" : "active"
              }`}
              onClick={this.ticketsToggle}
            >
              <span className="icon icon-dd"></span>
            </button>
          </div>
          {isShowTickets && !loaderTickets ? (
            <div className="event-map__sidebar-tickets-body">
              <div className="event-map__sidebar-tickets-list">
                <PerfectScrollbar
                  options={{
                    wheelSpeed: 0.3,
                    wheelPropagation: false
                  }}
                >
                  {listIdTickets.map(ticket => {
                    if(ticket.is_ticket) {
                      return true;
                    }
                    return (
                      <EventTicket
                      place={parseNameAttribute(
                        planList,
                        ticket.name
                      )}
                      id={ticket.id}
                      basketID={ticket.basketID}
                      coast={ticket.price}
                      ticket_id={ticket.ticket_id}
                      deleteTicket={deleteTicket}
                      />)
                    // return (
                    //   <EventTicket
                    //     place={parseNameAttribute(planList, ticket.name)}
                    //     id={ticket.id}
                    //     coast={ticket.price}
                    //     ticket_name={ticket.name}
                    //     ticket_id={ticket.ticket_id}
                    //     deleteTicket={deleteTicket}
                    //   />
                    // );
                  })}
                </PerfectScrollbar>
              </div>
            </div>
          ) : null}
          <div className="event-map__sidebar-tickets-foot">
            <div className="event-map__sidebar-tickets-total">
              Итого выбрано: {listIdTickets.length} шт
            </div>
            <div className="event-map__sidebar-tickets-button">
              <button className="btn" onClick={this.props.showOrder}>
                Создать заказ
              </button>
              <button className="btn" onClick={this.props.clearTickets}>
                Очистить
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default EventPlanTickets;
