import React, { Component } from 'react';
import './style.scss';
import './media.scss';
import Select from 'react-select';
import { connect } from "react-redux";
import {
  setFilterMultiTypes,
  setFilterTypes
} from '../../store/actions/orders'

const options = [
  { value: 'all', label: 'Все заказы' }, // filter null
  { value: 'is_manufacturing', label: 'Изготовление' }, //is_manufacturing
  { value: 'is_invitation', label: 'Изготовление c ценой 0.00' }, //is_invitation
  { value: 'is_salles', label: 'Продажа' }, // all false
  { value: 'is_mail', label: 'Бронирование' }, // mail
  { value: 'is_cashless', label: 'Безналичная' }, //is_cashless
];

const ordersType = [
  { value: 'type1', label: 'Продажа', type:'order' }, // all false
  { value: 'type2', label: 'Пригласительный', type:'order' }, // is_invitation
  { value: 'type3', label: 'Изготовление с ценой' , type:'order'}, // is_manufacturing
  { value: 'type4', label: 'Безналичный подтвержден', type:'order' }, // ?
  { value: 'type5', label: 'Безналичный открытый', type:'order' },  // is_cashless

  { value: 'type6', label: 'Возврат', type:'order' }, // ?
  { value: 'type7', label: 'Частичный возврат', type:'order' }, // ? //"status_id": -4,
  { value: 'type8', label: 'Аннулированный', type:'order' }, // ?

  // { value: 'type9', label: 'Печать на принтере', type:'order' }, // 3 => ?
  // { value: 'type10', label: 'Сохранение на компьютере' , type:'order'},  // ?
  { value: 'type11', label: 'Отправка на EMAIL', type:'order' }, // mail
];

class EventOrdersFilter extends Component {
  render() {
    return (
      <>
        <div className="event-orders__filter">
          <div className="event-orders__filter-select">
            <Select
              classNamePrefix="select"
              placeholder="Выберите из списка"
              isSearchable={false}
              options={options}
              isDisabled={false}
              getOptionValue={(item) => item}
              value={this.props.selectorTypes}
              onChange={(e) => {
                this.props.setFilterTypes(e)
              }}
            />
          </div>
          <div className="event-orders__filter-type">
            <Select
              isMulti
              classNamePrefix="select"
              placeholder="Выберите из списка"
              isSearchable={false}
              isClearable={false}
              options={ordersType}
              value={this.props.multiSelectorTypes}
              onChange={(e) => {
                this.props.setFilterMultiTypes(e)
              }}
              isDisabled={false}
            />
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  multiSelectorTypes: state.orders.filter.multiSelectorTypes,
  selectorTypes: state.orders.filter.selectorTypes,
});

const mapDispatchToProps = {
  setFilterMultiTypes,
  setFilterTypes
};

export default connect(mapStateToProps, mapDispatchToProps)(EventOrdersFilter);
