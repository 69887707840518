import React, { Component } from 'react'
import Chart from 'react-google-charts'
import {
  getEventSalesByPeriod,
  getEventDynamicSales,
  getEventByPrice,
  getEventBySource,
  getEventByCountry,
} from '../../../api/events'
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import _ from 'lodash';
import HeaderAnalytic from '../components/header';
import Spinner from "../../../components/Spinner";
import EventOrders from '../components/headerMain';
import './index.scss';

const colorsTypes = [
  '#0000FF',
  '#708090',
  '#FF00FF',
  '#808080',
  '#991199',
  '#008000',
  '#545454',
  '#FFCBDB',
  '#FF0000',
  '#FFF000'
];

export default class AnalyticsTotal extends Component {
  state = {
    timeZone: 'за всё время',
    byPeriod: [],
    byDynamic: null,
    bySource: null,
    byPrice: null,
    ByCountries: null,

    byPeriodLoader: false,
    byPriceLoader: false,
    byCountryLoader: false,
    bySourceLoader: false,
    byDynamicLoader: false,

    charts: {
      byDynamic: null,
      ByCountries: null,
      bySource: null,
      byPrice: null,
      byPeriod: null,
    },
    searchTime: {
      to: null,
      from: null,
    }
  }


  searchDate = (from, to) => {
    this.setState({
      byPeriod: [],
      byDynamic: null,
      bySource: null,
      byPrice: null,
      ByCountries: null,
      charts: {
        byDynamic: null,
        ByCountries: null,
        bySource: null,
        byPrice: null,
        byPeriod: null,
      },
      timeZone: 'заданный период',
      searchTime: {
        to: moment(to).format('YYYY-MM-DD'),
        from: moment(from).format('YYYY-MM-DD'),
      }
    }, () => {
      let event_id = this.props.match.params.id
      this.getDynamicSales(event_id, this.state.searchTime.from, this.state.searchTime.to)
      this.getCountry(event_id, this.state.searchTime.from, this.state.searchTime.to);
      this.getSource(event_id, this.state.searchTime.from, this.state.searchTime.to);
      this.getPrices(event_id, this.state.searchTime.from, this.state.searchTime.to);
      this.getByPeriod(event_id, this.state.searchTime.from, this.state.searchTime.to); // заданный период
    })
  }

  onChangeDate = (type) => {

    this.setState({
      byPeriod: [],
      byDynamic: null,
      bySource: null,
      byPrice: null,
      ByCountries: null,
      charts: {
        byDynamic: null,
        ByCountries: null,
        bySource: null,
        byPrice: null,
        byPeriod: null,
      },
    })

    if (type === 'today') {
      this.setState({
        timeZone: 'сегодня',
        searchTime: {
          to: moment(new Date()).format('YYYY-MM-DD'),
          from: moment(new Date()).format('YYYY-MM-DD'), // HH-mm-ss
        }
      }, () => {
        this.findAnalytics()
      })

    } else if (type === 'week') {
      this.setState({
        timeZone: 'неделя',
        searchTime: {
          to: moment(new Date()).format('YYYY-MM-DD'),
          from: moment(new Date()).subtract(7, 'days').format('YYYY-MM-DD'), // HH-mm-ss
        }
      }, () => {
        this.findAnalytics()
      })

    } else if (type === 'month') {
      this.setState({
        timeZone: 'месяц',
        searchTime: {
          to: moment(new Date()).format('YYYY-MM-DD'),
          from: moment(new Date()).subtract(30, 'days').format('YYYY-MM-DD'), // HH-mm-ss
        }
      }, () => {
        this.findAnalytics()
      })
    } else if (type === 'all') {
      this.setState({
        timeZone: 'за всё время',
        searchTime: {
          to: moment(new Date()).format('YYYY-MM-DD'),
          from: moment(new Date()).subtract(370, 'days').format('YYYY-MM-DD'), // HH-mm-ss
        }
      }, () => {
        this.findAnalytics()
      })
    } else if (type === 'date') {
      // this.setState({
      //   timeZone: 'заданный период',
      //   searchTime: {
      //     to: null,
      //     from: null,
      //   }
      // })
    }
  }

  findAnalytics = () => {
    let event_id = this.props.match.params.id
    this.getDynamicSales(event_id, this.state.searchTime.from, this.state.searchTime.to)
    this.getCountry(event_id, this.state.searchTime.from, this.state.searchTime.to);
    this.getSource(event_id, this.state.searchTime.from, this.state.searchTime.to);
    this.getPrices(event_id, this.state.searchTime.from, this.state.searchTime.to);
    this.getByPeriod(event_id, this.state.searchTime.from, this.state.searchTime.to); // заданный период
  }

  componentDidMount() {
    let event_id = this.props.match.params.id

    this.getByPeriod(event_id);
    this.getDynamicSales(event_id)
    this.getCountry(event_id);
    this.getSource(event_id);
    this.getPrices(event_id);
  }

  getByPeriod = (event_id, from, to) => {
    this.setState({
      byPeriodLoader: true,
    })
    getEventSalesByPeriod({
      event_id: event_id,
      sales_from: from,
      sales_to: to,
    })
      .then(res => {
        if (_.some(res.data, { period: this.state.timeZone })) {
          res.data.filter(item => item.period === this.state.timeZone)[0].allCount = res.data?.filter(item => item.period === this.state.timeZone)[0]?.inv_count +
            (res.data.filter(item => item.period === this.state.timeZone)[0].sold_count) +
            (res.data.filter(item => item.period === this.state.timeZone)[0].mnf_count) +
            (res.data.filter(item => item.period === this.state.timeZone)[0].sold_cashless_count) +
            (res.data.filter(item => item.period === this.state.timeZone)[0].not_payed_cashless_count);

          this.setState({
            byPeriod: res.data,
            charts: {
              ...this.state.charts,
              byPeriod: [
                ["Пригласительные", (
                  res.data?.filter(item => item.period === this.state.timeZone)[0]?.inv_count
                ),
                ],
                ["Проданные", (res.data.filter(item => item.period === this.state.timeZone)[0].sold_count)],
                ["Изготовленные c ценой", (res.data.filter(item => item.period === this.state.timeZone)[0].mnf_count)],
                ["Проданные по безналу ", (res.data.filter(item => item.period === this.state.timeZone)[0].sold_cashless_count)],
                ["Неоплаченные безнал", (res.data.filter(item => item.period === this.state.timeZone)[0].not_payed_cashless_count)],

              ]
            }
          })
        }
      })
      .finally(() => {
        this.setState({
          byPeriodLoader: false,
        })
      })
  }

  getPrices = (event_id, from, to) => {
    this.setState({
      byPriceLoader: true,
    })
    getEventByPrice(
      {
        event_id: event_id,
        sales_from: from,
        sales_to: to,
      }
    )
      .then(res => {
        let data = [];
        res.data.map(item => {
          data.push(
            [item?.price_name_ru, item.operation_count],
          )
        })
        this.setState({
          byPrice: res.data,
          charts: {
            ...this.state.charts,
            byPrice: data,
          }
        })
      })
      .finally(() => {
        this.setState({
          byPriceLoader: false,
        })
      })
  }


  getSource = (event_id, from, to) => {
    this.setState({
      bySourceLoader: true,
    })
    getEventBySource(
      {
        event_id: event_id,
        sales_from: from,
        sales_to: to,
      }
    ).then(res => {
      if (res.data.length > 1) {
        let allCount = 0
        res.data.forEach(element => {
          allCount = allCount + element.operation_count
        });
        res.data = res.data.map(item => {
          item.prc = ((item.operation_count / allCount) * 100).toFixed(3);
          return item;
        })
      } else if (res.data.length === 1) {
        res.data[0].prc = 100;
      }

      let data = [];
      res.data.map(item => {
        data.push(
          [item?.source_name, item.operation_count],
        )
      })

      this.setState({
        bySource: res.data,
        charts: {
          ...this.state.charts,
          bySource: data,
        }
      })
    })
      .finally(() => {
        this.setState({
          bySourceLoader: false,
        })
      })
  }

  getCountry = (event_id, from, to) => {
    this.setState({
      byCountryLoader: true,
    })
    getEventByCountry(
      {
        event_id: event_id,
        date_from: from,
        date_to: to,
      }
    ).then(res => {
      let data = [];
      const byName = _.groupBy(res.data.row_list?.filter(it => it.country_name), it => it['country_name'])
      res.data.row_list = Object.keys(byName).map(name => {
        const sum = byName[name].reduce((acc, it) => acc + it.ticket_count, 0)
        return {
          country_name: name,
          ticket_count: sum
        }
      })
      _.unionBy(res.data.row_list, 'country_name').map(item => {
        if (item.country_name !== "Неизвестна") {
          data.push(
            [item?.country_name, item.ticket_count],
          )
        }
      })
      if (res.data.row_list.length > 1) {
        let allCount = 0
        res.data.row_list.forEach(element => {
          allCount = allCount + element.ticket_count
        });
        res.data.row_list = res.data.row_list.map(item => {
          item.prc = ((item.ticket_count / allCount) * 100).toFixed(3);
          return item;
        })
      } else if (res.data[0]) {
        res.data[0].prc = 100;
      }
      this.setState({
        ByCountries: res.data.row_list,
        charts: {
          ...this.state.charts,
          ByCountries: data,
        }
      })
    })
      .finally(() => {
        this.setState({
          byCountryLoader: false,
        })
      })
  }

  getDynamicSales = (event_id, from = null, to = null) => {
    this.setState({
      byDynamicLoader: true,
    })
    getEventDynamicSales({
      event_id: event_id,
      sales_from: from,
      sales_to: to,
    })
      .then(res => {
        let data = [];
        _.sortBy(res.data, 'date').map(item => {
          data.push(
            [moment(item.date).locale('ru').format("l"), item?.sold_count || 0],
          )
        })
        this.setState({
          byDynamic: res.data,
          charts: {
            ...this.state.charts,
            byDynamic: data,
          }
        })
      })
      .finally(() => {
        this.setState({
          byDynamicLoader: false,
        })
      })
  }

  render() {
    const ref_pdf = React.createRef();
    return (
      <>
        {/* <EventOrders /> */}
        <main className="main">
          <div className="container">
            <section className="analytic_container">
              <HeaderAnalytic
                searchDate={this.searchDate}
                onChangeDate={e => { this.onChangeDate(e) }}
                ref_pdf={ref_pdf}
                data={this.state}
              />

              <div
                ref={ref_pdf}
                id="pdf_page" className="analytic_container">

                <div
                  className="blocks"
                >
                  <div
                    className="blocks-sales">
                    <div className="analytic">
                      <div className="analytic-sales">
                        <h2>
                          Продажи
                    </h2>
                        <div
                          className="container">
                          {
                            this.state.byPeriodLoader ?
                              <div className="centerLoader">
                                <Spinner
                                ></Spinner>
                              </div> : (
                                <>
                                  <div className="block">
                                    <p className="block_text_green">
                                      {
                                        this.state.byPeriod?.filter(item => item.period === this.state.timeZone)[0]?.sold_count || "Нет данных"
                                      }
                                    </p>
                                    <p className="block_text">
                                      Билетов
    </p>
                                    <p className="block_text_red">
                                      {
                                        this.state.byPeriod?.filter(item => item.period === this.state.timeZone)[0]?.return_operation_count || "Нет данных"
                                      }
                                    </p>
                                    <p className="block_text">
                                      Возвращено билетов
    </p>
                                  </div>
                                  <div className="block">

                                    <p className="block_text_green">
                                      {
                                        this.state.byPeriod?.filter(item => item.period === this.state.timeZone)[0]?.operation_amount || "Нет данных"
                                      }
                                    </p>
                                    <p className="block_text">
                                      Сумма
</p>
                                    <p className="block_text_red">
                                      {
                                        this.state.byPeriod?.filter(item => item.period === this.state.timeZone)[0]?.return_operation_amount || "Нет данных"
                                      }
                                    </p>
                                    <p className="block_text">
                                      Сумма возвратов
</p>

                                  </div>
                                </>
                              )
                          }

                        </div>
                      </div>
                    </div>
                    <div
                      className="analytic">
                      <div className="analytic-sales">
                        <h2>
                          Кол-во по типам билетов
                    </h2>
                        {
                          this.state.byPeriodLoader ? <div className="centerLoader">
                            <Spinner
                            ></Spinner>
                          </div> : null}
                        <div className="container">
                          {
                            this.state.byPeriodLoader ? null : (<>
                              {
                                this.state.charts.byPeriod !== null && (
                                  <Chart
                                    width={'250px'}

                                    height={'300px'}
                                    chartType="PieChart"
                                    loader={<div>Загрузка!</div>}
                                    data={[
                                      ['Task', 'Hours per Day'],
                                      ...this.state.charts.byPeriod,
                                    ]}
                                    options={{
                                      pieHole: 0.6,
                                      pieSliceText: 'none',
                                      legend: 'none',
                                      colors: colorsTypes,

                                    }}
                                    rootProps={{ 'data-testid': '3' }}

                                  />
                                )}
                            </>
                            )
                          }
                          {
                            this.state.charts.byPeriod !== null && _.some(this.state.byPeriod, { period: this.state.timeZone }) && (
                              <PerfectScrollbar
                                options={{
                                  wheelSpeed: 0.3,
                                  wheelPropagation: false
                                }}
                                style={{
                                  width: '100%'
                                }}
                              >
                                <div className="dynamic-list-char">

                                  {this.state.byPeriod !== null && this.state.byPeriod.map((item, index) => {
                                    if (item.period !== this.state.timeZone) {
                                      return null
                                    }
                                  })}
                                  <div className="dynamic-list-char_item">
                                    <div
                                      className="dynamic-list-char_item_container"
                                    >
                                      <div
                                        style={{
                                          width: '12px',
                                          position: 'relative',
                                          paddingRight: '12px'
                                        }}
                                      >
                                      </div >
                                      <div
                                        style={{
                                          backgroundColor: colorsTypes[String(0).charAt(0)],
                                          width: '14px',
                                          height: '14px',
                                          top: '4px',
                                          position: 'absolute',
                                          left: '0px',
                                          borderRadius: '50%',
                                        }}
                                      >
                                      </div>
                                      <div className="dynamic-list-char_item_name">
                                        Пригласительные
                                  </div>
                                      <div className="dynamic-list-char_item_count">
                                        {
                                          this.state.byPeriod?.filter(item => item.period === this.state.timeZone)[0]?.inv_count
                                        }
                                      </div>
                                    </div>
                                    <div className="dynamic-list-char_item_line">
                                      <div style={{
                                        width: `${this.state.byPeriod?.filter(item => item.period === this.state.timeZone)[0]?.inv_count / (this.state.byPeriod?.filter(item => item.period === this.state.timeZone)[0]?.allCount) * 100
                                          }%`
                                      }}></div>
                                    </div>
                                  </div>

                                  <div className="dynamic-list-char_item">
                                    <div
                                      className="dynamic-list-char_item_container"
                                    >
                                      <div
                                        style={{
                                          width: '12px',
                                          position: 'relative',
                                          paddingRight: '12px'
                                        }}
                                      >
                                      </div >
                                      <div
                                        style={{
                                          backgroundColor: colorsTypes[String(1).charAt(0)],
                                          width: '14px',
                                          height: '14px',
                                          top: '4px',
                                          position: 'absolute',
                                          left: '0px',
                                          borderRadius: '50%',
                                        }}
                                      >
                                      </div>
                                      <div className="dynamic-list-char_item_name">
                                        Проданные
                                  </div>
                                      <div className="dynamic-list-char_item_count">
                                        {
                                          this.state.byPeriod?.filter(item => item.period === this.state.timeZone)[0]?.sold_count
                                        }
                                      </div>
                                    </div>
                                    <div className="dynamic-list-char_item_line">
                                      <div style={{
                                        width: `${this.state.byPeriod?.filter(item => item.period === this.state.timeZone)[0]?.sold_count / (this.state.byPeriod?.filter(item => item.period === this.state.timeZone)[0]?.allCount) * 100
                                          }%`
                                      }}></div>
                                    </div>
                                  </div>


                                  <div className="dynamic-list-char_item">
                                    <div
                                      className="dynamic-list-char_item_container"
                                    >
                                      <div
                                        style={{
                                          width: '12px',
                                          position: 'relative',
                                          paddingRight: '12px'
                                        }}
                                      >
                                      </div >
                                      <div
                                        style={{
                                          backgroundColor: colorsTypes[String(2).charAt(0)],
                                          width: '14px',
                                          height: '14px',
                                          top: '4px',
                                          position: 'absolute',
                                          left: '0px',
                                          borderRadius: '50%',
                                        }}
                                      >
                                      </div>
                                      <div className="dynamic-list-char_item_name">
                                        Изготовленные с ценой
                                  </div>
                                      <div className="dynamic-list-char_item_count">
                                        {
                                          this.state.byPeriod?.filter(item => item.period === this.state.timeZone)[0]?.mnf_count
                                        }
                                      </div>
                                    </div>
                                    <div className="dynamic-list-char_item_line">
                                      <div style={{
                                        width: `${this.state.byPeriod?.filter(item => item.period === this.state.timeZone)[0]?.mnf_count / (this.state.byPeriod?.filter(item => item.period === this.state.timeZone)[0]?.allCount) * 100
                                          }%`
                                      }}></div>
                                    </div>
                                  </div>


                                  <div className="dynamic-list-char_item">
                                    <div
                                      className="dynamic-list-char_item_container"
                                    >
                                      <div
                                        style={{
                                          width: '12px',
                                          position: 'relative',
                                          paddingRight: '12px'
                                        }}
                                      >
                                      </div >
                                      <div
                                        style={{
                                          backgroundColor: colorsTypes[String(3).charAt(0)],
                                          width: '14px',
                                          height: '14px',
                                          top: '4px',
                                          position: 'absolute',
                                          left: '0px',
                                          borderRadius: '50%',
                                        }}
                                      >
                                      </div>
                                      <div className="dynamic-list-char_item_name">
                                        Проданные по безналу
                                  </div>
                                      <div className="dynamic-list-char_item_count">
                                        {
                                          this.state.byPeriod?.filter(item => item.period === this.state.timeZone)[0]?.sold_cashless_count
                                        }
                                      </div>
                                    </div>
                                    <div className="dynamic-list-char_item_line">
                                      <div style={{
                                        width: `${this.state.byPeriod?.filter(item => item.period === this.state.timeZone)[0]?.sold_cashless_count / (this.state.byPeriod?.filter(item => item.period === this.state.timeZone)[0]?.allCount) * 100
                                          }%`
                                      }}></div>
                                    </div>
                                  </div>


                                  <div className="dynamic-list-char_item">
                                    <div
                                      className="dynamic-list-char_item_container"
                                    >
                                      <div
                                        style={{
                                          width: '12px',
                                          position: 'relative',
                                          paddingRight: '12px'
                                        }}
                                      >
                                      </div >
                                      <div
                                        style={{
                                          backgroundColor: colorsTypes[String(4).charAt(0)],
                                          width: '14px',
                                          height: '14px',
                                          top: '4px',
                                          position: 'absolute',
                                          left: '0px',
                                          borderRadius: '50%',
                                        }}
                                      >
                                      </div>
                                      <div className="dynamic-list-char_item_name">
                                        Неоплаченные безнал
                                  </div>
                                      <div className="dynamic-list-char_item_count">
                                        {
                                          this.state.byPeriod?.filter(item => item.period === this.state.timeZone)[0]?.not_payed_cashless_count
                                        }
                                      </div>
                                    </div>
                                    <div className="dynamic-list-char_item_line">
                                      <div style={{
                                        width: `${this.state.byPeriod?.filter(item => item.period === this.state.timeZone)[0]?.not_payed_cashless_count / (this.state.byPeriod?.filter(item => item.period === this.state.timeZone)[0]?.allCount) * 100
                                          }%`
                                      }}></div>
                                    </div>
                                  </div>


                                </div>
                              </PerfectScrollbar>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className>
                    <div className="blocks-canal">
                      <h2>
                        Каналы продажей
                    </h2>
                      {
                        this.state.bySourceLoader ?
                          <div className="centerLoader">
                            <Spinner
                            ></Spinner>
                          </div> : (<>

                            {
                              this.state.charts.bySource !== null && !this.state.charts.bySource.length ? <div className="not_data">Нет данных</div> : null
                            }

                            {
                              this.state.charts.bySource !== null && this.state.charts.bySource.length ? (
                                <Chart
                                  width={'500px'}
                                  height={'300px'}
                                  chartType="PieChart"
                                  loader={<div>Загрузка!</div>}
                                  data={[
                                    ['Task', 'Hours per Day'],
                                    ...this.state.charts.bySource,
                                  ]}
                                  rootProps={{ 'data-testid': '1' }}
                                  options={{
                                    legend: 'none',
                                    colors: colorsTypes,
                                  }}
                                />
                              ) : null

                            }</>
                          )}



                      <PerfectScrollbar
                        options={{
                          wheelSpeed: 0.3,
                          wheelPropagation: false
                        }}
                        style={{
                          width: '100%',
                          height: '270px',
                          padding: '0 12px',
                        }}
                      >
                        <div className="dynamic-list-char">
                          {this.state.bySource !== null && this.state.bySource.map((item, index) => {
                            return (<div className="dynamic-list-char_item">
                              <div
                                className="dynamic-list-char_item_container2"
                              >
                                <div
                                  style={{
                                    width: '12px',
                                    position: 'relative',
                                    paddingRight: '12px'
                                  }}
                                >
                                </div >
                                <div
                                  style={{
                                    backgroundColor: colorsTypes[String(index).charAt(0)],
                                    width: '14px',
                                    height: '14px',
                                    top: '4px',
                                    position: 'absolute',
                                    left: '0px',
                                    borderRadius: '50%',
                                  }}
                                >
                                </div>
                                <div className="dynamic-list-char_item_name">
                                  {item.source_name}
                                </div>
                                <div className="dynamic-list-char_item_count">
                                  {item.operation_count}
                                </div>
                              </div>
                              <div className="dynamic-list-char_item_line">
                                <div style={{ width: `${item.prc}%` }}></div>
                              </div>
                            </div>)
                          })}
                        </div>
                      </PerfectScrollbar>
                    </div>
                  </div>


                </div>


                <div className="dynamic-sales">
                  <div className="dynamic-sales_container   ">
                    <h2>
                      Динамика продаж
                </h2>
                    <div>
                      {
                        this.state.byDynamicLoader ?
                          <div className="centerLoader">
                            <Spinner
                            ></Spinner>
                          </div> : (<>

                            {
                              this.state.charts.byDynamic !== null && (
                                <Chart
                                  width={'100%'}
                                  height={'400px'}
                                  chartType="LineChart"
                                  loader={<div>Loading Chart</div>}
                                  data={[
                                    ['x', 'Билетов'],
                                    ...this.state.charts.byDynamic,
                                  ]}
                                  chartLanguage='by'
                                  options={{

                                    hAxis: {
                                      title: 'Дата',
                                    },
                                    vAxis: {
                                      title: 'Билетов',
                                    },
                                  }}
                                  rootProps={{ 'data-testid': '1' }}
                                />
                              )
                            }
                          </>
                          )
                      }
                    </div>
                  </div>
                </div>

                <div
                  className="blocks-map">
                  <h2>
                    География продаж
                    </h2>


                  {
                    this.state.byCountryLoader ?
                      <div
                        className="centerLoader">
                        <Spinner
                        ></Spinner>
                      </div>
                      : (<>
                        {
                          this.state.charts.ByCountries !== null && !this.state.charts.ByCountries.length ? <div className="not_data">Нет данных</div> : null
                        }

                        {
                          this.state.charts.ByCountries !== null && this.state.charts.ByCountries.length && (
                            <>
                              <Chart
                                width={'100%'}
                                height={'100%'}
                                chartType="GeoChart"
                                data={[
                                  ['Страна', 'Кол-во билетов'],
                                  ...this.state.charts.ByCountries
                                ]}
                                options={
                                  {
                                    colorAxis: { colors: ['#cddaef', '#2879f2'] },
                                  }
                                }
                                // Note: you will need to get a mapsApiKey for your project.
                                // See: https://developers.google.com/chart/interactive/docs/basic_load_libs#load-settings
                                mapsApiKey="YOUR_KEY_HERE"
                                rootProps={{ 'data-testid': '1' }}
                              />

                              <PerfectScrollbar
                                options={{
                                  wheelSpeed: 0.3,
                                  wheelPropagation: false
                                }}
                                style={{
                                  width: '30%',
                                  padding: '0 12px',
                                  minWidth: '430px',
                                }}
                              >
                                <div className="dynamic-list-country">
                                  {this.state.ByCountries !== null && (<>
                                    <div className="dynamic-list-char_item">
                                      <div
                                        className="dynamic-list-char_item_container2"
                                      >
                                        <div
                                          style={{
                                            width: '30px',
                                            position: 'relative'
                                          }}
                                        >
                                        </div >

                                        <div className="dynamic-list-char_item_name">
                                          Страна
                                                    </div>
                                        <div className="dynamic-list-char_item_count">
                                          Кол-во
                                                    </div>
                                      </div>
                                      <div className="dynamic-list-char_item_country">
                                        %
                                                  </div>
                                    </div>

                                    {
                                      _.orderBy(this.state.ByCountries, ['ticket_count'], ['desc']).map((item, index) => {
                                        return (<div className="dynamic-list-char_item">
                                          <div
                                            className="dynamic-list-char_item_container2"
                                          >
                                            <div
                                              style={{
                                                width: '30px',
                                                position: 'relative'
                                              }}
                                            >
                                              {index + 1}
                                            </div >

                                            <div className="dynamic-list-char_item_name">
                                              {item.country_name}
                                            </div>
                                            <div className="dynamic-list-char_item_count">
                                              {item.ticket_count}
                                            </div>
                                          </div>
                                          <div className="dynamic-list-char_item_country">
                                            {item.prc}%
                                                  </div>
                                        </div>
                                        )

                                      })
                                    }
                                  </>)
                                  }
                                </div>
                              </PerfectScrollbar>
                            </>
                          )
                        }
                      </>
                      )
                  }

                </div>
                {
                  this.state.byDynamicLoader ?
                    <div className="centerLoader">
                      <Spinner
                      ></Spinner>
                    </div> : (<>


                      <div className="blocks-coast">
                        <div className="container">
                          <div className="blocks-coast_container">
                            <div className="blocks-coast_header">
                              <div className="blocks-coast_header_name">Ценовая категория</div>
                              <div className="blocks-coast_header_coast">Цена</div>
                              <div className="blocks-coast_header_qnt">Кол-во</div>
                              <div className="blocks-coast_header_prc">Процент продаж</div>
                            </div>
                            {
                              this.state.byPrice !== null && !this.state.byPrice.length && (
                                <div className="blocks-coast_item">
                                  Нет данных
                                </div>
                              )
                            }
                            {
                              this.state.byPrice !== null && this.state.byPrice.map(item => {
                                return (
                                  <div className="blocks-coast_item">
                                    <div className="blocks-coast_item_name">{item.price_name_ru}</div>
                                    <div className="blocks-coast_item_coast">{item.cost_price}</div>
                                    <div className="blocks-coast_item_qnt">{item.operation_count}</div>
                                    <div className="blocks-coast_item_prc">
                                      <div className="blocks-coast_item_prc_container">
                                        {item.operation_count_prc} %
                            </div>
                                    </div>

                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>
                      </div>
                    </>
                    )}
              </div>

            </section>
          </div>
        </main>
      </>
    )
  }
}
