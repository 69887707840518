import React, { Component } from 'react';
import './style.scss';
import './media.scss';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom'

class EventMenu extends Component {

  LinkToEvent = () => {
    const { idEvent } = this.props;
    if (this.props.idEvent) {
      this.props.history.push(`/event/${idEvent}`);
    } else {
      this.props.history.push('/');
    }
  }

  LinkToReserve = () => {
    const { idEvent } = this.props;
    if (this.props.idEvent) {
      this.props.history.push(`/event-reserve/${idEvent}`);
    } else {
      this.props.history.push('/');
    }
  }

  render() {
    const { currentMenu } = this.props;
    return (
      <>
        <div className="event-menu">
          <ul>
            <li><a href="" className={currentMenu === 1 ? 'active' : ''} onClick={(e) => { e.preventDefault(); this.LinkToEvent() }}>Работа с заказами</a></li>
            <li><a href="" className={currentMenu === 2 ? 'active' : ''} onClick={(e) => { e.preventDefault(); this.LinkToReserve() }}>Резервирование</a></li>
            <li><Link to={`/event-orders/${this.props.match?.params?.eventID || ''}`} className={currentMenu === 3 ? 'active' : ''}>Заказы</Link></li>
            {/* <li><a href="" className={currentMenu === 4 ? 'active' : ''}>Аналитика</a></li> */}
            <li><Link to={`/analytics/${this.props.idEvent}`} className={currentMenu === 4 ? 'active' : ''}>Аналитика</Link></li>
            {/* <li><a href="" className={currentMenu === 5 ? 'active' : ''}>Проходная система</a></li> */}
            {/* <li><a href="" className={currentMenu === 6 ? 'active' : ''}>Сайт</a></li>. */}
          </ul>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  totalEvent: state.event.totalEvent,
  idEvent: state.event.totalEventId,
});

const mapDispatchToProps = {

};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventMenu));

