import React, { Component } from 'react';
import './style.scss';
import './media.scss';
import EventBox from '../../components/EventBox/EventBox';
import EventMenu from '../../components/EventMenu/EventMenu';
import EventOrdersFilter from '../../components/EventOrdersFilter/EventOrdersFilter';
import EventOrdersSearch from '../../components/EventOrdersSearch/EventOrdersSearch';
import EventOrderList from '../../components/EventOrderList/EventOrderList';
import { connect } from "react-redux";
import moment from "moment";
import {
  setCurrentOrder
} from '../../store/actions/orders';
import { withRouter } from 'react-router-dom';

class Order extends Component {

  componentDidMount() {
    this.props.setCurrentOrder(this.props.match?.params?.id)
  }

  render() {
    return (
      <>
        <main className="main">
          <div className="event-panel">
            <div className="event-panel">
              <div className="event-panel__head">
                <div className="event-panel__head-left">
                  <button className="event-back" onClick={() => { this.props.history.push('/event-orders') }}>
                    <span className="icon icon-arr-left"></span>
                  </button>
                  <div className="event-box">
                    <div className="event-box__title">
                      Заказ - {this.props.match?.params?.id}
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <EventMenu
              history={this.props.history}
              currentMenu={3}
            />
          </div>
          <EventOrderList />
        </main>
      </>
    )
  }
}

const mapStateToProps = state => ({
  totalEvent: state.event.totalEvent,
  idEvent: state.event.totalEventId,
  planList: state.seatScheme.list
});

const mapDispatchToProps = {
  setCurrentOrder
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Order));
