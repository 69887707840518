import React, { Component } from 'react';
import moment from "moment";
import { connect } from "react-redux";
import { getListOrders, setDefaultSearch, setIdFindOrders } from '../../store/actions/orders';
import EventOrdersListHeader from '../EventOrderListHeader';
import { newFilteredArrayOrders } from "../../store/reducers/OrdersResellect";
import { withRouter } from 'react-router-dom';
import { setTotalEvent } from '../../store/actions/event';
import OrdersSidebar from '../../components/OrderSidebar/OrderSidebar';
import { parseNameAttribute } from "../../helpers/index";

import './style.scss';
import './media.scss';

class EventOrdersList extends Component {


  state = {
    selection: [],
    selectAll: false,
  }


  toggleSelection = (key, onlySave) => {
    let selection = [...this.state.selection];
    const keyIndex = selection.indexOf(key);
    if (keyIndex >= 0) {
      if (!onlySave) {
        selection = [
          ...selection.slice(0, keyIndex),
          ...selection.slice(keyIndex + 1),
        ];
        this.setState((prevState) => ({
          ...prevState,
          selection,
        }));
      }
    } else {
      selection.push(key);
    }
    this.setState((prevState) => ({
      ...prevState,
      selection,
    }));
  };

  toggleAll = () => {
    const selectAll = !this.state.selectAll;
    const selection = [];
    if (selectAll) {
      const currentRecords = this.props.tickets;
      currentRecords.forEach((item) => {
        selection.push(item.id);
      });
    }
    this.setState({
      selectAll,
      selection,
    });
  };


  componentDidMount = async () => {
    this.props.setDefaultSearch();
    if (this.props.match.params.eventID) {
      this.props.setIdFindOrders(this.props.match.params.eventID)
    }
    if (+this.props.totalEvent?.id !== +this.props.match.params.eventID && this.props.match.params.eventID) {
      this.props.setTotalEvent(this.props.match.params.eventID)
    }
    // this.props.getListOrders(this.props.match.params.eventID);
    // document.addEventListener('scroll', this.handleScroll, false);
    // document.addEventListener('wheel', this.handleScroll, false);
    //   document.addEventListener("click", function (event) {
    //     alert(event.shiftKey ? "ctrl key pressed" : "ctrl key not pressed");
    // });
  }

  componentDidUpdate() {
    // if (!this.props.preloader &&
    //   document.getElementsByClassName('main')[0]?.clientHeight >
    //   document.getElementsByClassName('event-orders-table__tbody')[0]?.clientHeight) {
    //   this.handleScroll()
    // }
  }

  componentWillUnmount() {
    this.props.setDefaultSearch();
    // document.removeEventListener('scroll', this.handleScroll, false);
    // document.removeEventListener('wheel', this.handleScroll, false);
  }

  // handleScroll = async () => {
  //   const { preloader } = this.props;
  //   const scroll = document.documentElement || document.body;
  //   if (!preloader) {
  //     if ((scroll.scrollTop + scroll.clientHeight) + (scroll.scrollHeight / 100) > scroll.scrollHeight) {
  //       this.props.getListOrders()
  //     }
  //   }
  // }

  formatedData = (date) => {
    if (date) {
      return (date.substring(8, 10) + ' ' + moment(date).locale('ru').format('MMMM').substring(0, 3) + ',' +
        moment(date).locale('ru').format('YYYY') + ', ' + moment(date).format('H:mm'));
    } else {
      return null;
    }
  }

  render() {
    const { tickets } = this.props;
    const { selection } = this.state;
    return (
      <div className="event-order">
        <div className="event-orders-list">
          <div className="event-orders-table">
            <EventOrdersListHeader toggleAll={this.toggleAll} clearIdFind={this.clearIdFind} />
            <div className="event-orders-table__tbody">
              {
                tickets.map((item) => {
                  return (
                    <div
                      onClick={() => {
                        this.toggleSelection(item.id, false)
                      }}
                      onMouseEnter={e => {
                        if (e.shiftKey) {
                          this.toggleSelection(item.id, true)
                        }
                      }} key={item.id} className={`event-orders-table__row ${selection.includes(item.id) ? "event-orders-table__row_active" : ""}`}>
                      <div className="event-orders-table__col event-orders-checkbox">
                        <div className="checkbox">
                          <input
                            checked={selection.includes(item.id)} id={`ticketpro-${item.id}`} type="checkbox" name="ticketpro" />
                          <label
                          // htmlFor={`ticketpro-${item.id}`}

                          ></label>
                        </div>
                      </div>
                      <div className="event-orders-table__col event-orders-date">{this.formatedData(item.changed)}</div>
                      <div className="event-orders-table__col event-orders-return">{item.event_names[0].name}</div>
                      <div className="event-orders-table__col event-orders-return">{item.event_place?.descriptions[0]?.name}</div>
                      <div className="event-orders-table__col event-orders-return">{item.barcode}</div>
                      <div className="event-orders-table__col event-orders-return">{parseNameAttribute(this.props.planList, item.ticket_name)?.join(' ')}</div>
                      <div className="event-orders-table__col event-orders-status">{item.status[0]?.name}</div>
                      <div className="event-orders-table__col event-orders-status">{item.ticket_template_type_name}</div>
                      <div className="event-orders-table__col event-orders-status">{item.price} BYN</div>
                      {/* <div className="event-orders-table__col event-orders-date">{this.formatedData(item.inserted)}</div> */}
                      {/* <div className="event-orders-table__col event-orders-return">{item.status_id === -2 && this.formatedData(item.changed)}</div> */}
                      {/* <div className="event-orders-table__col event-orders-tickets">{item.tickets.length}</div> */}
                    </div>
                  )
                })
              }
            </div>
          </div>
          {
            this.props.preloader && (
              <div className="loading">
                <div className="loading-box">
                  <span className="icon icon-spinner"></span>
                </div>
              </div>
            )
          }
        </div>
        <OrdersSidebar selection={selection} />
      </div>
    )
  }
}

const makeMapStateToProps = () => {
  const mapStateToProps = state => {
    return {
      tickets: state.orders.currentOrder.data?.tickets || [],
      preloader: state.orders.preloader,
      totalEvent: state.event.totalEvent?.event,
      planList: state.seatScheme.list
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = {
  getListOrders,
  setDefaultSearch,
  setIdFindOrders,
  setTotalEvent
};

export default withRouter(connect(makeMapStateToProps, mapDispatchToProps)(EventOrdersList));
