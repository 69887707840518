import React, { Component } from 'react';
import './style.scss';
import './media.scss';

class OrderReservationSuccess extends Component {
  render() {
    const { reserved } = this.props
    return (
      <>
        <div className="order-reservation-success">
          <div className="modal-title">Бронь успешно создана</div>
          <div className="order-reservation-success__text">Номер бронирования</div>
          <div className="order-reservation-success__number">{reserved.code}</div>
          <div className="order-reservation-success__info">
            <p>Запомните номер бронирования. При покупке в точках продаж необходимо сказать продавцу номер бронирования.</p>
            {/* <p>Так же вы можете отправить ссылку на онлайн оплату и информацию о бронированиина электронный адрес.</p> */}
          </div>
          <div className="form-button">
            <button onClick={this.props.checkOrder} className="btn">Завершить бронирование</button>
          </div>
        </div>
      </>
    )
  }
}

export default OrderReservationSuccess;
