import React, { Component } from 'react';
import { getEventStats } from "../../api/events";
import { withRouter } from "react-router-dom";
import Spinner from "../../components/Spinner";
import './style.scss';
import './media.scss';

class EventPanelStatistics extends Component {
  state = {
    free_count: 0,
    in_basket_count: 0,
    not_payed_cashless_count: 0,
    sold_cashless_count: 0,
    sold_count: 0,
    loader: false,
    error: false
  }

  componentDidMount() {
    this.setState({
      loader: true, error: false

    })
    getEventStats(this.props.match.params.eventID)
      .then(res => {
        this.setState({
          ...res,
          loader: false,
          error: false,
        })
      })
      .catch(() => {
        this.setState({
          loader: false,
          error: true,
        })
      })
  }

  render() {
    const { sold_count, sold_cashless_count, free_count, not_payed_cashless_count, loader, error } = this.state;
    return (
      <>
        {
          loader ? (
            <Spinner />
          ) : (
              <div className="event-panel__statistics">
                {
                  error ? (
                    <div>
                      Произошла ошибка
                    </div>
                  ) : (
                      <>
                        <div className="event-panel__statistics-box">В продаже <b>{free_count}</b></div>
                        <div className="event-panel__statistics-box">Продано <b>{sold_count}</b></div>
                        <div className="event-panel__statistics-box">Свободно <b>{not_payed_cashless_count}</b></div>
                        <div className="event-panel__statistics-box">Безнал <b>{sold_cashless_count}</b></div>
                      </>
                    )
                }
              </div>
            )
        }
      </>
    )
  }
}

export default withRouter(EventPanelStatistics);
