import React, { Component } from 'react';
import './style.scss';
import './media.scss';
import Pagination from '../Pagination/Pagination';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import {
  setAsyncEvent,
} from '../../store/actions/report';
import { withRouter } from 'react-router-dom';
class ReportsList extends Component {

  formatedData = (date) => {
    if (date) {
      return (date.substring(8, 10) + ' ' + moment(date).locale('ru').format('MMMM').substring(0, 3) + ', ' + moment(date).format('H:mm'));
    } else {
      return null;
    }
  }

  getPlaces = (id) => {
    const { placesList } = this.props;
    if (placesList.length && id) {
      let place = ((_.find(placesList, { id: id })));
      if (place) {
        return place.descriptions[0].name;
      }
    }
    return "";
  }

  getCities = (id) => {
    const { placesList, citiesList } = this.props;
    if (placesList.length && id && citiesList.length) {
      let id_places = (_.find(placesList, { id: id })).city_id;
      let city = (_.find(citiesList, { id: id_places }));
      if (city) {
        return city.descriptions[0].name;
      }
    }
    return "";
  }

  _onPressPage = (min, max) => {
    this.props.setAsyncEvent({
      ...this.props.filter,
      min: min,
      max: max,
    });
  }

  render() {
    return (
      <>
        <div className="reports-list">
          <div className="reports-table">
            <div className="reports-table__thead">
              <div className="reports-table__row">
                <div className="reports-table__col report-check"></div>
                <div className="reports-table__col report-date">Дата/Время<span className="icon icon-sort"></span></div>
                <div className="reports-table__col report-name">Мероприятие<span className="icon icon-sort"></span></div>
                <div className="reports-table__col report-place">Место проведения<span className="icon icon-sort"></span></div>
                <div className="reports-table__col report-city">Город<span className="icon icon-sort"></span></div>
                <div className="reports-table__col report-status">Статус<span className="icon icon-sort"></span></div>
              </div>
            </div>
            <div className="reports-table__tbody">
              {
                this.props.loading && (
                  <div className="loading">
                    <div className="loading-box">
                      <span className="icon icon-spinner"></span>
                    </div>
                  </div>
                )
              }
              {
                !this.props.loading && this.props.eventsList.map(item => {
                  return (
                    <div className="reports-table__row">
                      <div className="reports-table__col report-check">
                        {/* <div className="checkbox">
                        <input id="report-1" type="checkbox" name="report" />
                        <label htmlFor="report-1"></label>
                      </div> */}
                      </div>
                      <div className="reports-table__col report-date">{this.formatedData(item.date_time_from)}</div>
                      <div className="reports-table__col report-name">{item.descriptions[0].name}</div>
                      <div className="reports-table__col report-place">{this.getPlaces(item.place_id)}</div>
                      <div className="reports-table__col report-city">{this.getCities(item.place_id)}</div>
                      <div className="reports-table__col report-status">{item.event_statuses[0].name}</div>
                      <span onClick={
                        () => {
                          if(this.props?.itsAnalytic) {
                            this.props.setTotalEvent(item.id)
                            this.props.history.push(`/analytics/${item.id}`)
                            return true;
                          }
                          this.props.history.push(`/report-data/${this.props.typeSearch}/promoter_id=${item.promoter_id}&${this.props.search(item.id, this.props.dateFrom, this.props.dateTo, this.props.date_step)}`)
                        }
                      } className="btn">Выбрать</span>
                    </div>
                  )
                })
              }
            </div>
          </div>
          <div className="reports-foot">
            <Pagination
              maxPage={this.props.maxPages}
              currentPage={this.props.currentPage}
              onPress={(min, max) => { !this.props.loading && this._onPressPage(min, max) }}
            />
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    eventsList: state.report.searchListEvents.list?.events || [],
    placesList: state.places.list,
    citiesList: state.cities.list,
    maxPages: state.report.searchListEvents.count,
    currentPage: state.report.searchListEvents.currentPage,
    filter: state.report.searchListEvents.filter,
    loading: state.report.searchListEvents.loading
  }
}

const mapDispatchToProps = {
  setAsyncEvent
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportsList));
