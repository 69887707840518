import { SEAT } from '../const';

const initialState = {
    list: [],
};

export default function seatScheme(state = initialState, action) {
    switch (action.type) {
        case SEAT.SET:
            return Object.assign({}, state, { list: action.payload });
        default:
            return state;
    }
}