import React, { Component } from 'react';
import './style.scss';
import './media.scss';
import LayoutTicket from '../LayoutTicket/LayoutTicket';
import { setOrderAnalog } from '../../api/order'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

class OrderPayment extends Component {
  state = {
    error: false,
    loader: false,
    setTimeoutPromo: null,
    errorPromo: false,

    countBonus: 0,
    countCodes: 0,
    valueCoastPromo: false,
  }

  refFormik = React.createRef();

  componentDidMount() {
    try {

      this.props.orderCreateSell({
        is_sale: true,
      })
        .catch((er) => {
          this.refFormik.current.setStatus({
            error: er.response.data.text
          })
        })
    } catch (er) {
      console.log(er)
    }
  }

  setOrderDiscount = (analog_code, amount) => {
    try {
      this.setState({
        valueCoastPromo: false,
      })
    clearTimeout(this.state.setTimeoutPromo)
    if (analog_code && amount) {
      this.setState({
        errorPromo: false,
        setTimeoutPromo: setTimeout(() => {
          setOrderAnalog({
            order_id: this.props.orderId,
            analog_code,
            amount
          })
            .then(({data}) => {
              this.refFormik.current.setFieldValue('analog_code', '')
              this.refFormik.current.setFieldValue('amount', '')
              this.props.updateOrder(data)

              this.setState({
                valueCoastPromo: amount
              })
              // let sumBonus = 0
              // let sumBalls = 0
              // data.used_analogs.forEach(item => {
              //   if(item.analog_type_id === 2) {
              //     sumBalls = +sumBalls + item.sum
              //   } else {
              //     sumBonus = +sumBonus + item.sum
              //   }
              // });
            })
            .catch(er => {
              this.setState({
                errorPromo: er.response.data.text,
              })
            })
        }, 1000)
      })
    } 
  } catch(er) {
    console.error(er)
  }
  }

  render() {
    const validateForm = Yup.object().shape({
      CASH: Yup.number().min(0, 'Минимальное значение 0').typeError('Необходимо числовое значение'),
      MS: Yup.number().min(0, 'Минимальное значение 0').typeError('Необходимо числовое значение'),
    })
    return (
      <>
        <div className="order-payment">
          <div className="modal-title">Выберите способ оплаты и распечатайте заказ</div>
          <Formik
            validationSchema={validateForm}
            onSubmit={async (values, formik) => {
              try {
                formik.setStatus({})
                if (+this.props.coast === +(+values.MS + +values.CASH).toFixed(2)) {
                  try {
                    let data = {}
                    this.props.activeLoader()
                    if (values.MS > 0) {
                      data.pay_sum_1 = values.MS
                      data.ident_type_1 = 'MS'
                    }
                    if (values.CASH > 0) {
                      data[`pay_sum_${data.hasOwnProperty('pay_sum_1') ? 2 : 1}`] = values.CASH
                      data[`ident_type_${data.hasOwnProperty('ident_type_1') ? 2 : 1}`] = 'CASH'
                    }
                    await this.props.orderCreatePayment(data)
                      .catch(er => {
                        formik.setStatus({
                          error: er.response.data.text,
                        })
                        this.setState({
                          loader: false,
                        })
                      })
                      .finally(() => {
                        this.props.hideLoader()
                      })
                  } catch (er) {
                    this.props.hideLoader()
                  }
                } else {
                  formik.setStatus({
                    error: `Необходима сумма - `
                  })
                }
              } catch (er) {
                console.log(er)
              }
            }}
            initialValues={{
              analog_code: '',
              amount: '',
              MS: 0,
              CASH: 0,
            }}
            innerRef={this.refFormik}
            render={({
              values,
              errors,
              setFieldValue,
              handleSubmit,
              touched,
              ...formik
            }) => (
                <Form
                  className="form-control-content-contacts"
                  onSubmit={handleSubmit}
                >
                  {
                    formik?.status?.error && (
                      <div className="form-error-message">
                        {formik?.status?.error}{this.props.coast}
                      </div>
                    )
                  }
                  <div className="order-payment__form">
                    <div className="order-payment__group">
                      <div className="order-payment__row">
                        <h3 className="payment-error">{this.state.error}</h3>
                        <div className="order-payment__col payment-icon"><span className="icon icon-cash"></span></div>
                        <div className="order-payment__col payment-title">Наличные</div>
                        <div className="order-payment__col payment-field"><span className="currency">BYN</span>
                          <input
                            className={(touched.CASH && errors.CASH) ? 'payment-field-error' : ''}
                            onChange={(e) => setFieldValue('CASH', e.target.value)}
                            disabled={this.state.loader}
                            value={values.CASH}
                            type="text"
                            defaultValue="0.00" />
                        </div>
                      </div>
                      <div className="order-payment__row">
                        <div className="order-payment__col payment-icon"><span className="icon icon-bank-card"></span></div>
                        <div className="order-payment__col payment-title">Банковская карта</div>
                        <div className="order-payment__col payment-field"><span className="currency">BYN</span>
                          <input
                            className={(touched.MS && errors.MS) ? 'payment-field-error' : ''}
                            onChange={(e) => setFieldValue('MS', e.target.value)}
                            disabled={this.state.loader}
                            value={values.MS}
                            type="text"
                            defaultValue="0.00" />
                        </div>
                      </div>
                    </div>
                    <div className="order-payment__group">
                      <h3 className="payment-error">{this.state.errorPromo}</h3>
                      <div className="order-payment__row">
                        <div className="order-payment__col payment-icon"><span className="icon icon-certificate"></span></div>
                        <div className="order-payment__col payment-title">Подарочный сетификат</div>
                        <div className="order-payment__col payment-field">
                          <input
                            className={(this.state.errorPromo) ? 'payment-field-error' : ''}
                            onChange={(e) => {
                              this.setOrderDiscount(e.target.value, values.amount)
                              return setFieldValue('analog_code', e.target.value)
                            }}
                            disabled={this.state.loader}
                            value={values.analog_code}
                            type="text" /></div>
                        <div className="order-payment__col payment-price"></div>
                      </div>
                      <div className="order-payment__row">
                        <div className="order-payment__col payment-icon"><span className="icon icon-bonus"></span></div>
                        <div className="order-payment__col payment-title">Бонусные баллы</div>
                        <div className="order-payment__col payment-field"><button>
                          <span className="icon icon-refresh"></span></button>
                          <input
                            className={(this.state.errorPromo) ? 'payment-field-error' : ''}
                            onChange={(e) => {
                              this.setOrderDiscount(values.analog_code, e.target.value)
                              return setFieldValue('amount', e.target.value)
                            }}
                            disabled={this.state.loader}
                            value={values.amount}
                            type="text" />
                        </div>
                          <div className="order-payment__col payment-price">{this.state.valueCoastPromo} {this.state.valueCoastPromo && 'BYN'}
                          </div>
                      </div>
                    </div>
                  </div>
                  {/* <LayoutTicket changeTempls={this.props.changeTempls} typesTemplsList={this.props.typesTemplsList} /> */}
                  <div className="form-button">
                    <button
                      disabled={this.state.loader}
                      className="btn"
                      type="submit"
                    >Оплатить и распечатать</button>
                    {
                      this.props.orderId && (
                        <button
                          disabled={this.state.loader}
                          onClick={this.props.orderDelete}
                          className="btn btn-link"><span className="icon icon-cart"
                          ></span>Анулировать заказ</button>
                      )
                    }
                  </div>
                </Form>
              )}
          />
        </div>
      </>
    )
  }
}

export default OrderPayment;
