import React, { Component } from 'react'
import { connect } from "react-redux";
import { sortEventsById } from '../../store/actions/orders'

class EventOrdersListHeader extends Component {
    state = {
        type: false,
        status: false
    }
    sortByType = (type) => {
        if (!this.props.preloader) {
            if (type === this.state.type) {
                if ('desc' === this.state.status) {
                    this.setState({
                        status: 'asc'
                    }, () => {
                        this.props.sortEventsById(this.state)
                    })
                }
                else {
                    this.setState({
                        status: 'desc'
                    }, () => {
                        this.props.sortEventsById(this.state)
                    })
                }
            } else {
                this.setState({
                    type: type,
                    status: 'desc'
                }, () => {
                    this.props.sortEventsById(this.state)
                })
            }
        }
    }
    render() {
        return (
            <div className="event-orders-table__thead">
                <div className="event-orders-table__row">
                    <div className="event-orders-table__col event-orders-checkbox">
                        <div className="checkbox">
                            <input onClick={() => { this.props.toggleAll() }} id={`ticketpro-0}`} type="checkbox" name="ticketpro" />
                            <label htmlFor={`ticketpro-0}`} ></label>
                        </div>
                    </div>
                    <div onClick={() => this.sortByType('date')} className="event-orders-table__col event-orders-date">Дата, время<span className="icon icon-sort"></span></div>
                    <div onClick={() => this.sortByType('name')} className="event-orders-table__col event-orders-return">Название мереоприятия<span className="icon icon-sort"></span></div>
                    <div onClick={() => this.sortByType('venue')} className="event-orders-table__col event-orders-return">Площадка<span className="icon icon-sort"></span></div>
                    <div onClick={() => this.sortByType('id')} className="event-orders-table__col event-orders-return">Штрихкод<span className="icon icon-sort"></span></div>
                    <div onClick={() => this.sortByType('address')} className="event-orders-table__col event-orders-return">Адрес места<span className="icon icon-sort"></span></div>

                    {/* <div onClick={() => this.sortByType('ticket')} className="event-orders-table__col event-orders-tickets">Билеты<span className="icon icon-sort"></span></div> */}
                    <div onClick={() => this.sortByType('status')} className="event-orders-table__col event-orders-status">Статус<span className="icon icon-sort"></span></div>
                    <div onClick={() => this.sortByType('ticket_status')} className="event-orders-table__col event-orders-status">Тип<span className="icon icon-sort"></span></div>
                    <div onClick={() => this.sortByType('coast')} className="event-orders-table__col event-orders-status">Стоимость<span className="icon icon-sort"></span></div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    orders: state.orders.orders,
    preloader: state.orders.preloader,
});

const mapDispatchToProps = {
    sortEventsById,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventOrdersListHeader);
