import React, { Component } from 'react';
import ReportsFilter from '../../../components/ReportsFilter/ReportsFilter';
import ReportsList from '../../../components/ReportsList/ReportsList';

class Reports extends Component {
    render() {
        return (
            <>
                <main className="main">
                    <div className="container">
                        <section className="reports">
                            <ReportsFilter
                                selectDate={true}
                                selectCategories={false}
                                eventsShow={true}
                                date_step={true}
                                typeSearch="dynamic"
                                search={(event, date_from, date_to, date_step) => `event_id=${event}&period_from=${date_from}&period_to=${date_to}&date_step=${date_step}`}
                            />
                        </section>
                    </div>
                </main>
            </>
        )
    }
}

export default Reports;
