import instance from "./index";
import serviceApi from './service';
import { Server } from "http";

export const getJSONReportEvent = (data) => {
    return serviceApi(`/ticket-wp-api/v1/get-promoter-event-report/`, data);
}

export const getJSONReportCategory = (data) => {
    return serviceApi(`/ticket-wp-api/v1/get-event-category-sales-report/`, data);
}

export const getJSONReportGeo = (data) => {
    return serviceApi(`/ticket-wp-api/v1/get-sales-geography-report/`, data);
}

export const getJSONReportSeats = (data) => {
    return serviceApi(`/ticket-wp-api/v1/get-seat-stats-report/`, data);
}

export const getJSONReportDynamic = (data) => {
    return serviceApi(`/ticket-wp-api/v1/get-sales-dynamics-report/`, data);
}
