import React, { Component } from "react";
import "./style.scss";
import "./media.scss";
import Spinner from "../Spinner";
class ReserveTypes extends Component {
  render() {
    return (
      <>
        {!this.props.loaderModal ? (
          <div className="reserve-types">
            <div className="modal-title">Выберите тип резервации</div>
            <div className="reserve-types__list">
              {this.props.listLocks.length &&
                this.props.listLocks.map((type, key) => {
                  return (
                    <div
                      onClick={() => this.props.changeSeatLock(type.id)}
                      id={type.id}
                      className={`reserve-types__box${this.props.seatLock === type.id ? " active" : ""
                        }`}
                      key={key}
                    >
                      <div
                        className="reserve-types__box-color"
                        style={type.color === "#fff" ? { backgroundColor: type.color, border: "1px solid black" } : { backgroundColor: type.color }}
                      ></div>
                      <div className="reserve-types__box-title">
                        {type.name}
                      </div>
                    </div>
                  );
                })}
            </div>
            {
              this.props.error && (
                <div className="form-error">
                {this.props.error}
              </div>
              )
            }
            <div className="form-button">
              {/* <button
                className="btn"
                onClick={this.props.showReserveCreateType}
              >
                Создать новый тип
                </button> */}
              {this.props.seatLock !== false && (
                <button
                  className="btn"
                  onClick={this.props.createReserveSeats}
                >
                  <span className="icon icon-edit"></span>Изменить тип резервации
                </button>
              )
              }
            </div>
          </div>
        ) : (
            <Spinner />
          )}
      </>
    );
  }
}

export default ReserveTypes;
