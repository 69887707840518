import React, {Component} from 'react';
import './style.scss';
import './media.scss';

class EventTicket extends Component{
  render() {
    const { place, id, coast, deleteTicket, basketID } = this.props;
    if(place) {
      return (
        <>
          <div className="event-ticket__box">
            <div className="event-ticket__box-body">
              <div className="event-ticket__box-place"><b>{place[0] ? `${place[0][0]} ${place[0][1]}`: null}</b></div>
              <div className="event-ticket__box-place"><b>{place[1] ? `${place[1][0]} ${place[1][1]}`: null}</b></div>
              <div className="event-ticket__box-place">{place[2] ? `${place[2][0]} ${place[2][1]}`: null}</div>
              <div className="event-ticket__box-place">{place[3] ? `${place[3][0]} ${place[3][1]}`: null}</div>
            </div>
            <div className="event-ticket__box-foot">
              <div className="event-ticket__box-price">{coast} BYN</div>
              <div className="event-ticket__box-delete">
                <button onClick={() => deleteTicket(id, basketID)}><span className="icon icon-cart"></span>Удалить</button>
              </div>
            </div>
          </div>
        </>
      )
    } else {
      return null;
    }
  }
}

export default EventTicket;
