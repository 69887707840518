import React, { Component } from "react";
import "./style.scss";
import "./media.scss";
import Select from "react-select";
import { getPdfTickets } from "../../api/reserve";
import printJS from "print-js";

const options = [
  { value: "standart", label: "Печать на принтере" },
  { value: "standart1", label: "Сохранить на компьютере" },
  { value: "standart2", label: "Отправить на email" }
];

class OrderPrint extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedOption: options[0],
      active: true,
      errorEmail: false,
      mail: "",
      error: '',
    };
  }

  download = (url, filename) => {
    fetch(url).then(function (t) {
      return t.blob().then(b => {
        var a = document.createElement("a");
        a.href = URL.createObjectURL(b);
        a.setAttribute("download", filename);
        a.click();
      }).catch(er => {
        this.props.hideLoader()
        this.setState({
          active: true
        });
      })
    });
  };

  downoloadPdf = () => {
    if (this.props.orderId && this.props.typeTicketId && this.state.active) {
      this.setState({
        active: false,
        error: false,
      });
      this.props.activeLoader()
      getPdfTickets(this.props.orderId,this.props.ticket_template_type_id, null, this.props.typeTicketId
      ).then(res => {
        this.setState({ urlPdf: res.data.path });
        this.download(res.data.path, "tickets.pdf");
        this.props.orderDisabled();
      }).catch(er => {
        this.props.hideLoader()
        this.setState({
          active: true,
          error: er.response.data.text
        });
      })
    }
  };

  onChangePrint = selectedOption => {
    this.setState({ selectedOption });
  };

  printTickets = () => {
    if (this.props.orderId && this.props.typeTicketId && this.state.active) {
      if (this.props.orderId && this.props.typeTicketId && this.state.active) {
        this.props.activeLoader()
        this.setState({
          active: false,
          error: false,
        });
        getPdfTickets(this.props.orderId,this.props.ticket_template_type_id, null, this.props.typeTicketId).then(
          async res => {
            this.props.orderDisabled();
            await printJS(res.data.path);
          }
        ).catch(er => {
          this.props.hideLoader()
          this.setState({
            active: true,
            error: er.response.data.text
          });
        })
      }
    }
  };

  sentMail = e => {
    e.preventDefault();
    if (
      this.props.orderId &&
      this.props.typeTicketId &&
      this.state.active &&
      this.state.errorEmail
    ) {
      if (this.props.orderId && this.props.typeTicketId && this.state.active) {
        this.props.activeLoader()
        this.setState({
          active: false,
          error: false,
        });
        getPdfTickets(
          this.props.orderId,this.props.ticket_template_type_id, this.state.mail, this.props.typeTicketId
        )
          .then(res => {
            this.props.orderDisabled();
          })
          .catch((er) => {
            this.props.hideLoader()
            this.setState({
              active: true,
              error: er.response.data.text
            });
            //err
          });
      }
    }
  };

  changeMail = e => {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.setState({
      mail: e.target.value,
      errorEmail: re.test(e.target.value)
    });
  };

  errorEmail = () => {
    if (this.state.mail.length === 0) {
      return false;
    }
    if (!this.state.errorEmail) {
      return true;
    }
    return false;
  };

  render() {
    const { selectedOption } = this.state;

    return (
      <>
        <div className="order-print">
          <h2 className="order-print-error">
            {this.state.error}
          </h2>
          <div className="modal-title">Выберите параметр печати</div>
          <div className="form-group">
            <Select
              classNamePrefix="select"
              placeholder="Выберите из списка"
              isSearchable={false}
              options={options}
              defaultValue={options[0]}
              value={selectedOption ? selectedOption : options[0]}
              onChange={this.onChangePrint}
            />
          </div>
          {selectedOption && selectedOption.value === "standart" && (
            <>
              <div className="modal-text">
                <p>
                  Билеты будут напечатаны на принтере, не забудьте положить
                  бланки в лоток принтера
                </p>
              </div>
              <div className="form-button">
                <button onClick={this.printTickets} className="btn">
                  Печать
                </button>
                <button
                  onClick={this.props.orderDisabled}
                  className="btn btn-link">Пропустить</button>
              </div>
            </>
          )}
          {selectedOption && selectedOption.value === "standart1" && (
            <>
              <div className="modal-text">
                <p>
                  Билеты будут сохранены на компьютере в файле формата pdf,
                  согласно выбраному шаблону билета
                </p>
              </div>
              <div className="form-button">
                <button onClick={this.downoloadPdf} className="btn">
                  Сохранить
                </button>
                <button onClick={this.props.orderDisabled}
                  className="btn btn-link">Пропустить</button>
              </div>
            </>
          )}
          {selectedOption && selectedOption.value === "standart2" && (
            <>
              <form>
                <div className="form-group">
                  <input
                    type="email"
                    required
                    value={this.state.mail}
                    onChange={e => {
                      this.changeMail(e);
                    }}
                    placeholder="Ваш email"
                  />
                  {this.errorEmail() ? <div>Введите email</div> : null}
                </div>
                <div className="modal-text">
                  <p>
                    Билеты будут отправлены указанному адресату в файле формата
                    pdf, согласно выбраному шаблону билета
                  </p>
                </div>
                <div className="form-button">
                  <button
                    type="submit"
                    onClick={e => this.sentMail(e)}
                    className="btn"
                  >
                    Отправить
                  </button>
                  <button
                    onClick={this.props.orderDisabled}
                    className="btn btn-link">Пропустить</button>
                </div>
              </form>
            </>
          )}
        </div>
      </>
    );
  }
}

export default OrderPrint;
