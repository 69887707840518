import { PLACES } from '../const';

export function setPlaces(body) {
    return {
      type: PLACES.SET,
      payload: body
    }
  }

export function setSearchInput(body) {
  return {
    type: PLACES.SEARCH_INPUT,
    payload: body
  }  
}