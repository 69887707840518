import React, {Component} from 'react';
import './style.scss';
import './media.scss';
import ReserveTypes from '../ReserveTypes/ReserveTypes';
import ReserveCreateType from '../ReserveCreateType/ReserveCreateType';

const defaultState = {
  isShowReserveTypes: false,
  isShowReserveCreateType: false
};

class Reserve extends Component{
  constructor(props) {
    super(props);

    this.state = {
      ...defaultState,
    }
  }

  componentDidMount() {
    this.showReserveTypes();
  }

  showReserveTypes = () => {
    this.setState({
      ...defaultState,
      isShowReserveTypes: true
    })
  };

  showReserveCreateType = () => {
    this.setState({
      ...defaultState,
      isShowReserveCreateType: true
    })
  };

  render() {
    const {isShowReserveTypes, isShowReserveCreateType} = this.state;

    let modalSize  = '';

    if (isShowReserveCreateType){
      modalSize = 'modal-xs';
    }
    if (false){
      modalSize = 'modal-sm';
    }
    if (isShowReserveTypes){
      modalSize = 'modal-md';
    }

    return (
      <>
        <div className="modal">
          <div className={`modal-box ${modalSize}`}>
            {isShowReserveTypes &&
              <ReserveTypes
                listLocks={this.props.listLocks}
                seatLock={this.props.seatLock}
                changeSeatLock={this.props.changeSeatLock}
                showReserveCreateType={this.showReserveCreateType}
                createReserveSeats={this.props.createReserveSeats}
                loaderModal={this.props.loaderModal}
                error={this.props.error}
              />
            }
            {isShowReserveCreateType &&
              <ReserveCreateType
                showReserveTypes={this.showReserveTypes}
              />
            }
            <div className="modal-close" onClick={this.props.hideReserve}>
              <span className="icon icon-close"></span>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Reserve;
