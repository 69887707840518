import React, { Component } from "react";
import svgPanZoom from "svg-pan-zoom/src/svg-pan-zoom.js";
import axios from "axios";
import Spinner from "../Spinner";
import { parseNameAttribute } from "../../helpers/index";
import LegendScheme from "../../components/LegendScheme";
import ModalGroupBuyTickets from '../ModalGroupBuyTickets';
class Sheme extends Component {
  state = {
    loader: true,
    tickets: [],
    planList: [],
    svg: false,
    disabledTickets: true,
    listIdTickets: [],
    isShowModalOrder: false,
    getZoom: 100,
    loaderTickets: true,
    hoveredSeat: {},
    legendScheme: null,
    legendSchemeHide: true,
    groupElements: null,
    activeModalGroupBuy: false,
    modalGroupBuy: {}
  };

  componentDidMount() {
    let { event } = this.props;
    try {
      this.map(event.schema.path);
    } catch (e) {
      this.props.history.push("/");
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.event !== this.props.event) {
      this.map(this.props.event.schema.path);
    }
  }

  groupMouseOverListener = e => {
    let priceID = parseInt(e.target.parentNode.getAttribute("price_id"));
    let nameId = e.target.getAttribute("name");
    let parseName = parseNameAttribute(this.props.planList, nameId);
    let coast = this.props.event.prices.filter(item => item.id === priceID)

    this.setState({
      legendScheme: { price: coast.length && coast[0].price, name: parseName },
      legendSchemeHide: false
    });
  };

  groupMouseOutListener = e => {
    this.setState({
      legendScheme: null,
      legendSchemeHide: true
    });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.onCenter);

    let groups = document.querySelectorAll("svg g[fill]");
    groups.forEach(elem => {
      elem.removeEventListener("mouseover", this.groupMouseOverListener);
      elem.removeEventListener("mouseout", this.groupMouseOutListener);
    });
  }

  map = url => {
    axios
      .get(`${url}`)
      .then(svg => {
        this.setState({ svg: svg.data });
        if (
          document.querySelector("#svg-map > svg") &&
          document.querySelector("#scopeContainer > svg")
        ) {
          svgPanZoom("#svg-map > svg", {
            zoomEnabled: true,
            controlIconsEnabled: false,
            fit: true,
            center: true,
            maxZoom: 40,
            zoomScaleSensitivity: 0.3,
            minZoom: 0.7,
            dblClickZoomEnabled: false
          });

          svgPanZoom("#scopeContainer > svg", {
            zoomEnabled: true,
            controlIconsEnabled: false,
            fit: true,
            contain: false,
            center: true,
            maxZoom: 35,
            zoomScaleSensitivity: 0.3,
            minZoom: 0.7,
            dblClickZoomEnabled: false
          });
          this.updateMainViewPan();
          var scopeContainer = document.querySelector("#svg-map > svg");
          scopeContainer.addEventListener(
            "mousemove",
            this.updateMainViewPan,
            false
          );
          scopeContainer.addEventListener(
            "wheel",
            this.updateMainViewPan,
            false
          );

          let groups = scopeContainer.querySelectorAll("svg g[fill]");
          groups.forEach((elem) => {
            elem.addEventListener("mouseover", this.groupMouseOverListener);
            elem.addEventListener("mouseout", this.groupMouseOutListener);
          });

          window.addEventListener("resize", this.onCenter);
        }
      })
      .catch(e => {
        //   this.props.history.push("/");
      });
    // this.map();
  };

  updateMainViewPan = evt => {
    var scope = document.querySelector("#scope");
    var line1 = document.querySelector("#line1");
    var line2 = document.querySelector("#line2");
    this._updateThumbScope(window.main, window.thumb, scope, line1, line2);
  };

  onZoom = () => {
    const panZoom = svgPanZoom("#svg-map > svg");
    panZoom.zoomBy(1.3);
    this.updateMainViewPan();
  };

  onCenter = () => {
    const panZoom = svgPanZoom("#svg-map > svg");
    panZoom.resize();
    panZoom.fit();
    panZoom.center();
    this.updateMainViewPan();
  };

  _updateThumbScope = () => {
    const panZoom = svgPanZoom("#svg-map > svg");
    const scopeContainer = svgPanZoom("#scopeContainer > svg");
    this.setState({
      getZoom: Math.floor(panZoom.getZoom() * 100)
    });
    let mainPanX = panZoom.getPan().x,
      mainPanY = panZoom.getPan().y,
      mainWidth = panZoom.getSizes().width,
      mainHeight = panZoom.getSizes().height,
      mainZoom = panZoom.getSizes().realZoom,
      thumbPanX = scopeContainer.getPan().x,
      thumbPanY = scopeContainer.getPan().y,
      thumbZoom = scopeContainer.getSizes().realZoom;

    let thumByMainZoomRatio = thumbZoom / mainZoom;

    let scopeX = thumbPanX - mainPanX * thumByMainZoomRatio;
    let scopeY = thumbPanY - mainPanY * thumByMainZoomRatio;
    let scopeWidth = mainWidth * thumByMainZoomRatio;
    let scopeHeight = mainHeight * thumByMainZoomRatio;
    var scope = document.getElementById("scope");
    scope.setAttribute("x", scopeX + 1);
    scope.setAttribute("y", scopeY + 1);
    scope.setAttribute("width", scopeWidth - 2);
    scope.setAttribute("height", scopeHeight - 2);
  };

  outZoom = () => {
    const panZoom = svgPanZoom("#svg-map > svg");
    panZoom.zoomOut(1.3);
    this.updateMainViewPan();
  };

  _buyTicket = (e, activeBuy) => {
    this.setState({
      activeModalGroupBuy: false,
    })
    if(e.target.tagName === "rect" && e.target.id) {
      let priceID = parseInt(e.target.parentNode.getAttribute("price_id"));
      let coast = this.props.prices.filter(item => item.id === priceID)[0]?.price
      this.setState({
        activeModalGroupBuy: true,
        modalGroupBuy: {
          coast,
          maxCount: e.target.getAttribute('gsc_fl'),
          place: {
            target: e.target,
          }
        }
      })
    } else {
      this.props.buyTicket(e, activeBuy);
    }
  }

  buyGroupTicket = (count) => {
    this.props.buyTicket(this.state.modalGroupBuy.place, false);
    if(count <= 1) {
      this._closeModalGroup();
      return true;
    } else {
      this.buyGroupTicket(count - 1);
    }
  }

  _closeModalGroup = () => {
    this.setState({
      activeModalGroupBuy: false,
      modalGroupBuy: {}
    })
  }

  render() {
    const { getZoom, legendScheme, legendSchemeHide } = this.state;
    return (
      <>
        <LegendScheme legendSchemeHide={legendSchemeHide} legendScheme={legendScheme} />
        <ModalGroupBuyTickets 
          active={this.state.activeModalGroupBuy}
          buyTickets={(count) => {this.buyGroupTicket(count)}}
          disabledModal={this._closeModalGroup}
          modalGroupBuy={this.state.modalGroupBuy}
        />
        <div className="event">
          {!this.state.svg ? (
            <Spinner />
          ) : (
            <div
              id="svg-map"
              className="event-map"
              onClick={e => this._buyTicket(e, false)}
              // onClick={e => this.props.buyTicket(e)}
              onMouseMove={e => {
                if (e.target) {
                  if (e.shiftKey) {
                    this.props.buyTicket(e, true);
                  }
                }
              }}
              dangerouslySetInnerHTML={{ __html: this.state.svg }}
            />
          )}
          <div className="event-map__sidebar">
            {!this.state.svg ? null : (
              <>
                <div className="event-map__sidebar-plan">
                  <div className="event-map__sidebar-plan-control">
                    <div
                      className="event-map__sidebar-plan-zoomin"
                      onClick={this.onZoom}
                    >
                      <span className="icon icon-plus"></span>
                    </div>
                    <div
                      className="event-map__sidebar-plan-refresh"
                      onClick={this.onCenter}
                    >
                      <span className="event-map__sidebar-plan-percent">
                        {getZoom}%
                      </span>
                      <span className="icon icon-refresh"></span>
                    </div>
                    <div
                      className="event-map__sidebar-plan-zoomout"
                      onClick={this.outZoom}
                    >
                      <span className="icon icon-minus"></span>
                    </div>
                  </div>
                  <div
                    id="thumbViewContainer"
                    className="event-map__sidebar-plan-view"
                  >
                    <div
                      id="scopeContainer"
                      dangerouslySetInnerHTML={{ __html: this.state.svg }}
                    />
                    <svg className="scope-border">
                      <g>
                        <rect
                          id="scope"
                          fill="red"
                          fillOpacity="0.1"
                          stroke="red"
                          strokeWidth="2px"
                          x="0"
                          y="0"
                          width="0"
                          height="0"
                        />
                        <line
                          id="line1"
                          stroke="red"
                          strokeWidth="2px"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="0"
                        ></line>
                        <line
                          id="line2"
                          stroke="red"
                          strokeWidth="2px"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="0"
                        ></line>
                      </g>
                    </svg>
                  </div>
                </div>
                {this.props.planTickets !== false
                  ? this.props.planTickets
                  : null}
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default Sheme;
