import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { getJSONReportEvent, getJSONReportDynamic, getJSONReportSeats, getJSONReportGeo, getJSONReportCategory } from '../../../api/report';
import { connect } from "react-redux";
import { parseNameAttribute } from "../../../helpers/index";

class ReportData extends Component {

  state = {
    error: false,
    preloader: false,
    event: {
      datetime: "",
      row_count: 1,
      row_list: [
        //   {
        //     "currency": 933,
        //     "data_source": "Тикетпро: продажи",
        //     "price_name": "Основная",
        //     "promoter_commission_amount": "80,00",
        //     "ticket_count": 10,
        //     "total_ticket_amount": 800
        //   }
      ]
    }
  }

  componentDidMount() {
    this.setState({
      preloader: true
    })
    if (this.props.match.params?.event === 'event') {
      getJSONReportEvent(
        JSON.parse('{"' + decodeURI(this.props.match.params.id).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
      ).then(res => {
        console.log(res)
        this.setState({
          event: res.data,
          preloader: false,
        })
      })
        .catch(er => {
          this.setState({
            error: true,
          })
        })
    } else if (this.props.match.params?.event === 'seats') {
      getJSONReportSeats(
        JSON.parse('{"' + decodeURI(this.props.match.params.id).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
      ).then(res => {
        console.log(res)
        this.setState({
          event: res.data,
          preloader: false,
        })
      })
        .catch(er => {
          this.setState({
            error: true,
          })
        })
    } else if (this.props.match.params?.event === 'geo') {
      getJSONReportGeo(
        JSON.parse('{"' + decodeURI(this.props.match.params.id).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
      ).then(res => {
        console.log(res)
        this.setState({
          event: res.data,
          preloader: false,
        })
      })
        .catch(er => {
          this.setState({
            error: true,
          })
        })
    } else if (this.props.match.params?.event === 'dynamic') {
      getJSONReportDynamic(
        JSON.parse('{"' + decodeURI(this.props.match.params.id).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
      ).then(res => {
        console.log(res)
        this.setState({
          event: res.data,
          preloader: false,
        })
      })
        .catch(er => {
          this.setState({
            error: true,
          })
        })
    } else if (this.props.match.params?.event === 'categories') {
      getJSONReportCategory(
        JSON.parse('{"' + decodeURI(this.props.match.params.id).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
      ).then(res => {
        console.log(res)
        this.setState({
          event: res.data,
          preloader: false,
        })
      })
        .catch(er => {
          this.setState({
            error: true,
          })
        })
    }
  }

  render() {

    if (this.state.error) {
      return (
        <main className="main">
          <div className="container">
            Ошибка!
          </div>
        </main>
      )
    }

    if (this.props.match.params?.event === 'event') {
      return (
        <main className="main">
          <div className="container">
            <section className="reports">
              <div className="reports-list">
                <div className="reports-table">
                  <div className="reports-table__thead">
                    <div className="reports-table__row">
                      <div className="reports-table__col report-check"></div>
                      <div className="reports-table__col report-date">Количество<span className="icon icon-"></span></div>
                      <div className="reports-table__col report-place">Источник<span className="icon icon-"></span></div>
                      <div className="reports-table__col report-place">Ценовая политика<span className="icon icon-"></span></div>
                      <div className="reports-table__col report-city">Комисия промоутера<span className="icon icon-"></span></div>
                      <div className="reports-table__col report-status">Кол-во билетов<span className="icon icon-"></span></div>
                      <div className="reports-table__col report-status">Общая сумма билетов<span className="icon icon-"></span></div>
                    </div>
                  </div>
                  <div className="reports-table__tbody">
                    {
                      this.state.event.row_list.map(item => {
                        return (
                          <div className="reports-table__row">
                            <div className="reports-table__col report-check">
                              <div className="checkbox">
                              </div>
                            </div>
                            <div className="reports-table__col report-date">{item.currency}</div>
                            <div className="reports-table__col report-place">{item.data_source}</div>
                            <div className="reports-table__col report-place">{item.price_name}</div>
                            <div className="reports-table__col report-city">{item.promoter_commission_amount}</div>
                            <div className="reports-table__col report-status">{item.ticket_count}</div>
                            <div className="reports-table__col report-status">{item.total_ticket_amount}</div>

                          </div>
                        )
                      })
                    }
                    {
                      this.state.preloader && (
                        <div className="loading">
                          <div className="loading-box">
                            <span className="icon icon-spinner"></span>
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>

            </section>
          </div>
        </main>
      )
    } else if (this.props.match.params?.event === 'geo') {
      return (
        <main className="main">
          <div className="container">
            <section className="reports">
              <div className="reports-list">
                <div className="reports-table">
                  <div className="reports-table__thead">
                    <div className="reports-table__row">
                      <div className="reports-table__col report-check"></div>
                      <div className="reports-table__col report-date">Город<span className="icon icon-"></span></div>
                      <div className="reports-table__col report-place">Страна<span className="icon icon-"></span></div>
                      <div className="reports-table__col report-place">Регион<span className="icon icon-"></span></div>
                      <div className="reports-table__col report-city">Количество билетов<span className="icon icon-"></span></div>
                    </div>
                  </div>
                  <div className="reports-table__tbody">
                    {
                      this.state.event.row_list.map(item => {
                        return (
                          <div className="reports-table__row">
                            <div className="reports-table__col report-check">
                              <div className="checkbox">
                              </div>
                            </div>
                            <div className="reports-table__col report-date">{item.city_name}</div>
                            <div className="reports-table__col report-place">{item.country_name}</div>
                            <div className="reports-table__col report-place">{item.region_name}</div>
                            <div className="reports-table__col report-city">{item.ticket_count}</div>

                          </div>
                        )
                      })
                    }
                    {
                      this.state.preloader && (
                        <div className="loading">
                          <div className="loading-box">
                            <span className="icon icon-spinner"></span>
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>

            </section>
          </div>
        </main>)
    } else if (this.props.match.params?.event === 'categories') {
      return (
        <main className="main">
          <div className="container">
            <section className="reports">
              <div className="reports-list">
                <div className="reports-table">
                  <div className="reports-table__thead">
                    <div className="reports-table__row">
                      <div className="reports-table__col report-check"></div>
                      <div className="reports-table__col report-date">Валюта<span className="icon icon-"></span></div>
                      <div className="reports-table__col report-place">Дата<span className="icon icon-"></span></div>
                      <div className="reports-table__col report-place">Ценовая политика<span className="icon icon-"></span></div>
                      <div className="reports-table__col report-city">Комисия промоутера<span className="icon icon-"></span></div>
                      <div className="reports-table__col report-place">Количество билетов<span className="icon icon-"></span></div>
                      <div className="reports-table__col report-place">текущие Количество<span className="icon icon-"></span></div>
                      <div className="reports-table__col report-place">Площадка<span className="icon icon-"></span></div>


                    </div>
                  </div>
                  <div className="reports-table__tbody">
                    {
                      this.state.event.row_list.map(item => {
                        return (
                          <div className="reports-table__row">
                            <div className="reports-table__col report-check">
                              <div className="checkbox">
                              </div>
                            </div>
                            <div className="reports-table__col report-date">{item.currency}</div>
                            <div className="reports-table__col report-place">{item.event_date}</div>
                            <div className="reports-table__col report-place">{item.price_name}</div>
                            <div className="reports-table__col report-city">{item.promoter_commission_amount}</div>

                            <div className="reports-table__col report-place">{item.ticket_count}</div>
                            <div className="reports-table__col report-place">{item.total_ticket_amount}</div>
                            <div className="reports-table__col report-place">{item.venue}</div>

                          </div>
                        )
                      })
                    }
                    {
                      this.state.preloader && (
                        <div className="loading">
                          <div className="loading-box">
                            <span className="icon icon-spinner"></span>
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>

            </section>
          </div>
        </main>)

    } else if (this.props.match.params?.event === 'dynamic') {
      return (
        <main className="main">
          <div className="container">
            <section className="reports">
              <div className="reports-list">
                <div className="reports-table">
                  <div className="reports-table__thead">
                    <div className="reports-table__row">
                      <div className="reports-table__col report-check"></div>
                      <div className="reports-table__col report-place">Валюта<span className="icon icon-"></span></div>
                      <div className="reports-table__col report-place">Начало<span className="icon icon-"></span></div>
                      <div className="reports-table__col report-place">Конец<span className="icon icon-"></span></div>
                      <div className="reports-table__col report-place">Количество операций<span className="icon icon-"></span></div>
                      <div className="reports-table__col report-place">Общая сумма<span className="icon icon-"></span></div>
                      <div className="reports-table__col report-place">Продано билетов<span className="icon icon-"></span></div>
                      <div className="reports-table__col report-place">Продано на сумму<span className="icon icon-"></span></div>
                      <div className="reports-table__col report-place">Возращено билетов<span className="icon icon-"></span></div>
                      <div className="reports-table__col report-place">Возращенная сумма<span className="icon icon-"></span></div>


                    </div>
                  </div>
                  <div className="reports-table__tbody">
                    {
                      this.state.event.row_list.map(item => {
                        return (
                          <div className="reports-table__row">
                            <div className="reports-table__col report-check">
                              <div className="checkbox">
                              </div>
                            </div>
                            <div className="reports-table__col report-place">{item.currency}</div>
                            <div className="reports-table__col report-place">{item.period_start_date}</div>
                            <div className="reports-table__col report-place">{item.period_end_date}</div>
                            <div className="reports-table__col report-place">{item.operation_count}</div>
                            <div className="reports-table__col report-place">{item.total_amount}</div>
                            <div className="reports-table__col report-place">{item.total_saled_ticket_count}</div>
                            <div className="reports-table__col report-place">{item.total_saled_amount}</div>
                            <div className="reports-table__col report-place">{item.total_refund_ticket_count}</div>
                            <div className="reports-table__col report-place">{item.total_refund_amount}</div>

                          </div>
                        )
                      })
                    }
                    {
                      this.state.preloader && (
                        <div className="loading">
                          <div className="loading-box">
                            <span className="icon icon-spinner"></span>
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>

            </section>
          </div>
        </main>)
    } else if (this.props.match.params?.event === 'seats') {
      return (
        <main className="main">
          <div className="container">
            <section className="reports">
              <div className="reports-list">
                <div className="reports-table">
                  <div className="reports-table__thead">
                    <div className="reports-table__row">
                      <div className="reports-table__col report-check"></div>
                      <div className="reports-table__col report-place">Статус<span className="icon icon-"></span></div>
                      <div className="reports-table__col report-place">Место<span className="icon icon-"></span></div>
                      <div className="reports-table__col report-place">Количество<span className="icon icon-"></span></div>


                    </div>
                  </div>
                  <div className="reports-table__tbody">
                    {
                      this.state.event.row_list.map(item => {
                        return (
                          <div className="reports-table__row">
                            <div className="reports-table__col report-check">
                              <div className="checkbox">
                              </div>
                            </div>
                            <div className="reports-table__col report-place">{item.status}</div>
                            <div className="reports-table__col report-place">{item.address}
                              {parseNameAttribute(this.props.planList, item.ticket_name)?.join(' ')}
                            </div>
                            <div className="reports-table__col report-place">{item.count}</div>

                          </div>
                        )
                      })
                    }
                    {
                      this.state.preloader && (
                        <div className="loading">
                          <div className="loading-box">
                            <span className="icon icon-spinner"></span>
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>

            </section>
          </div>
        </main>)

    } else {
      return null
    }
  }
}

const makeMapStateToProps = () => {
  const mapStateToProps = state => {
    return {
      planList: state.seatScheme.list
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = {};

export default withRouter(connect(makeMapStateToProps, mapDispatchToProps)(ReportData));