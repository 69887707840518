import React, { Component } from "react";
import "./style.scss";
import "./media.scss";
import EventBox from "../../components/EventBox/EventBox";
import EventPanelStatistics from "../EventPanelStatistics/EventPanelStatistics";
import EventMenu from "../../components/EventMenu/EventMenu";
import EventPlanTickets from "../../components/EventPlanTickets/EventPlanTickets";
import { getCancelablePromise } from "../../helpers/index";
import { getSvg } from "../../api/events";
import { getBasket, deleteAllBasket } from "../../api/basket";
import moment from "moment";
import svgPanZoom from "svg-pan-zoom/src/svg-pan-zoom.js";
import _ from "lodash";
import Order from "../../components/Order/Order";
import { Link } from "react-router-dom";
import { TicketDelete } from "../../api/ticket";
import { parseNameAttribute } from "../../helpers/index";
import Spinner from "../../components/Spinner";
import Scheme from "../../components/Sheme";
import { setTotalEvent } from "../../store/actions/event";
import { connect } from "react-redux";
import { getSellerSvg } from "../../api/scheme";
import { formatedData } from '../../helpers/index';

class EventPlan extends Component {
  state = {
    event: [],
    loader: true,
    tickets: [],
    svg: false,
    disabledTickets: true,
    listIdTickets: [],
    isShowModalOrder: false,
    getZoom: 100,
    loaderTickets: true,
  };

  componentDidMount() {
    deleteAllBasket()
    this.loadedEvent();
  }

  clearTickets = (clear = false) => {
    if(clear) {
    this.state.listIdTickets.map(item => {
      this.deleteTicket(item.id)
    })
  }
    this.setState({
      listIdTickets: []
    });
  };

  loadedEvent = () => {
    deleteAllBasket()
    let { match, totalEvent, idEvent } = this.props;
    if (totalEvent.event && Number(match.params.eventID) === Number(idEvent)) {
      this.setState({
        event: totalEvent
      });
      getSellerSvg(match.params.eventID).then(res => {
        if (res && res.data) {
          totalEvent.schema.path = res.data.path;
          this.setState({
            event: totalEvent,
            loader: false
          });
        }
      });
    } else {
      this.props.setTotalEvent(match.params.eventID).then(res => {
        if (!res) {
        } else {
          this.setState({
            event: res,
            loader: false
          });
        }
      });
    }
    this.getBasket()
  };

  getBasket = () => {
    const basket = getCancelablePromise(getBasket());
    Promise.all([basket.promise])
      .then(data => {
        let tickets = []
        data[0].basket_entities.map(item => {
          tickets.push({
            info: item,
            fill: "#FFFFFF",
            id: item.ticket_id,
            name: item.ticket_name,
            price: item.price,
            priceID: item.price_id,
            TicketReserved: true,
            basketID: item.id,
          })
        })
        this.setState({
          listIdTickets: tickets,
          loaderTickets: false
        });
      });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onCenter);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      isShowModalOrder
    } = this.state;
    if (isShowModalOrder === false && isShowModalOrder !== prevState.isShowModalOrder) {
      this.getBasket()
      // this.setState({
      //   loader: true,
      // })
      // this.updateMap()
    }
  }

  updateMap = () => {
    let { match } = this.props;
    const newSvg = getCancelablePromise(getSvg(match.params.eventID));
    Promise.all([newSvg.promise])
      .then(data => {
        if (data[0].path) {
          let b = this.state.event;
          b.schema.path = data[0].path
          this.setState({
            loader: false,
            event: {
              schema: "test",
              ...this.state.event
            }
          })
        }
      });
  };


  buyTicket = (e, onlyBuy = false) => {
    try {
    let id = Number(e.target.id);
    const { listIdTickets } = this.state;
    let list = listIdTickets;
    let priceID = parseInt(e.target.parentNode.getAttribute("price_id"));
    let fill = e.target.parentNode.getAttribute("fill");
    let nameTicket = e.target.getAttribute("name");
    console.log(id);
    console.log(nameTicket)
    if (e.target.id && id && fill !== "#999999" && priceID) {
      if (!_.some(listIdTickets, { id: id }) || e.target.tagName === "rect") {
        e.target.setAttribute("fill", "black");
        let coast = this.state.event.prices.filter(item => item.id === priceID)
        list.push({
          id: id,
          name: nameTicket,
          price: coast.length && coast[0].price,
          priceID: priceID,
          fill: fill
        });
        this.setState({
          listIdTickets: list
        });
      } else {
        // delete ticket
        if(!onlyBuy) {
          list = list.filter(idList => idList.id !== id);
          e.target.removeAttribute("fill");
          this.setState({
            listIdTickets: list
          });
        }
      }
    }
  } catch(er) {
    console.log(er)
  }
  };

  deleteTicket = (id, basketID = false) => {
    try {
      const { listIdTickets } = this.state;
      const filterId = id;
      let list = listIdTickets;

      if (basketID) {
        TicketDelete(basketID)
          .then(res => {
            let tickets = []
            res.basket_entities.map(item => {
              tickets.push({
                info: item,
                fill: "#FFFFFF",
                id: item.ticket_id,
                name: item.ticket_name,
                price: item.price,
                priceID: item.price_id,
                TicketReserved: true,
                basketID: item.id,
              })
            })
            this.setState({
              listIdTickets: tickets,
              loaderTickets: false
            });
          })
      }

      document.querySelectorAll(`[id="${id}"]`).forEach(el => {
        let elemSelector = el.outerHTML.match(/^<(circle|rect)/)[1];
        if (elemSelector === "rect") {
          let arrayRect = _.filter(listIdTickets, { id: id });
          let newArray = arrayRect.slice(0, arrayRect.length - 1);
          let arrayCircle = _.filter(listIdTickets, function (o) {
            return o.id !== id;
          });
          this.setState({ listIdTickets: arrayCircle.concat(newArray) }, () => {
            if (!_.filter(this.state.listIdTickets, { id: filterId }).length) {
              el.removeAttribute("fill");
            }
          });
        }
        if (elemSelector === "circle") {
          list = list.filter(itemID => itemID.id !== id);
          this.setState({ listIdTickets: list });

          el.removeAttribute("fill");
        }
      });
    } catch (er) {
      console.log(er)
    }
  };

  showModalOrder = () => {
    this.setState({
      isShowModalOrder: true
    });
  };

  hideModalOrder = () => {
    this.setState({
      isShowModalOrder: false
    });
  };

  render() {
    const { isShowModalOrder } = this.state;
    const { event } = this.state;

    return (
      <>
        <main className="main">
          <div className="event-panel">
            <div className="event-panel__head">
              <div className="event-panel__head-left">
                <Link to="/">
                  <button className="event-back">
                    <span className="icon icon-arr-left"></span>
                  </button>
                </Link>
                {event.event ? (
                  <EventBox
                    date={formatedData(event.event.date_time_from)}
                    time={moment(event.event.date_time_from).format("H:mm")}
                    name={event.event.descriptions[0].name}
                    location={event.venue.descriptions[0].name}
                  />
                ) : (
                  <Spinner />
                )}
              </div>
              <div className="event-panel__head-right">
                <EventPanelStatistics />
              </div>
            </div>
            <EventMenu
              history={this.props.history}
              currentMenu={1}
              match={this.props.match}
            />
          </div>
          {this.state.loader === false && this.state.event ? (
            <Scheme
              prices={this.state.event.prices}
              match={this.props.match}
              event={this.state.event}
              buyTicket={this.buyTicket}
              history={this.props.history}
              planList={this.props.planList}
              planTickets={
                <EventPlanTickets
                  clearTickets={() => this.clearTickets(true)}
                  tickets={this.state.tickets}
                  loaderTickets={this.state.loaderTickets}
                  planList={this.props.planList}
                  deleteTicket={this.deleteTicket}
                  listIdTickets={this.state.listIdTickets}
                  showOrder={() => {
                    this.showModalOrder();
                  }}
                />
              }
            />
          ) : (
            <Spinner />
          )}
        </main>
        {isShowModalOrder && (
          <Order
            hideOrder={() => this.hideModalOrder()}
            clearTickets={this.clearTickets}
            planList={this.props.planList}
            eventID={this.props.match.params.eventID}
            location={this.props.location}
            listIdTickets={this.state.listIdTickets}
            getBasket={this.getBasket}
            eventDate={formatedData(event.event.date_time_from)}
            eventTime={moment(event.event.date_time_from).format("H:mm")}
            eventName={event.event.descriptions[0].name}
            eventLocation={event.venue.descriptions[0].name}
            event={this.state.event}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = state => ({
  totalEvent: state.event.totalEvent,
  idEvent: state.event.totalEventId,
  planList: state.seatScheme.list
});

const mapDispatchToProps = {
  setTotalEvent
};

export default connect(mapStateToProps, mapDispatchToProps)(EventPlan);
