import store from '../store/store';
import serviceApi from './service';

export const getBasket = () => {
  let path = `/ticket-wp-api/v1/get-basket/`
  return serviceApi(path)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error
    }
    )
}

export const deleteAllBasket = () => {
  return serviceApi(`/ticket-wp-api/v1/basket-delete-all/`)
}