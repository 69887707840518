import { REPORT } from '../const';
import { getEventsList } from '../../api/events';
import { getCancelablePromise } from '../../helpers/index';


export const clearEvents = () => dispatch => {
    dispatch({
        type: REPORT.CLEAR_EVENTS,
    });
}

export const setAsyncEvent = (objectData) => dispatch => {
    dispatch({
        type: REPORT.PRELOADER_EVENTS,
        payload: true,
    });
    let eventsinfo = getCancelablePromise(getEventsList(objectData));
    return Promise.all([eventsinfo.promise])
        .then((data) => {
            dispatch({
                type: REPORT.ADD_EVENTS,
                events: data[0],
                count: Math.ceil(data[0].all_count / 15),
                currentPage: +objectData.max / 15,
                filter: objectData,
            });
            dispatch({
                type: REPORT.PRELOADER_EVENTS,
                payload: false
            });
            return data[0]
        }).catch((err) => {
            dispatch({
                type: REPORT.PRELOADER_EVENTS,
                payload: false
            });
            return err
        })
}