import { PLACES } from '../const';

const initialState = {
    list: [],
    search: "",
};

export default function places(state = initialState, action) {

    switch (action.type) {
        case PLACES.SET:
            return Object.assign({}, state, { list: action.payload });
        case PLACES.SEARCH_INPUT:
            return Object.assign({}, state, { search: action.payload });
        default:
            return state;
    }
}