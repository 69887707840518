import React, { Component } from 'react'
import { sortEventsBy } from '../../store/actions/event'
import { connect } from 'react-redux'
class HeaderEventsList extends Component {
    state = {
        type: false,
        status: false
    }
    sortByType = (type) => {
        if (!this.props.preloader) {
            if (type === this.state.type) {
                if ('desc' === this.state.status) {
                    this.setState({
                        status: 'asc'
                    }, () => {
                        this.props.sortEventsBy(this.state)
                    })
                }
                // else if ('asc' === this.state.status) {
                //     this.setState({
                //         status: false
                //     }, () => {
                //         this.props.sortEventsBy(this.state)
                //     })
                // }
                else {
                    this.setState({
                        status: 'desc'
                    }, () => {
                        this.props.sortEventsBy(this.state)
                    })
                }
            } else {
                this.setState({
                    type: type,
                    status: 'desc'
                }, () => {
                    this.props.sortEventsBy(this.state)
                })
            }
        }
    }

    render() {
        return (
            <div className="events-table__thead">
                <div className="events-table__row">
                    <div onClick={() => this.sortByType('date_time_from')} className="events-table__col event-date">Дата/Время<span className="icon icon-sort"></span></div>
                    <div onClick={() => this.sortByType('event')} className="events-table__col event-name">Мероприятие<span className="icon icon-sort"></span></div>
                    <div onClick={() => this.sortByType('place_id')} className="events-table__col event-place">Место проведения<span className="icon icon-sort"></span></div>
                    <div onClick={() => this.sortByType('city')} className="events-table__col event-city">Город<span className="icon icon-sort"></span></div>
                    <div onClick={() => this.sortByType('status')} className="events-table__col event-status">Статус<span className="icon icon-sort"></span></div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    preloader: state.event.preloader
});

const mapDispatchToProps = {
    sortEventsBy,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderEventsList);
