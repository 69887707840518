import React, { Component } from "react";
import "./style.scss";
import "./media.scss";
import Select from "react-select";

class LayoutTicket extends Component {
  render() {
    console.log(this.props.typesTemplsList)
    return (
      <>
        <div className="layout-ticket">
          <div className="layout-ticket__title">Макет билета</div>
          <div className="layout-ticket__field">
            {this.props.typesTemplsList && this.props.typesTemplsList.length ? (
              <Select
                classNamePrefix="select"
                placeholder="Выберите из списка"
                isSearchable={false}
                options={this.props.typesTemplsList}
                defaultValue={this.props.typesTemplsList[0]}
                onChange={e => {
                  console.log(e)
                  this.props.changeTempls(e)
                }}
              />
            ) : null}
          </div>
        </div>
      </>
    );
  }
}

export default LayoutTicket;
