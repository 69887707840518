import React, { Component } from 'react';
import ReportsFilter from '../../../components/ReportsFilter/ReportsFilter';
import ReportsList from '../../../components/ReportsList/ReportsList';

class Reports extends Component {
    render() {
        return (
            <>
                <main className="main">
                    <div className="container">
                        <section className="reports">
                            <ReportsFilter
                                selectDate={false}
                                // selectCategories={true}
                                eventsShow={true}
                                typeSearch="seats"
                                search={(event, date_from, date_to) => `event_id=${event}`}
                            />
                        </section>
                    </div>
                </main>
            </>
        )
    }
}

export default Reports;
