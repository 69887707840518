import React, {Component} from 'react';
import './style.scss';
import './media.scss';
import Pagination from '../Pagination/Pagination';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import {
  setAsyncEvent,
} from '../../store/actions/report';
import { withRouter } from 'react-router-dom';
import { getEventsList } from '../../api/events';

class ReportsList extends Component {

  componentDidMount() {
    // getEventsList();
  }

  formatedData = (date) => {
    if (date) {
      return (date.substring(8, 10) + ' ' + moment(date).locale('ru').format('MMMM').substring(0, 3) + ', ' + moment(date).format('H:mm'));
    } else {
      return null;
    }
  }

  getPlaces = (id) => {
    const { placesList } = this.props;
    if (placesList.length && id) {
      let place = ((_.find(placesList, { id: id })));
      if (place) {
        return place.descriptions[0].name;
      }
    }
    return "";
  }

  getCities = (id) => {
    const { placesList, citiesList } = this.props;
    if (placesList.length && id && citiesList.length) {
      let id_places = (_.find(placesList, { id: id })).city_id;
      let city = (_.find(citiesList, { id: id_places }));
      if (city) {
        return city.descriptions[0].name;
      }
    }
    return "";
  }

  _onPressPage = (min, max) => {
    this.props.setAsyncEvent({
      ...this.props.filter,
      min: min,
      max: max,
    });
  }

  render() {
    return (
      <>
        <div className="reports-list">
          <div className="reports-table">
            <div className="reports-table__thead">
              <div className="reports-table__row">
                <div className="reports-table__col report-check"></div>
                <div className="reports-table__col report-name">Категория<span className="icon icon- sort"></span></div>
              </div>
            </div>
            <div className="reports-table__tbody">
              {
                this.props.eventsList[0]?.promoter_id && this.props.categoriesList.map(item => {
                  return (
                    <div  className="reports-table__row">
                    <div className="reports-table__col report-check">
                      {/* <div className="checkbox">
                        <input id="report-1" type="checkbox" name="report" />
                        <label htmlFor="report-1"></label>
                      </div> */}
                    </div>
                    <div className="reports-table__col report-name">{item.descriptions[0].name}</div>
                    <span onClick={
                      () => {
                        this.props.history.push(`/report-data/${this.props.typeSearch}/promoter_id=${
                          this.props.eventsList[0]?.promoter_id || null
                        }&${this.props.search(item.id, this.props.dateFrom, this.props.dateTo)}`)
                      }
                    } className="btn">Выбрать</span>
                  </div>
                  )
                })
              }
            </div>
          </div>
          <div className="reports-foot">
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
  placesList: state.places.list,
  citiesList: state.cities.list,
  maxPages: state.report.searchListEvents.count,
  currentPage:  state.report.searchListEvents.currentPage,
  filter: state.report.searchListEvents.filter,

  eventsList: state.report.searchListEvents.list?.events || [],
  categoriesList: state.categories.list
}}

const mapDispatchToProps = {
  setAsyncEvent
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportsList));
