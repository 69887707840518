import serviceApi from './service';

export const orderCreateCashless = (data) => {
    return serviceApi(`/ticket-wp-api/v1/order-create-cashless/`, data);
}

export const orderDelete = (data) => {
    return serviceApi(`/ticket-wp-api/v1/order-delete/`, data);
}

export const payOrderTwoSum = (order_id, { pay_sum_1, ident_type_1, pay_sum_2, ident_type_2 }) => {
    return serviceApi(`/ticket-wp-api/v1/promoter-order-twosum-pay/`, {
        order_id: `${order_id}`,
        ident_type_1: ident_type_1,
        pay_sum_1: pay_sum_1,
        ident_type_2: ident_type_2,
        pay_sum_2: pay_sum_2,
        is_cash_active: false,
    }
    )
}

export const setOrderAnalog = (data) => {
    return serviceApi(`/ticket-wp-api/v1/analog-use/`, data);
}

export const orderSuccess = (data) => {
    return serviceApi(`/ticket-wp-api/v1/promoter-mnf-order-confirm/`, data)
}

export const ordersList = (data) => {
    return serviceApi(`/ticket-wp-api/v1/get-orders-list/`, data)
}

export const getOrder = (data) => {
    return serviceApi(`/ticket-wp-api/v1/get-order/`, data)
}
export const deletePrintOrderId = (data) => {
    return serviceApi(`/ticket-wp-api/v1/order-cancel-printing/`, data)
}

export const deletePrintOrderTickets = (data) => {
    return serviceApi(`/ticket-wp-api/v1/order-entity-cancel-printing/`, data)
}
export const printOrder = (data) => {
    return serviceApi(`/ticket-wp-api/v1/order-get-ticket/`, data)
}

export const cancelOrderMnf = (data) => {
    return serviceApi(`/ticket-wp-api/v1/promoter-mnf-order-cancel/`, data)
}

export const cancelOrderCancel = (data) => {
    return serviceApi(`/ticket-wp-api/v1/promoter-order-cancel/`, data)
}

export const cancelOrderEntity = (data) => {
    return serviceApi(`/ticket-wp-api/v1/promoter-order-entity-return/`, data)
}

export const cancelOrderMnfEntity = (data) => {
    return serviceApi(`/ticket-wp-api/v1/promoter-mnf-order-entity-return/`, data)
}

// /promoter-order-cancel

export const setSuccessOrderCashless = (data) => {
    return serviceApi(`/ticket-wp-api/v1/set-order-payed-cashless/`, data)
}

export const changeTicketsTemplate = (data) => {
    return serviceApi(`/ticket-wp-api/v1/ticket-set-template/`, data)
}