import React, { Component } from 'react'
import { connect } from "react-redux";
import { sortEventsBy } from '../../store/actions/orders'

class EventOrdersListHeader extends Component {
    state = {
        type: false,
        status: false
    }
    sortByType = (type) => {
        if (!this.props.preloader) {
            if (type === this.state.type) {
                if ('desc' === this.state.status) {
                    this.setState({
                        status: 'asc'
                    }, () => {
                        this.props.sortEventsBy(this.state)
                    })
                }
                else {
                    this.setState({
                        status: 'desc'
                    }, () => {
                        this.props.sortEventsBy(this.state)
                    })
                }
            } else {
                this.setState({
                    type: type,
                    status: 'desc'
                }, () => {
                    this.props.sortEventsBy(this.state)
                })
            }
        }
    }
    render() {
        return (
            <div className="event-orders-table__thead">
                <div className="event-orders-table__row">
                    <div onClick={() => this.sortByType('id')} className="event-orders-table__col event-orders-number">№<span className="icon icon-sort"></span></div>
                    <div onClick={() => this.sortByType('status')} className="event-orders-table__col event-orders-status">Статус<span className="icon icon-sort"></span></div>
                    <div onClick={() => this.sortByType('date')} className="event-orders-table__col event-orders-date">Дата, время<span className="icon icon-sort"></span></div>
                    <div onClick={() => this.sortByType('date_change')} className="event-orders-table__col event-orders-return">Возврат<span className="icon icon-sort"></span></div>
                    <div onClick={() => this.sortByType('ticket')} className="event-orders-table__col event-orders-tickets">Билеты<span className="icon icon-sort"></span></div>
                    <div onClick={() => this.sortByType('pay')} className="event-orders-table__col event-orders-payment">Оплата<span className="icon icon-sort"></span></div>
                    <div onClick={() => this.sortByType('ticket_status')} className="event-orders-table__col event-orders-print">
                        Печать<span className="icon icon-sort"></span></div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    orders: state.orders.orders,
    preloader: state.orders.preloader,
  });
  
  const mapDispatchToProps = {
    // getListOrders,
    // setDefaultSearch,
    sortEventsBy,
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(EventOrdersListHeader);
  