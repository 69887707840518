import { EVENT } from '../const';
import { getEventsList } from '../../api/events';
import { getCancelablePromise } from '../../helpers/index';
import { error } from '../../containers/const';
import { svgTotalEvent } from '../../api/events';
import moment from "moment";

export const setTotalEventData = (data, id) => async dispatch => {
    dispatch({
      type: EVENT.SET_CURRENT_EVENT,
      event: data,
      id: id,
    });
}

export const setTotalEvent = (id) => async dispatch => {
  return svgTotalEvent(id).then(res => {
    if (res.data) {
      dispatch({
        type: EVENT.CURRENT_EVENT,
        event: res.data,
        id: id,
      });
      return res.data;
    } else {
      return false
    }
  }).catch(er => {
    return false;
  })
}

export function setEvents(body) {
  return {
    type: EVENT.ADD_EVENTS,
    events: body
  }
}

export function setPreloader(body) {
  return {
    type: EVENT.PRELOADER,
    preloader: body,
  }
}

export function setSearch(body) {
  return {
    type: EVENT.SET_SEARCH_INPUT,
    searchInput: body
  }
}
export function setDefaultSearch() {
  return {
    type: EVENT.DEFAULT_SEARCH
  }
}

export function setEventsCategories(body) {
  return {
    type: EVENT.SET_EVENTS,
    value: body
  }
}
export function setVenues(body) {
  return {
    type: EVENT.SET_VENUES,
    value: body
  }
}
export function setCategories(body) {
  return {
    type: EVENT.SET_CATEGORIES,
    value: body
  }
}
export function setSelectTo(body) {
  return {
    type: EVENT.SET_SELECT_TO,
    value: body
  }
}
export function setSelectFrom(body) {
  return {
    type: EVENT.SET_SELECT_FROM,
    value: body
  }
}
export function setCities(body) {
  return {
    type: EVENT.SET_CITIES,
    value: body
  }
}
export function setMin(body) {
  return {
    type: EVENT.SET_MIN,
    value: body
  }
}
export function setMax(body) {
  return {
    type: EVENT.SET_MAX,
    value: body
  }
}
export function setCurrentPage(body) {
  return {
    type: EVENT.CURRENT_PAGE,
    page: body
  }
}
export function setCurrentButtons(body) {
  return {
    type: EVENT.BUTTONS,
    value: body
  }
}

export function loadEvents(body) {
  return {
    type: EVENT.LOAD_EVENTS,
    events: body
  }
}

export const setAsyncEvent = () => (dispatch, getState) => {
  let eventsinfo = getCancelablePromise(getEventsList(
    {
      name_filter: getState().event.searchParameters.name_filter,
      promoter_id: getState().event.searchParameters.promoter_id,
      event_type_id: getState().event.searchParameters.event_type_id,
      events: getState().event.searchParameters.events,
      venues: getState().event.searchParameters.venues.length
        ? getState().event.searchParameters.venues.join()
        : null,
      event_categories: getState().event.searchParameters.event_categories
        .length
        ? getState().event.searchParameters.event_categories.join()
        : null,
      select_to:
        getState().event.searchParameters.select_to &&
        moment(getState().event.searchParameters.select_to)
          .add(1, "days")
          .format("YYYY-MM-DD"),
      select_from:
        getState().event.searchParameters.select_from &&
        moment(getState().event.searchParameters.select_from).format(
          "YYYY-MM-DD"
        ),
      cities: getState().event.searchParameters.cities.length
        ? getState().event.searchParameters.cities.join()
        : null,
      min: getState().event.searchParameters.min,
      max: getState().event.searchParameters.max
    }
  ));
  return Promise.all([eventsinfo.promise])
    .then((data) => {
      dispatch({
        type: EVENT.BUTTONS,
        value: Math.ceil(data[0].all_count / 15)
      });
      dispatch({
        type: EVENT.ADD_EVENTS,
        events: data[0]
      });
      dispatch({
        type: EVENT.PRELOADER,
        preloader: false
      });
      return data[0]
    }).catch((err) => {
      dispatch({
        type: EVENT.PRELOADER,
        preloader: false
      });
      return err
    })
}

export const loadAsyncEvent = () => (dispatch, getState) => {
  let eventsinfo = getCancelablePromise(getEventsList(
    {
      name_filter: getState().event.searchParameters.name_filter,
      promoter_id: getState().event.searchParameters.promoter_id,
      event_type_id: getState().event.searchParameters.event_type_id,
      events: getState().event.searchParameters.events,
      venues: getState().event.searchParameters.venues.length
        ? getState().event.searchParameters.venues.join()
        : null,
      event_categories: getState().event.searchParameters.event_categories
        .length
        ? getState().event.searchParameters.event_categories.join()
        : null,
      select_to:
        getState().event.searchParameters.select_to &&
        moment(getState().event.searchParameters.select_to)
          .add(1, "days")
          .format("YYYY-MM-DD"),
      select_from:
        getState().event.searchParameters.select_from &&
        moment(getState().event.searchParameters.select_from).format(
          "YYYY-MM-DD"
        ),
      cities: getState().event.searchParameters.cities.length
        ? getState().event.searchParameters.cities.join()
        : null,
      min: getState().event.searchParameters.min,
      max: getState().event.searchParameters.max
    }
  ));
  return Promise.all([eventsinfo.promise])
    .then((data) => {
      dispatch({
        type: EVENT.BUTTONS,
        value: Math.ceil(data[0].all_count / 15)
      });
      dispatch({
        type: EVENT.LOAD_EVENTS,
        events: data[0]
      });
      dispatch({
        type: EVENT.PRELOADER,
        preloader: false
      });
      return data[0]
    }).catch((err) => {
      dispatch({
        type: EVENT.PRELOADER,
        preloader: false
      });
      return err
    })
}

export const sortEventsBy = (sort) => (dispatch, getState) => {
  dispatch({
    type: EVENT.SET_EVENTS_SORT,
    payload: sort,
    state: getState(),
  })
}
