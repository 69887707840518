import React, {Component} from 'react';
import './style.scss';
import './media.scss';
import ReserveTypes from '../ReserveTypes/ReserveTypes';
import ReserveCreateType from '../ReserveCreateType/ReserveCreateType';
import Spinner from "../Spinner";
import { changeTicketsTemplate } from '../../api/order';

import OrderLayoutTicket from '../OrderLayoutTicket/OrderLayoutTicket';
import OrderPrint from '../OrderPrint/OrderPrint';
import {
  getTypesTemlpsList,
  getTypesTemlpsAllList,
  getValidTypesTemlpsAllList
} from "../../api/reserve";
import { 
  printOrder
} from '../../api/order';

const defaultState = {
  isShowReserveTypes: false,
  isShowReserveCreateType: false
};

class OrderListPrintModal extends Component{
  constructor(props) {
    super(props);

    this.state = {
      ...defaultState,
      typesTemplsList: [],
      ticket_template_type_id: 1,
      loader: false,
      ticket_template_type: 1,
    }
  }

  componentDidMount() {
    this.showReserveTypes();
    // console.log(this.props.order_id)
    // getTypesTemlpsList
    // console.log(this.props.order?.tickets[0]?.event_id)
    this.setState({
      loader: true,
    })
    getTypesTemlpsList(
      this.props.order?.tickets[0]?.event_id
      // this.props.order_id
    )
    .then(res => {
        this.setState({
          ticket_template_type_id: res.data[0].id,
          ticket_template_type: res.data[0].type.id
        })

      let data = res.data.map(item => {
        item.value = item.name;
        item.label = item.name;
        return item;
      });
      console.log(data)
      this.setState({
        typesTemplsList: data,
      }, () => {
        console.log(this.state.typesTemplsList.length + 'test')
      });
    })
    .catch(er => {
      // error
    })
    .finally(() => {
      this.setState({
        loader: false
      })
    })
  }

  showReserveTypes = () => {
    this.setState({
      ...defaultState,
      isShowReserveTypes: true
    })
  };

  showReserveCreateType = () => {
    this.setState({
      ...defaultState,
      isShowReserveCreateType: true
    })
  };

  changeTempls = (e) => {
    this.setState({
      ticket_template_type_id: e.id,
      ticket_template_type: e.type.id
    })
  }

  orderCreateManufacturing = async () => {
    // let tickets = this.props.order?.tickets.map(item => item.id);
    // this.setState({
    //   loader: true
    // })
    // await changeTicketsTemplate({
    //   ticket_template_id: this.state.ticket_template_type_id,
    //   // order_entity_id: this.props.order_id,
    //   order_entities: tickets.join(',')
    // }).finally(res => {
    //   this.setState({
    //     loader: false,
    //   })
    // })

    this.setState({
      isShowReserveCreateType: true,
      isShowReserveTypes: false,
    })
  }
  activeLoader = () => {
    this.setState({
      loader: true,
    })
  }

  hideLoader = () => {
    this.setState({
      loader: false,
    })
  }

  render() {
    const {isShowReserveTypes, isShowReserveCreateType} = this.state;

    let modalSize  = '';

    if (isShowReserveCreateType){
      modalSize = 'modal-xs';
    }
    if (isShowReserveTypes){
      modalSize = 'modal-md';
    }

    return (
      <>
        <div className="modal">
          <div className={`modal-box ${modalSize}`}>
          {this.state.loader && (
              <div className="modal_loader">
                <Spinner />
              </div>
            )}
            {isShowReserveTypes ?
              <OrderLayoutTicket
              typesTemplsList={this.state.typesTemplsList}
              changeTempls={this.changeTempls}
              orderCreateManufacturing={this.orderCreateManufacturing}
              /> : null
            }
            {isShowReserveCreateType &&
              <OrderPrint
                orderId={this.props.order_id}
                orderDisabled={this.props._handleModal} // hide modal
                typeTicketId={this.state.ticket_template_type} // id
                ticket_template_type_id={this.state.ticket_template_type_id}
                activeLoader={this.activeLoader}
                hideLoader={this.hideLoader}
              />
            }
            <div className="modal-close" onClick={this.props._handleModal}>
              <span className="icon icon-close"></span>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default OrderListPrintModal;
