import React, {Component} from 'react';
import './style.scss';
import './media.scss';
import {PhotoshopPicker} from 'react-color';

class ReserveCreateType extends Component{
  constructor(props) {
    super(props);

    this.state = {
      color: { r: 255, g: 255, b: 255, a: 1 }
    }
  }

  handleChangeComplete = (color) => {
    //color.hex
    this.setState({
      color: color.rgb
    })
  };

  render() {
    const {color} = this.state;

    return (
      <>
        <div className="reserve-type">
          <div className="modal-title">Создание нового типа резервации</div>
          <div className="form-group">
            <label>Название типа резервации</label>
            <div className="form-field">
              <input type="text" name="name"/>
            </div>
          </div>
          <div className="form-group">
            <label>Выберите цвет</label>
            <div className="reserve-color">
              <PhotoshopPicker
                color={color}
                onChange={this.handleChangeComplete}
              />
            </div>
          </div>
          <div className="form-button">
            <button className="btn">Создать</button>
            <button className="btn btn-link" onClick={this.props.showReserveTypes}>Отменить</button>
          </div>
        </div>
      </>
    )
  }
}

export default ReserveCreateType;
