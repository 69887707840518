import React, { Component } from 'react';
import './style.scss';
import './media.scss';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { getPromoterStatics } from '../../api/events';
import { connect } from 'react-redux';
import Spinner from "../../components/Spinner";

class EventsSidebar extends Component {
  constructor(props) {
    super(props);

    this.events = props.eventsList;
    this.state = {
      showEventsSidebar: false,
      loader: false,
      infoStatistics: {},
      activeSals: 'sales_today',
      activeGain: 'daily_gain',
      error: false,
    }
  }

  componentDidUpdate = (prevProps) => {
    if (!this.state.loader && (this.props.eventsList.length || prevProps.eventsList.length)) {
      this.setState({
        loader: true,
      })
      getPromoterStatics({
        promoter_id: this.props.eventsList[0].promoter_id
      })
        .then(({ data }) => {
          this.setState({
            infoStatistics: data,
          })
        })
        .catch(er => {
          this.setState({
            error: er?.response?.data?.text ?? 'Ошибка!'
          })
        })
    }
  }


  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, false);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, false);
  }

  handleClickOutside = (e) => {
    let path = e.path;
    if (!e.path) {
      path = e.composedPath();
    }

    const eventsSidebar = document.getElementsByClassName('events-sidebar')[0];
    if (!path.includes(eventsSidebar)) {
      const eventsSidebarToggle = document.getElementsByClassName('events-sidebar__toggle')[0];
      if (!path.includes(eventsSidebarToggle)) {
        this.setState({
          showEventsSidebar: false
        })
      }
    }
  };

  toggleEventsSidebar = () => {
    this.setState({
      showEventsSidebar: !this.state.showEventsSidebar
    })
  };

  render() {
    const { showEventsSidebar, infoStatistics, activeSals, activeGain, loader, error } = this.state;

    return (
      <aside className={`events-sidebar ${showEventsSidebar ? 'show' : ''}`}>
        <div className="events-sidebar__toggle" onClick={this.toggleEventsSidebar}>
          <span className="icon icon-statistics"></span>
        </div>
        <div className="events-sidebar__scroll">
          <PerfectScrollbar
            options={{
              wheelSpeed: 0.3,
              wheelPropagation: false
            }}
          >
            {
              !loader && (
                <div className="events-sidebar__spinner">
                  <Spinner />
                </div>
              )
            }
            {
              !error ? (
                <>
                  <div className="events-sidebar__box">
                    <div className="events-sidebar__box-body">
                      <div className="events-sidebar__statistics">
                        <div className="events-sidebar__statistics-box">
                          <div className="events-sidebar__statistics-box-text">Итого мероприятий</div>
                          <div className="events-sidebar__statistics-box-count">{infoStatistics?.common_stat?.event_count}</div>
                        </div>
                        <div className="events-sidebar__statistics-box">
                          <div className="events-sidebar__statistics-box-text">Всего билетов в продаже</div>
                          <div className="events-sidebar__statistics-box-count">{infoStatistics?.common_stat?.ticket_count}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="events-sidebar__box">
                    <div className="events-sidebar__box-head">Продажи</div>
                    <div className="events-sidebar__box-body">
                      <div className="events-sidebar__tabs">
                        <div className="events-sidebar__tabs-link">
                          <ul>
                            <li className={activeSals === 'sales_today' ? "active" : ""} onClick={() => { this.setState({ activeSals: 'sales_today' }) }} >Сегодня</li>
                            <li className={activeSals === 'sold_per_month' ? "active" : ""} onClick={() => { this.setState({ activeSals: 'sold_per_month' }) }} >Месяц</li>
                            <li className={activeSals === 'sold_total' ? "active" : ""} onClick={() => { this.setState({ activeSals: 'sold_total' }) }} >Все</li>
                          </ul>
                        </div>
                        {
                          infoStatistics[activeSals] && (
                            <div className="events-sidebar__tabs-info">
                              <div className="events-sidebar__tabs-info-box"><b>{infoStatistics[activeSals]?.ticket_count}</b> билетов</div>
                              <div className="events-sidebar__tabs-info-box"><b>{infoStatistics[activeSals]?.amount}</b> рублей</div>
                              <div className="events-sidebar__tabs-info-box"><b>{infoStatistics[activeSals]?.order_count}</b> заказов</div>
                            </div>
                          )
                        }
                      </div>
                    </div>
                  </div>
                  <div className="events-sidebar__box">
                    <div className="events-sidebar__box-head">Сравнить</div>
                    <div className="events-sidebar__box-body">
                      <div className="events-sidebar__tabs">
                        <div className="events-sidebar__tabs-link">
                          <ul>
                            <li className={activeGain === 'daily_gain' ? "active" : ""} onClick={() => { this.setState({ activeGain: 'daily_gain' }) }}>Вчера</li>
                            <li className={activeGain === 'weekly_gain' ? "active" : ""} onClick={() => { this.setState({ activeGain: 'weekly_gain' }) }}>Неделя</li>
                            <li className={activeGain === 'monthly_gain' ? "active" : ""} onClick={() => { this.setState({ activeGain: 'monthly_gain' }) }}>Месяц</li>
                          </ul>
                        </div>
                        {
                          infoStatistics[activeGain] && (
                            <div className="events-sidebar__tabs-info">
                              <div className="events-sidebar__tabs-info-box"><b>{infoStatistics[activeGain]?.order_count}</b> билетов</div>
                              <div className="events-sidebar__tabs-info-box"><b>{infoStatistics[activeGain]?.order_count}</b> рублей</div>
                              <div className="events-sidebar__tabs-info-box"><b>{infoStatistics[activeGain]?.order_count}</b> заказов</div>
                            </div>
                          )
                        }
                      </div>
                    </div>
                  </div>
                </>
              ) : (<div className="events-sidebar__box">
                <div className="events-sidebar__box-body">
                  {error}
                </div>
              </div>)
            }
            <div className="events-sidebar__box">
              <div className="events-sidebar__box-head">Помощь</div>
              <div className="events-sidebar__box-body">
                <div className="events-sidebar__box-text">
                  <p>Связаться с технической поддержкой можно с 11:00 до 20:00 по телефонам:</p>
                </div>
                <div className="events-sidebar__box-contacts">
                  <p><a href="tel:+3753265465454">+375 32 654 654 54</a></p>
                  <p><a href="tel:+3753265465454">+375 32 654 654 54</a></p>
                </div>
                <div className="events-sidebar__box-text">
                  <p>Отдел по работе с организаторами:</p>
                </div>
                <div className="events-sidebar__box-contacts">
                  <p><a href="tel:+3753265465454">+375 32 654 654 54</a></p>
                  <p><a href="mailto:promoter@ticketpro.by">promoter@ticketpro.by</a></p>
                </div>
                <div className="events-sidebar__box-text">
                  <p>Логотипы для размещения:</p>
                </div>
                <div className="events-sidebar__box-contacts">
                  <p><a href="www.ticketpro.by/organizatoram">www.ticketpro.by/organizatoram</a></p>
                </div>
              </div>
            </div>
          </PerfectScrollbar>
        </div>
      </aside>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  events: state.event,
  eventsList: state.event.events,
  searchParameters: state.event.searchParameters,
  preloader: state.event.preloader,
  placesList: state.places.list,
  citiesList: state.cities.list,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(EventsSidebar)
