import instance from "./index";
import serviceApi from './service';
import { Server } from "http";

export const getSeatLocksList = () => {
    return serviceApi(`/ticket-wp-api/v1/get-seat-locks-list/`, {
        min: 1,
        max: 500
      });
}


export const seatLockReserve = async (tickets_and_counts, seat_lock_id) => {
return serviceApi(`/ticket-wp-api/v1/seats-lock/`, {
  tickets_and_counts: tickets_and_counts,
  seat_lock_id: seat_lock_id,
});
}

export const seatUnlockReserve = tickets => {
return serviceApi(`/ticket-wp-api/v1/seats-unlock/`, {
    tickets: tickets
  });
}
//tickets="181735/1",prices="25",is_invitation="true" 
export const seatLock = creds => {
  return serviceApi(`/ticket-wp-api/v1/tickets-reserve/`, creds)
}

export const orderCreate = (data) => {
  return serviceApi(`/ticket-wp-api/v1/order-create/`, data)
}

export const orderPayMakeOrder = (order_id) => {
  return serviceApi(`/ticket-wp-api/v1/promoter-mnf-order-confirm/`, {
    order_id
  })
} 

export const getTypesTemlpsList = (event_id) => {
  return serviceApi('/ticket-wp-api/v1/get-event-ticket-templs-list/', {
    event_id: event_id,
    min: 1,
    max: 500,
  })
}

export const getTypesTemlpsAllList = () => {
  return serviceApi('/ticket-wp-api/v1/get-ticket-template-types-list/', {
    min: 1,
    max: 500,
  })
}

export const getValidTypesTemlpsAllList = (order) => {
  return serviceApi('/ticket-wp-api/v1/get-order-valid-tictempl-types/', {
    min: 1,
    max: 500,
    order_id: order
  })
}


export const getPdfTickets = (order_id, ticket_template_type_id, email, type) => {
  return serviceApi('/ticket-wp-api/v1/order-get-ticket/',{
    order_id: order_id,
    // ticket_template_type_id: ticket_template_type_id,
    email: email ? email : null,
    ticket_template_type: type ? type : null,
  })
} 