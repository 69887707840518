import React, { Component } from "react";
import "./style.scss";
import "./media.scss";
import PerfectScrollbar from "react-perfect-scrollbar";
import EventTicket from "../EventTicket/EventTicket";
import { parseNameAttribute } from "../../helpers/index";

class EventPlanReserveTickets extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowTickets: true
    };
  }

  ticketsToggle = () => {
    this.setState({
      isShowTickets: !this.state.isShowTickets
    });
  };

  render() {
    const { isShowTickets } = this.state;

    return (
      <>
        <div className="event-map__sidebar-tickets">
          <div className="event-map__sidebar-tickets-head">
            Билеты{" "}
            <button
              className={`event-map__sidebar-tickets-toggle ${
                isShowTickets ? "" : "active"
              }`}
              onClick={this.ticketsToggle}
            >
              <span className="icon icon-dd"></span>
            </button>
          </div>
          {isShowTickets && (
            <div className="event-map__sidebar-tickets-body">
              <div className="event-map__sidebar-tickets-list">
                <PerfectScrollbar
                  options={{
                    wheelSpeed: 0.3,
                    wheelPropagation: false
                  }}
                >
                  {this.props.listIdTickets.map(ticket => {
                    return (
                      <EventTicket
                        place={parseNameAttribute(
                          this.props.planList,
                          ticket.name
                        )}
                        id={ticket.id}
                        coast={
                          ticket.price
                        }
                        ticket_id={ticket.ticket_id}
                        deleteTicket={this.props.deleteTicket}
                      />
                    );
                  })}
                </PerfectScrollbar>
              </div>
            </div>
          )}
          <div className="event-map__sidebar-tickets-foot">
            <div className="event-map__sidebar-tickets-total">
              Итого выбрано: {this.props.listIdTickets.length} шт
            </div>
            <div className="event-map__sidebar-tickets-button">
              <button className="btn" onClick={this.props.showReserve}>
                Изменить тип резервации
              </button>
              <button className="btn" onClick={this.props.clearTickets}>
                Очистить
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default EventPlanReserveTickets;
