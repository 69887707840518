import React from "react";

export default () => {
  return (
    <div 
    className="loading">
      <div className="loading-box">
        <span className="icon icon-spinner"></span>
      </div>
    </div>
  );
};
