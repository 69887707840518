import React from "react";
import { connect } from "react-redux";
import { getCancelablePromise } from "../helpers/index";
import { setPlaces } from "../store/actions/places";
import { getPlacesList, getCitiesList, getCategoriesList, getSchemeForSeats } from "../api/events";
import { setCitiesList } from "../store/actions/cities";
import { setCategoriesList } from '../store/actions/category';
import { setSeatSchemeList } from '../store/actions/seatScheme';

export default function(ComposedClass) {
  class Component extends React.Component {
    componentDidMount = async () => {
      this.load();
    };

    load = () => {
      const seatScheme = getCancelablePromise(getSchemeForSeats());
      const events = getCancelablePromise(getPlacesList());
      const cities = getCancelablePromise(getCitiesList());
      const category = getCancelablePromise(getCategoriesList());

      Promise.all([events.promise, cities.promise, category.promise, seatScheme.promise])
        .then(data => {
          if (data[0].data === undefined) {
            throw new Error(data[0].error);
          } else {
            this.props.setPlaces(data[0].data);
          }
          if (data[2].data === undefined) {
            throw new Error(data[2].error);
          } else {
            this.props.setCategoriesList(data[2].data);
          }
          if (data[1].data === undefined) {
            throw new Error(data[1].error);
          } else {
            this.props.setCitiesList(data[1].data);
          }
          if (data[3] === undefined) {
            throw new Error(data[3].error);
          } else {
            this.props.setSeatSchemeList(data[3]);
          }
        })
        .catch(er => {
          setTimeout(() => this.load(), 15000);
        });
    };

    render() {
      return <ComposedClass {...this.props} />;
    }
  }

  const mapDispatchToProps = {
    setPlaces,
    setCategoriesList,
    setCitiesList,
    setSeatSchemeList,
  };
  return connect(null, mapDispatchToProps)(Component);
}
