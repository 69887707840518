import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './style.scss';
import './media.scss';

class OrderCashlessSuccess extends Component {
  render() {
    const { dataOrderInfo } = this.props;
    return (
      <>
        <div className="order-cashless-success">
          <div className="modal-title">Заказ успешно создан</div>
          <div className="order-cashless-success__text">Номер заказа</div>
          <div className="order-cashless-success__number">{dataOrderInfo.id}</div>
          <div className="order-cashless-success__info">
            <p>Пожалуйста зафиксируйте номер заказа для облегчения  его поиска.</p>
            <p>Для подтверждения  заказа и печати  перейдите в раздел заказы</p>
          </div>
          <div className="form-button">
            <button onClick={() => {
              this.props.history.push(`/event-orders/${this.props.match.params.eventID}`)
            }} className="btn">Перейти к заказам</button>
            <button onClick={() => { this.props.history.push('/') }} className="btn">Перейти к мероприятиям</button>
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(OrderCashlessSuccess);
