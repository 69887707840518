import React, { Component } from "react";
import "./style.scss";
import "./media.scss";
import Select from "react-select";
import PerfectScrollbar from "react-perfect-scrollbar";
import { parseNameAttribute } from "../../helpers/index";
import _ from 'lodash'
import { SetPromoTicket } from '../../api/ticket'
import Spinner from "../Spinner";

class OrderList extends Component {

  state = {
    validate: {},
    setTimeoutPromo: null,
    setTimeoutUpdate: null,
    checkedBoxes: [],
    allInputPromo: 0,
    allInputPromoId: false,
    loader: false,
    valuesPromocode: {},
  }
  refInput = {}

  selectedMultiValues = () => {
    let checkedBoxes = []
    this.state.checkedBoxes.forEach(item => {
      checkedBoxes.push(item.priceID)
    })
    if (this.props.eventPrices.filter(item => checkedBoxes.includes(item.id)).length) {
      let filterArray = _.filter(this.props.eventPrices, function (o) { return checkedBoxes.includes(o.id) })
      const reducer = (accumulator, currentValue) => {
        return _.unionBy(accumulator.discounts, currentValue.discounts, 'id')
      };
      let newArrayUnion = filterArray.reduce(reducer)
      if (!Array.isArray(newArrayUnion)) {
        return [
          {
            id: 'all',
            descriptions: [
              {
                name: 'Выберите скидку'
              }
            ]
          },
          ...newArrayUnion.discounts
        ]
      }
      return [
        {
          id: 'all',
          descriptions: [
            {
              name: 'Выберите скидку'
            }
          ]
        },
        ...newArrayUnion
      ]
    }
    return [
      {
        id: 'all',
        descriptions: [
          {
            name: 'Выберите скидку'
          }
        ]
      }
    ]
  }

  selectedValues = (ticket) => {
    if (this.props.eventPrices.filter(item => item.id === ticket.priceID).length) {
      let newArray = [
        {
          id: 'all',
          descriptions: [
            {
              name: 'Выберите скидку'
            }
          ]
        }
      ]
      this.props.eventPrices.map(item => {
        if (item.id === ticket.priceID) {
          item.discounts.map(itemDiscount => {
            if (itemDiscount.reached_ticket_count) {
              itemDiscount.disabled = true
            }
            newArray.push(itemDiscount)
          })
        }
      })
      return newArray
    }
    return [
      {
        id: 'all',
        descriptions: [
          {
            name: 'Выберите скидку'
          }
        ]
      }
    ]
  }

  setDiscountSelected = async (...params) => {
    this.setState(function (prevState) {
      let newValidate = prevState.validate;
      newValidate[params[0]] = ''
      return { validate: newValidate, loader: true, }
    });
    await SetPromoTicket(...params)
      .then(res => {
        this.setState(function (prevState) {
          let newValidate = prevState.validate;
          newValidate[params[0]] = 'has-success'
          return { validate: newValidate }
        });
      })
      .catch(er => {
        this.setState(function (prevState) {
          let newValidate = prevState.validate;
          newValidate[params[0]] = 'has-error'
          return { validate: newValidate }
        });
      })
      .finally(() => {
        clearTimeout(this.state.setTimeoutUpdate)
        this.setState({
          setTimeoutUpdate: setTimeout(() => {
            this.props.updateBasket()
            this.setState({
              loader: false,
            })
          }, 250)
        })
      })
  }

  handleCheckbox = (e, s) => {
    const checkedBoxes = [...this.state.checkedBoxes];
    if (e.target.checked) {
      checkedBoxes.push(s)
    } else {
      const index = checkedBoxes.findIndex((ch) => ch.basketID === s.basketID);
      checkedBoxes.splice(index, 1);
    }
    this.setState({ checkedBoxes });
  }

  setMultiValues = (select = false, ...params) => {
    this.state.checkedBoxes.forEach(item => {
      if (select) {
        this.setState(function (prevState) {
          let newValuesPromocodes = prevState.valuesPromocode;
          newValuesPromocodes[item.basketID] = select
          return {
            valuesPromocode: newValuesPromocodes
          }
        })
      }

      this.setDiscountSelected(item.basketID, ...params)
    })
  }

  render() {
    const { checkedBoxes, loader, valuesPromocode } = this.state;
    return (
      <>
        {
          loader &&
          (
            <div className="spinner-screen-order">
              <Spinner /> 
            </div>
          )
        }
        <div className="order-list">
          <div className="order-list__head">
            <div className="order-list__row">
              <div className="order-list__col order-place">Выбранное место</div>
              {!this.props.isShowOrderListZero && (
                <>
                  <div className="order-list__col order-price">Стоимость</div>
                  <div className="order-list__col order-discount">Скидка</div>
                  <div className="order-list__col order-promo">Промокод</div>
                </>
              )}
            </div>
          </div>
          <div className="order-list__body">
            <PerfectScrollbar
              options={{
                wheelSpeed: 0.3,
                wheelPropagation: false
              }}
            >
              {this.props.listIdTickets.map(item => {
                let place = parseNameAttribute(this.props.planList, item.name);
                return (
                  <div className="order-list__row">
                    {!this.props.isShowOrderListZero && (
                      <div className="order-list__col order-check">
                        <div className="checkbox">
                          <input
                            id={item.basketID}
                            type="checkbox"
                            name="order"
                            checked={checkedBoxes.find((ch) => ch.basketID === item.basketID)}
                            onChange={(e) => this.handleCheckbox(e, item)}
                          />
                          <label htmlFor={item.basketID}></label>
                        </div>
                      </div>
                    )}
                    <div className="order-list__col order-place">
                      {place.map(places => {
                        return (
                          <div className="place-box">{places.join(" ")}</div>
                        );
                      })}
                    </div>
                    {!this.props.isShowOrderListZero && (
                      <>
                        <div className="order-list__col order-price">
                          <span className="price">
                            <b>{item.price} BYN</b>
                          </span>
                          {
                            (item.hasOwnProperty('info') && item.info.discount) ? (
                              <span className="discount">-{item.info.discount} BYN</span>
                            ) : null
                          }
                        </div>
                        <div className="order-list__col order-discount">
                          <Select
                            isOptionDisabled={(option) => option.hasOwnProperty('disabled')}
                            classNamePrefix="select"
                            isDisabled={loader}
                            placeholder="Выберите из списка"
                            isSearchable={false}
                            menuPortalTarget={document.body}
                            options={this.selectedValues(item)}
                            defaultValue={
                              item.hasOwnProperty('info') &&
                              item.info.discount_id &&
                              this.selectedValues(item).filter(itemPromo => String(itemPromo.id) === String(item.info.discount_id))[0]}
                            value={
                              valuesPromocode[item.basketID] &&
                              valuesPromocode[item.basketID]
                            }
                            onChange={(e) => {
                              if (e.hasOwnProperty('promo_needed') && e.promo_needed === 0) {
                                this.setDiscountSelected(item.basketID, e.id)
                              } else if (e.hasOwnProperty('promo_needed') && e.promo_needed === 1) {
                              } else if (e.id === 'all') {
                                this.setDiscountSelected(item.basketID)
                              }
                              this.setState(function (prevState) {
                                let newValuesPromocodes = prevState.valuesPromocode;
                                newValuesPromocodes[item.basketID] = e
                                return {
                                  valuesPromocode: newValuesPromocodes
                                }
                              })
                              return e
                            }}
                            getOptionLabel={(option) => {
                              return option.descriptions[0].name
                            }}
                            getOptionValue={(item) => item.id}
                          />
                        </div>
                        <div className="order-list__col order-promo">
                          <div className={`form-group ${this.state.validate[item.basketID]}`}>
                            <input
                              onChange={(e) => {
                                let text = e.target.value
                                clearTimeout(this.state.setTimeoutPromo)
                                this.setState({
                                  setTimeoutPromo: setTimeout(() => {
                                    this.setDiscountSelected(item.basketID, this.state.valuesPromocode[item.basketID].id, text)
                                  }, 1000)
                                })
                              }}
                              ref={e => this.refInput[item.basketID] = e}
                              defaultValue={item.hasOwnProperty('info') ? item.info.promo : ''}
                              type="text"
                              disabled={
                                !(this.state.valuesPromocode.hasOwnProperty(item.basketID) &&
                                  this.state.valuesPromocode[item.basketID].hasOwnProperty("promo_needed") &&
                                  this.state.valuesPromocode[item.basketID].promo_needed === 1)
                              || loader
                                }
                            />
                            <button
                              onClick={() => {
                                this.setDiscountSelected(item.basketID, this.state.valuesPromocode[item.basketID].id, this.refInput[item.basketID].value)
                              }}
                            >
                              <span className="icon icon-refresh"></span>
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
            </PerfectScrollbar>
          </div>
          {!this.props.isShowOrderListZero && (
            <div className="order-list__foot">
              <div className="order-list__row">
                <div className="order-list__col order-text">
                  Установить скидку для выбранных билетов
                </div>
                <div className="order-list__col order-discount">
                  <Select
                    classNamePrefix="select"
                    placeholder="Выберите из списка"
                    isOptionDisabled={(option) => option.hasOwnProperty('disabled')}
                    isSearchable={false}
                    isDisabled={loader}
                    options={this.selectedMultiValues()}
                    getOptionLabel={(option) => {
                      return option.descriptions[0].name
                    }}
                    onChange={(e) => {
                      if (e.hasOwnProperty('promo_needed')) {
                        this.setState({
                          allInputPromo: e.promo_needed
                        })
                        if (e.promo_needed === 0) {
                          this.setMultiValues(e, e.id)
                        }
                      } else {
                        this.setMultiValues(e)
                        this.setState({
                          allInputPromo: 0
                        })
                      }
                      this.setState({
                        allInputPromoId: e
                      })
                      return e
                    }}
                    getOptionValue={(item) => item.id}
                    getOptionVal
                  />
                </div>
                <div className="order-list__col order-promo">
                  <div className="form-group">
                    <input
                      disabled={(this.state.allInputPromo === 0) || loader}
                      type="text"
                      ref={e => this.refInput['all'] = e}
                      onChange={(e) => {
                        let text = e.target.value
                        clearTimeout(this.state.setTimeoutPromo)
                        this.setState({
                          setTimeoutPromo: setTimeout(() => {
                            this.setMultiValues(this.state.allInputPromoId, this.state.allInputPromoId.id, text)
                          }, 1000)
                        })
                      }}
                    />
                    <button
                      onClick={() => {
                        this.setMultiValues(false, this.state.allInputPromoId.id, this.refInput['all'].value)
                      }}
                    >
                      <span className="icon icon-refresh"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default OrderList;
