import { EVENT } from '../const'
import _ from 'lodash'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const initialState = {
    searchParameters: {
        name_filter: '',
        promoter_id: null,
        event_type_id: null,
        events: null,
        venues: [],
        event_categories: [],
        select_to: null,
        select_from: null,
        cities: [],
        min: 1,
        max: 15,
    },
    currentPage: 1,
    count: 0,
    buttonEvents: 0,
    searchInput: null,
    events: [],
    preloader: true,
    totalEvent: [],
    totalEventId: false,

    sort: {
        type: false,
    }
};

export default persistReducer(
    {
        storage,
        key: 'contact',
        blacklist: ['preloader', 'events', 'searchInput', 'buttonEvents', 'count', 'currentPage', 'searchParameters'],
        whitelist: ['totalEvent', 'totalEventId']
    },
    (state = initialState, action) =>

    // export default function event(state = initialState, action)
    {
        switch (action.type) {
            case EVENT.SET_CURRENT_EVENT:
                return Object.assign({}, state, { totalEvent: action.event, totalEventId: action.id });
            case EVENT.CURRENT_EVENT:
                return Object.assign({}, state, { totalEvent: action.event, totalEventId: action.id });
            case EVENT.PRELOADER:
                return Object.assign({}, state, { preloader: action.preloader });
            case EVENT.BUTTONS:
                return Object.assign({}, state, { buttonEvents: action.value });
            case EVENT.CURRENT_PAGE:
                return Object.assign({}, state, { currentPage: action.page });
            case EVENT.ADD_EVENTS:
                return Object.assign({}, state, { events: action.events.events, count: action.events.all_count });
            case EVENT.LOAD_EVENTS:
                return Object.assign({}, state, state.events.push(...action.events.events), { count: action.events.all_count });
            case EVENT.DEFAULT_SEARCH:
                return Object.assign({}, state, {
                    currentPage: 1,
                    searchParameters: {
                        name_filter: "",
                        promoter_id: null,
                        event_type_id: null,
                        events: null,
                        venues: [],
                        event_categories: [],
                        select_to: null,
                        select_from: null,
                        cities: [],
                        min: 1,
                        max: 15,
                    },
                    events: [],
                });
            case EVENT.SET_SEARCH_INPUT:
                return Object.assign({}, state, {
                    currentPage: 1,
                    searchParameters: {
                        name_filter: action.searchInput,
                        promoter_id: null,
                        event_type_id: null,
                        events: state.searchParameters.events,
                        venues: state.searchParameters.venues,
                        event_categories: state.searchParameters.event_categories,
                        select_to: state.searchParameters.select_to,
                        select_from: state.searchParameters.select_from,
                        cities: state.searchParameters.cities,
                        min: 1,
                        max: 15,
                    }
                });
            case EVENT.SET_EVENTS:
                return Object.assign({}, state, {
                    currentPage: 1,
                    searchParameters: {
                        name_filter: state.searchParameters.name_filter,
                        promoter_id: null,
                        event_type_id: null,
                        events: action.value,
                        venues: state.searchParameters.venues,
                        event_categories: state.searchParameters.event_categories,
                        select_to: state.searchParameters.select_to,
                        select_from: state.searchParameters.select_from,
                        cities: state.searchParameters.cities,
                        min: 1,
                        max: 15,
                    }
                });
            case EVENT.SET_VENUES:
                return Object.assign({}, state, {
                    currentPage: 1,
                    searchParameters: {
                        name_filter: state.searchParameters.name_filter,
                        promoter_id: null,
                        event_type_id: null,
                        events: state.searchParameters.events,
                        venues: action.value,
                        event_categories: state.searchParameters.event_categories,
                        select_to: state.searchParameters.select_to,
                        select_from: state.searchParameters.select_from,
                        cities: state.searchParameters.cities,
                        min: 1,
                        max: 15,
                    }
                });
            case EVENT.SET_CATEGORIES:
                return Object.assign({}, state, {
                    currentPage: 1,
                    searchParameters: {
                        name_filter: state.searchParameters.name_filter,
                        promoter_id: null,
                        event_type_id: null,
                        events: state.searchParameters.events,
                        venues: state.searchParameters.venues,
                        event_categories: action.value,
                        select_to: state.searchParameters.select_to,
                        select_from: state.searchParameters.select_from,
                        cities: state.searchParameters.cities,
                        min: 1,
                        max: 15,
                    }
                });
            case EVENT.SET_SELECT_TO:
                return Object.assign({}, state, {
                    currentPage: 1,
                    searchParameters: {
                        name_filter: state.searchParameters.name_filter,
                        promoter_id: null,
                        event_type_id: null,
                        events: state.searchParameters.events,
                        venues: state.searchParameters.venues,
                        event_categories: state.searchParameters.event_categories,
                        select_to: action.value,
                        select_from: state.searchParameters.select_from,
                        cities: state.searchParameters.cities,
                        min: state.searchParameters.min,
                        max: state.searchParameters.max,
                    }
                });
            case EVENT.SET_SELECT_FROM:
                return Object.assign({}, state, {
                    currentPage: 1,
                    searchParameters: {
                        name_filter: state.searchParameters.name_filter,
                        promoter_id: null,
                        event_type_id: null,
                        events: state.searchParameters.events,
                        venues: state.searchParameters.venues,
                        event_categories: state.searchParameters.event_categories,
                        select_to: state.searchParameters.select_to,
                        select_from: action.value,
                        cities: state.searchParameters.cities,
                        min: state.searchParameters.min,
                        max: state.searchParameters.max,
                    }
                });
            case EVENT.SET_CITIES:
                return Object.assign({}, state, {
                    currentPage: 1,
                    searchParameters: {
                        name_filter: state.searchParameters.name_filter,
                        promoter_id: null,
                        event_type_id: null,
                        events: state.searchParameters.events,
                        venues: state.searchParameters.venues,
                        event_categories: state.searchParameters.event_categories,
                        select_to: state.searchParameters.select_to,
                        select_from: state.searchParameters.select_from,
                        cities: action.value,
                        min: state.searchParameters.min,
                        max: state.searchParameters.max,
                    }
                });
            case EVENT.SET_MIN:
                return Object.assign({}, state, {
                    currentPage: state.currentPage,
                    searchParameters: {
                        name_filter: state.searchParameters.name_filter,
                        promoter_id: null,
                        event_type_id: null,
                        events: state.searchParameters.events,
                        venues: state.searchParameters.venues,
                        event_categories: state.searchParameters.event_categories,
                        select_to: state.searchParameters.select_to,
                        select_from: state.searchParameters.select_from,
                        cities: state.searchParameters.cities,
                        min: action.value,
                        max: state.searchParameters.max,
                    }
                });
            case EVENT.SET_MAX:
                return Object.assign({}, state, {
                    currentPage: state.currentPage,
                    searchParameters: {
                        name_filter: state.searchParameters.name_filter,
                        promoter_id: null,
                        event_type_id: null,
                        events: state.searchParameters.events,
                        venues: state.searchParameters.venues,
                        event_categories: state.searchParameters.event_categories,
                        select_to: state.searchParameters.select_to,
                        select_from: state.searchParameters.select_from,
                        cities: state.searchParameters.cities,
                        min: state.searchParameters.min,
                        max: action.value,
                    }
                });
            case EVENT.SET_EVENTS_SORT:
                return Object.assign({}, state,
                    {
                        events: eventSortBy(state.events, action.payload, action.state)
                    }
                );
            default:
                return state;
        }
    }
)

const eventSortBy = (events, sort, state) => {
    let sortEvents = events
    if (sort.status) {
        if (sort.type === 'event') {
            sortEvents = _.orderBy(events, ['descriptions[0].name'], [sort.status]);

        } else if (sort.type === 'status') {
            sortEvents = _.orderBy(events, ['event_statuses[0].name'], [sort.status]);

        } else if (
            sort.type === 'city'
        ) {
            sortEvents = _.orderBy(events, (e) => {
                return getCities(e.place_id, state.places.list, state.cities.list)
            }, [sort.status]);

        } else if (
            sort.type === 'place_id'
        ) {
            sortEvents = _.orderBy(events, (e) => {
                return getPlaces(e.place_id, state.places.list)
            }, [sort.status]);

        } else {
            sortEvents = _.orderBy(events, [sort.type], [sort.status]);
        }
    }
    return [...sortEvents]
}

const getPlaces = (id, placesList) => {
    if (placesList.length && id) {
        let place = ((_.find(placesList, { id: id })));
        if (place) {
            return place.descriptions[0].name;
        }
    }
    return "";
}

const getCities = (id, placesList, citiesList) => {
    if (placesList.length && id && citiesList.length) {
        let id_places = (_.find(placesList, { id: id })).city_id;
        let city = (_.find(citiesList, { id: id_places }));
        if (city) {
            return city.descriptions[0].name;
        }
    }
    return "";
}
