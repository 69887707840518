import { ORDERS } from '../const';
import { ordersList, getOrder } from '../../api/order'

import { getCancelablePromise } from '../../helpers/index';
import { error } from '../../containers/const';
import { svgTotalEvent } from '../../api/events';

export const setTotalEvent = (id) => async dispatch => {
    return svgTotalEvent(id).then(res => {
        if (res.data) {
            dispatch({
                type: ORDERS.CURRENT_EVENT,
                event: res.data,
                id: id,
            });
            return res.data;
        } else {
            return false
        }
    }).catch(er => {
        return false;
    })
}

export function setEvents(body) {
    return {
        type: ORDERS.ADD_ORDERS,
        events: body
    }
}

export function setPreloader(body) {
    return {
        type: ORDERS.PRELOADER,
        preloader: body,
    }
}

export function setSearch(body) {
    return {
        type: ORDERS.SET_SEARCH_INPUT,
        searchInput: body
    }
}
export function setDefaultSearch() {
    return {
        type: ORDERS.DEFAULT_SEARCH
    }
}

export function setEventsCategories(body) {
    return {
        type: ORDERS.SET_ORDERS,
        value: body
    }
}
export function setVenues(body) {
    return {
        type: ORDERS.SET_VENUES,
        value: body
    }
}
export function setCategories(body) {
    return {
        type: ORDERS.SET_CATEGORIES,
        value: body
    }
}
export function setSelectTo(body) {
    return {
        type: ORDERS.SET_SELECT_TO,
        value: body
    }
}
export function setSelectFrom(body) {
    return {
        type: ORDERS.SET_SELECT_FROM,
        value: body
    }
}
export function setCities(body) {
    return {
        type: ORDERS.SET_CITIES,
        value: body
    }
}
export function setCurrentPage(body) {
    return {
        type: ORDERS.CURRENT_PAGE,
        page: body
    }
}
export function setCurrentButtons(body) {
    return {
        type: ORDERS.BUTTONS,
        value: body
    }
}

export const getListOrders = () => (dispatch, getState) => {
        if (getState().orders.loaded) {
            try {
                dispatch({
                    type: ORDERS.PRELOADER,
                    preloader: true
                });
                let searchData = {
                    min: getState().orders.searchParameters.min,
                    max: getState().orders.searchParameters.max, 
                }
                if(getState().orders.searchIdEvent) {
                    searchData.event_id = getState().orders.searchIdEvent;
                } else {
                    searchData.is_for_report = true;
                }
                ordersList(searchData)
                    .then(({ data }) => {
                        dispatch({
                            type: ORDERS.BUTTONS,
                            value: Math.ceil(data.all_count / 15),
                            max: +getState().orders.searchParameters.max + 15,
                            min: +getState().orders.searchParameters.max + 1
                        });
                        dispatch({
                            type: ORDERS.ADD_ORDERS,
                            orders: data,
                            loaded: data.length ? true : false,
                        });
                        dispatch({
                            type: ORDERS.PRELOADER,
                            preloader: false
                        });
                        return data
                    }).catch((err) => {
                        dispatch({
                            type: ORDERS.PRELOADER,
                            preloader: false
                        });
                        throw err
                    })
            } catch (er) {
                console.error(er)
            }
        }
}

export const sortEventsBy = (sort) => (dispatch, getState) => {
    dispatch({
        type: ORDERS.SET_ORDERS_SORT,
        payload: sort,
        state: getState(),
    })
}
export const sortEventsById = (sort) => (dispatch, getState) => {
    dispatch({
        type: ORDERS.SET_ORDERS_SORT_ID,
        payload: sort,
        state: getState(),
    })
}

export function searchNameEvent(body) {
    return {
        type: ORDERS.SET_SEARCH_INPUT,
        payload: body
    }
}
export function searchDateEvent(body) {
    return {
        type: ORDERS.SET_SEARCH_DATE,
        payload: body
    }
}

export function searchIdEvent(body) {
    return {
        type: ORDERS.SET_SEARCH_ID_EVENT,
        payload: body
    }
}

export function setFilterMultiTypes(body) {
    return {
        type: ORDERS.SET_FILTER_MULTI_TYPES,
        payload: body
    }
}

export function setFilterTypes(body) {
    return {
        type: ORDERS.SET_FILTER_TYPES,
        payload: body
    }
}

export function setClearFilter() {
    return {
        type: ORDERS.SET_CLEAR_FILTER,
    }
}

export const searchOrderFilter = (id) => (dispatch, getState) => {
    getOrder({ order_id: id })
    .then(({ data }) => {
        if(!getState().orders.orders.filter(item => +item.id === +id).length) {
            dispatch({
                type: ORDERS.ADD_EVENT,
                payload: data
            });
        }
        dispatch({
            type: ORDERS.SET_SEARCH_FILTER,
        })
    })
    .catch(er => {
        dispatch({
            type: ORDERS.SET_SEARCH_FILTER,
        })
    })
}

/*
export const searchOrderFilter = (id) => (dispatch, getState) => {
    try {
        if (!id.length) {
            dispatch(setDefaultSearch());
            dispatch(getListOrders());
        } else {
            dispatch({
                type: ORDERS.SET_CURRENT_ORDER_LOADER,
            })
            getOrder({ order_id: id })
                .then(({ data }) => {
                    dispatch({
                        type: ORDERS.ADD_EVENT,
                        payload: data
                    });
                })
                .catch(er => {
                    dispatch({
                        type: ORDERS.SET_SEARCH_FILTER,
                    })
                })
        }
    } catch (er) {
    }
}
*/

export const setIdFindOrders = (id) => {
    if(!id) {
        return {
            type: ORDERS.DEFAULT_SEARCH
        }
    }
    return {
        type: ORDERS.SET_ID_FIND,
        payload: id,
    }   
}

export const setCurrentOrder = (id) => (dispatch, getState) => {
        try {
            dispatch({
                type: ORDERS.SET_CURRENT_ORDER_LOADER,
            })
            getOrder({order_id: id})
                .then(({ data }) => {
                    dispatch({
                        type: ORDERS.SET_CURRENT_ORDER,
                        payload: data
                    });
                }).catch((err) => {
                    dispatch({
                        type: ORDERS.SET_CURRENT_ORDER_ERROR,
                    });
                    throw err
                })
        } catch (er) {
            console.error(er)
        }
    }