import React, {Component} from 'react';
import './style.scss';
import './media.scss';

class OrderInfo extends Component{
  render() {
    return (
      <>
        <div className="order-info">
          <div className="modal-title">Что дальше?</div>
          <div className="modal-text">
            <p>Выберите действие из предложенных и оформите заказ.</p>
            <p>Выберите скидку для места, либо воспользуйтесь установкой скидки для выбора билетов. После оформления заказа выберите дальнейшие действия.</p>
          </div>
        </div>
      </>
    )
  }
}

export default OrderInfo;
