import React, { Component } from 'react';
// import './style.scss';
// import './media.scss';
import EventBox from '../../../components/EventBox/EventBox';
import EventMenu from '../../../components/EventMenu/EventMenu';
import EventOrdersFilter from '../../../components/EventOrdersFilter/EventOrdersFilter';
import EventOrdersSearch from '../../../components/EventOrdersSearch/EventOrdersSearch';
import EventOrdersList from '../../../components/EventOrdersList/EventOrdersList';
import { connect } from "react-redux";
import moment from "moment";
import { formatedData } from '../../../helpers/index';

class HeaderMain extends Component {

  render() {
    const { totalEvent, searchIdEventOrder } = this.props;
    return (
      <>
        <main className="main">
          <div className="event-panel">
            <div className="event-panel__head">
              {
                totalEvent?.event?.descriptions[0].name && (
                  <div className="event-panel__head-left">
                    <button className="event-back" onClick={() => {this.props.history.push('/')}}>
                      <span className="icon icon-arr-left"></span>
                    </button>
                    {
                      searchIdEventOrder && (
                        <EventBox
                        date={formatedData(totalEvent?.event?.date_time_from)}
                        time={moment(totalEvent?.event?.date_time_from).locale('ru').format("H:mm")}
                        name={totalEvent?.event?.descriptions[0].name}
                        location={totalEvent?.venue?.descriptions[0].name}
                      />
                      )
                    }
                  </div>
                )
              }
              <div className="event-panel__head-right">
              </div>
            </div>
          </div>
          <div className="event-orders">
            
          </div>
        </main>
      </>
    )
  }
}

const mapStateToProps = state => {
  console.log(state.event)
  return {
  totalEvent: state.event.totalEvent,
  idEvent: state.event.totalEventId,
  planList: state.seatScheme.list,
  searchIdEventOrder: state.orders.searchIdEvent

}};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMain);
