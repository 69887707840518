import React, { Component } from 'react'
import ReportsList from '../../../components/ReportsList/ReportsList';
import moment from 'moment';
import { connect } from 'react-redux';
import {
    setAsyncEvent,
    clearEvents,
} from '../../../store/actions/report';
import { 
    setTotalEvent
} from '../../../store/actions/event';

class AnalyticsChoice extends Component {

    state = {
        filterSearch: {
            place: null,
            event: '',
            city: null,
            dateFrom: null,
            dateTo: null,
            page: 2,
        },
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, false);
        this.props.clearEvents();
        this.props.setAsyncEvent({
            name_filter: this.state.filterSearch.event || null,
            venues: this.state.filterSearch.place || null,
            select_to: this.state.filterSearch.dateTo ?
                moment(this.state.filterSearch.dateTo).format(
                    "YYYY-MM-DD"
                ) : null,
            select_from:
                this.state.filterSearch.dateFrom ?
                    moment(this.state.filterSearch.dateFrom).format(
                        "YYYY-MM-DD"
                    ) : null,
            cities: this.state.filterSearch.city || null,
            // min: (this.state.filterSearch.page * 15) - 14,
            // max: this.state.filterSearch.page * 15,
            min: 1,
            max: 15,
        });
    }


    render() {
        return (
            <main className="main">
                <div className="container">
                    <section className="analytic_container">

                        <ReportsList
                            itsAnalytic={true}
                            setTotalEvent={(e) => {this.props.setTotalEvent(e)}}
                        />
                    </section>
                </div>
            </main>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        placesList: state.places.list,
        citiesList: state.cities.list,
    }
}

const mapDispatchToProps = {
    setAsyncEvent,
    clearEvents,
    setTotalEvent
}

export default (connect(mapStateToProps, mapDispatchToProps)(AnalyticsChoice))
