import React, { Component } from "react";
import "./style.scss";
import "./media.scss";
import Logo from "../../assets/img/logo-short.svg";
import { Link } from "react-router-dom";
import {
  setEvents,
  setSearch,
  setPreloader,
  setSelectTo,
  setSelectFrom,
  setVenues,
  setDefaultSearch,
  setAsyncEvent,
} from "../../store/actions/event";
import { setSearchInput } from "../../store/actions/places";
import { connect } from "react-redux";
import { error } from "../../containers/const/index";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import Exit from "../../containers/Exit";
import DayPicker, { DateUtils } from "react-day-picker";
import DayPickerInput from "react-day-picker";
import moment from "moment";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils from "react-day-picker/moment";
import { newFilteredArrayPlaces } from "../../store/reducers/placesResellect";
import ListLink from "./ListLink";
import Modal from "./ModalError";
import "moment/locale/ru";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      from: undefined,
      to: undefined,
      isShowDateModal: false,
      isShowPlaceModal: false,
      isisShowNoticeModal: false,
      isShowUserModal: false,
      isShowMenuModal: false,
      errorSearch: false,
      searchInput: "",
      searchTimeout: false
    };

    this.handleDayClick = this.handleDayClick.bind(this);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, false);
  }

  async componentDidMount() {
    document.addEventListener("click", this.handleClickOutside, false);
  }

  handleClickOutside = e => {
    let path = e.path;
    if (!e.path) {
      path = e.composedPath();
    }

    const dateModal = document.getElementsByClassName("search-date")[0];
    if (!path.includes(dateModal)) {
      const searchDateIcon = document.getElementsByClassName(
        "search-date search-icon"
      )[0];
      if (!path.includes(searchDateIcon)) {
        this.setState({
          isShowDateModal: false
        });
      }
    }

    const placeModal = document.getElementsByClassName("search-place")[0];
    if (!path.includes(placeModal)) {
      const searchPlaceIcon = document.getElementsByClassName(
        "search-place search-icon"
      )[0];
      if (!path.includes(searchPlaceIcon)) {
        this.setState({
          isShowPlaceModal: false
        });
      }
    }

    const noticeModal = document.getElementsByClassName("notice")[0];
    if (!path.includes(noticeModal)) {
      this.setState({
        isShowNoticeModal: false
      });
    }

    const userModal = document.getElementsByClassName("user")[0];
    if (!path.includes(userModal)) {
      this.setState({
        isShowUserModal: false
      });
    }

    const topMenu = document.getElementsByClassName("top-menu")[0];
    if (!path.includes(topMenu)) {
      const menuToggle = document.getElementsByClassName("menu-toggle")[0];
      if (!path.includes(menuToggle)) {
        this.setState({
          isShowMenuModal: false
        });
      }
    }
  };

  showDateModal = () => {
    this.setState({
      isShowDateModal: true
    });
  };

  handleDayClick = day => {
    const range = DateUtils.addDayToRange(day, this.state);
    const { searchParameters } = this.props;
    if (range.to === null) {
      this.props.setSelectTo(null);
    }
    if (range.from === null) {
      this.props.setSelectFrom(null);
    }

    if (
      range.from &&
      moment(range.from).format("YYYY-MM-DD") !== searchParameters.select_from
    ) {
      range.from && this.props.setSelectFrom(range.from);
      range.to && this.props.setSelectTo(range.from);
    }

    if (
      range.to &&
      moment(range.to).format("YYYY-MM-DD") !== searchParameters.select_to
    ) {
      if (String(range.to) !== String(range.from)) {
        this.props.setSelectTo(range.to);
      }
    }

    if (
      range.from !== null &&
      range.to !== null &&
      String(range.to) === String(range.from)
    ) {
      this.props.setSelectTo(range.from);
    }

    this.searchEvents(this.state.searchInput);

    this.setState(range);
    // if (range.to) {
    //   this.setState({
    //     isShowDateModal: false
    //   })
    // }
  };

  searchEvents = async searchValue => {
    let { searchTimeout } = this.state;
    clearTimeout(searchTimeout);
    this.props.setPreloader(true);
    this.props.setSearch(searchValue);
    this.setState({
      searchInput: searchValue
    });
    let data;
    this.setState({
      searchTimeout: setTimeout(async () => {
        data = await this.props.setAsyncEvent();
        if (data.events === undefined) {
          this.setState({ errorSearch: true });
          this.props.setDefaultSearch();
          this.setState(prevState => ({
            errorSearch: true,
            searchInput: ""
          }));
          this.searchEvents("");
        }
      }, 300)
    });
  };

  showPlaceModal = () => {
    this.setState({
      isShowPlaceModal: true
    });
  };

  showNoticeModal = () => {
    this.setState({
      isShowNoticeModal: true
    });
  };

  showUserModal = () => {
    this.setState({
      isShowUserModal: true
    });
  };

  toggleMenu = () => {
    this.setState({
      isShowMenuModal: !this.state.isShowMenuModal
    });
  };

  abbreviationFio = () => {
    const { fio } = this.props.auth;
    if (fio !== null && fio.split(" ")[0][0] && fio.split(" ")[1][0]) {
      return fio.split(" ")[0][0] + fio.split(" ")[1][0];
    } else {
      return null;
    }
  };

  searchPlaces = id => {
    const { searchParameters } = this.props;
    if (!searchParameters.venues.includes(id)) {
      let places = searchParameters.venues;
      places.push(id);
      this.props.setVenues(places);
      this.searchEvents(this.state.searchInput);
    } else {
      let places = searchParameters.venues.filter(name => name !== id);
      this.props.setVenues(places);
      this.searchEvents(this.state.searchInput);
    }
  };

  _refreshFilter = () => {
    this.setState({
        to:null,
        from: null,    
    });
    this.props.setDefaultSearch();
    this.searchEvents("");
  }

  changePlaced = value => {
    this.props.setSearchInput(value);
  };

  handleCloseModal = () => {
    this.setState(prevState => ({
      errorSearch: false
    }));
  };

  render() {
    const {
      isShowDateModal,
      from,
      to,
      isShowPlaceModal,
      isShowNoticeModal,
      isShowUserModal,
      isShowMenuModal,
      errorSearch,
    } = this.state;
    // const modifiers = { start: from, end: to };
    const { listPlaces, searchParameters } = this.props;
    const modifiers = {
      start: this.props.searchParameters.select_from,
      end: this.props.searchParameters.select_to
    };

    return (
      <header className="header">
        <div className="header-left">
          <div className="logo-short">
            <Link to={"/"}>
              <img src={Logo} alt="" />
            </Link>
          </div>
          <div className="search">
            <Link to={"/"}>
              <input
                onChange={e => {
                  this.searchEvents(e.target.value);
                }}
                type="text"
                placeholder="Поиск"
                disabled={errorSearch}
                value={this.props.searchParameters.name_filter}
              />
            </Link>
            <span className="icon icon-search"></span>
          </div>
          <Link to={"/"}>
            <div className={`search-place ${isShowPlaceModal ? "show" : ""}`}>
              <div className="search-icon" onClick={this.showPlaceModal}>
                <span className="icon icon-marker"></span>
              </div>
              <div className="search-place-modal">
                <div className="search-place-modal__head">
                  <input
                    onChange={e => this.changePlaced(e.target.value)}
                    type="text"
                    name="place"
                    placeholder="Место проведения"
                  />
                </div>
                <div className="search-place-modal__list">
                  <PerfectScrollbar
                    options={{
                      wheelSpeed: 0.3,
                      wheelPropagation: false
                    }}
                  >
                    {listPlaces.map(item => (
                      <div
                        key={item.id}
                        onClick={() => this.searchPlaces(item.id)}
                        className={`search-place-modal__list-box${searchParameters.venues.includes(item.id)
                          ? " _active"
                          : ""
                          }`}
                      >
                        {item.descriptions[0].name}
                      </div>
                    ))}
                  </PerfectScrollbar>
                </div>
              </div>
            </div>
          </Link>
          <Link to={"/"}>
            <div className={`search-date ${isShowDateModal ? "show" : ""}`}>
              <div
                className={`search-icon ${from && to ? "show" : ""}`}
                onClick={this.showDateModal}
              >
                <span className="icon icon-calendar"></span>
                {from && to ? (
                  from.toLocaleDateString() == to.toLocaleDateString() ? (
                    <span className="search-icon__date">
                      {from.toLocaleDateString()}
                    </span>
                  ) : (
                    <span className="search-icon__date">
                      {from.toLocaleDateString()} - {to.toLocaleDateString()}
                    </span>
                  )
                ) : (
                  ""
                )}
              </div>
              <div className="search-date-modal">
                <div className="search-date-modal__title">Дата мероприятия</div>
                <div className="search-date-modal__calendar">
                  <DayPickerInput
                    localeUtils={MomentLocaleUtils}
                    locale="ru"
                    selectedDays={[
                      this.props.searchParameters.select_from,
                      {
                        from: this.props.searchParameters.select_from,
                        to: this.props.searchParameters.select_to
                      }
                    ]}
                    onDayClick={this.handleDayClick}
                    modifiers={modifiers}
                    showOutsideDays
                  />
                </div>
              </div>

            </div>
          </Link>
          <Link to={"/"}>

            <div
            onClick={this._refreshFilter}
              className={`search-icon show`}
            >
              <span className="icon icon-refresh"></span>
            </div>

          </Link>
        </div>
        <div className="header-right">
          <ListLink
            location={this.props.location}
            isShowMenuModal={isShowMenuModal}
          />
          {/* <a href="" className="btn">
            Новое событие
          </a> */}
          {/* <div className="notice">
            <div className="notice-icon" onClick={this.showNoticeModal}>
              <span className="icon icon-bell"></span>
              <span className="notice-count">2</span>
            </div>
            <div className={`notice-modal ${isShowNoticeModal ? "show" : ""}`}>
              <div className="notice-modal__title">
                <span className="icon icon-bell"></span>Уведомления
              </div>
              <div className="notice-modal__list">
                <PerfectScrollbar
                  options={{
                    wheelSpeed: 0.3,
                    wheelPropagation: false
                  }}
                >
                  <div className="notice-modal__box new">
                    <div className="notice-modal__box-date">1 янв 2020</div>
                    <div className="notice-modal__box-text">
                      Добавленно новое мероприятие
                    </div>
                  </div>
                  <div className="notice-modal__box new">
                    <div className="notice-modal__box-date">1 янв 2020</div>
                    <div className="notice-modal__box-text">
                      Мероприятие отменено
                    </div>
                  </div>
                  <div className="notice-modal__box">
                    <div className="notice-modal__box-date">1 янв 2020</div>
                    <div className="notice-modal__box-text">
                      Добавленно новое мероприятие
                    </div>
                  </div>
                  <div className="notice-modal__box">
                    <div className="notice-modal__box-date">1 янв 2020</div>
                    <div className="notice-modal__box-text">
                      Мероприятие отменено
                    </div>
                  </div>
                  <div className="notice-modal__box">
                    <div className="notice-modal__box-date">1 янв 2020</div>
                    <div className="notice-modal__box-text">
                      Добавленно новое мероприятие
                    </div>
                  </div>
                  <div className="notice-modal__box">
                    <div className="notice-modal__box-date">1 янв 2020</div>
                    <div className="notice-modal__box-text">
                      Мероприятие отменено
                    </div>
                  </div>
                </PerfectScrollbar>
              </div>
            </div>
          </div> */}
          <div className="user">
            <div className="user-icon" onClick={this.showUserModal}>
              <span>{this.abbreviationFio()}</span>
            </div>
            <div className={`user-modal ${isShowUserModal ? "show" : ""}`}>
              <div className="user-modal__title">Профиль</div>
              <div className="user-modal__list">
                <ul>
                  {/* <li>
                    <a href="">
                      <span className="icon icon-settings"></span>Настройки
                      профиля
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <span className="icon icon-bell"></span>Настройки
                      уведомлений
                    </a>
                  </li> */}
                  <li>
                    <Link to={"/"} onClick={() => Exit.exit()}>
                      <span className="icon icon-exit"></span>Выйти
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="menu-toggle" onClick={this.toggleMenu}>
            <span className="icon icon-menu"></span>
          </div>
        </div>
        {errorSearch && (
          <Modal style={{ maxWidth: 'max-content' }} isClose hideModal={this.handleCloseModal}>
            <div className="modal-text">Произошла ошибка в поиске!</div>
          </Modal>
        )}
      </header>
    );
  }
}

const makeMapStateToProps = () => {
  const getNewFilteredArray = newFilteredArrayPlaces();
  const mapStateToProps = state => {
    return {
      listPlaces: getNewFilteredArray(state),
      auth: state.auth,
      events: state.event,
      eventsList: state.event.events,
      searchParameters: state.event.searchParameters,
      preloader: state.event.preloader
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = {
  setEvents,
  setSearch,
  setPreloader,
  setSelectTo,
  setSelectFrom,
  setSearchInput,
  setVenues,
  setDefaultSearch,
  setAsyncEvent
};

export default connect(makeMapStateToProps, mapDispatchToProps)(Header);
