import { REPORT } from '../const';

const initialState = {
    list: [],
    searchListEvents: {
        list: [],
        error: false,
        loading: false,
        count: 0,
        currentPage: 0,
        filter: {},
    },
    searchParameters: {

    },
};

export default function report(state = initialState, action) {

    switch (action.type) {
        case REPORT.SET:
            return Object.assign({}, state, { list: action.payload });

        case REPORT.PRELOADER_EVENTS: 
        return Object.assign({}, state, { searchListEvents:{
            
            ...state.searchListEvents,
            loading: action.payload,
        } });

        case REPORT.ADD_EVENTS:
            return Object.assign({}, state, {
                searchListEvents: {
                    ...state.searchListEvents,
                    currentPage: action.currentPage,
                    list: action.events,
                    count: action.count,
                    error: false,
                    filter: action.filter,
                }
            });

        case REPORT.CLEAR_EVENTS:
            return Object.assign({},
                state,
                {
                    searchListEvents: {
                        loading: false,
                        error: false,
                        list: [],
                        count: 0,
                    }
                })

        default:
            return state;
    }
}