import React, { Component } from "react";
import "./style.scss";

export default class Modal extends Component {
  render() {
    const { children, isClose, hideModal, style = {maxWidth: 'max-content'} } = this.props;
    return (
      <div className="modal">
        <div style={style} className="modal-box">
          {children}
          {isClose && (
            <div
              className="modal-close"
              onClick={() => {
                hideModal();
              }}
            >
              <span className="icon icon-close"></span>
            </div>
          )}
        </div>
      </div>
    );
  }
}
