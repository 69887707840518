import React, { Component } from 'react';
import './style.scss';
import './media.scss';

class OrderStep extends Component {
  constructor(props) {
    super(props);

  }

  render() {
    const { orderStep, orderStepAccess } = this.props;

    return (
      <>
        <div className="order-step">
          <ul>
            <li
              // onClick={this.props.showOrderStep1}
              className={orderStep === 1 ? 'active' : orderStep > 1 ? 'complete' : ''}>Создание заказа</li>
            <li
              // onClick={this.props.showOrderStep2}
              className={orderStep === 2 ? 'active' : orderStep > 2 ? 'complete' : ''}>Оплата билетов</li>
            <li
              // onClick={orderStepAccess === 3 && this.props.showOrderStep3}
              className={orderStep === 3 ? 'active' : orderStep > 3 ? 'complete' : ''}>Печать билетов</li>
          </ul>
        </div>
      </>
    )
  }
}

export default OrderStep;
