import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setEvents, setPreloader, setMin, setMax, setDefaultSearch, loadAsyncEvent } from '../../store/actions/event';
import DimmerLoader from '../DimmerLoader';
import _ from 'lodash';
import moment from 'moment';
import HeaderEventsList from './HeaderEventsList';
import { Link, withRouter } from 'react-router-dom';

import 'moment/locale/ru';
import './style.scss';
import './media.scss';

moment().locale('ru')

class EventsList extends Component {

  state = {
    max: false,
  }

  componentDidMount = async () => {
    this.props.setDefaultSearch();
    this.props.loadAsyncEvent();
    document.addEventListener('scroll', this.handleScroll, false);
    document.addEventListener('wheel', this.handleScroll, false);
  }


  componentWillUnmount() {
    this.props.setDefaultSearch();
    document.removeEventListener('scroll', this.handleScroll, false);
    document.removeEventListener('wheel', this.handleScroll, false);
  }

  formatedData = (date) => {
    if (date) {
      return (date.substring(8, 10) + ' ' + moment(date).locale('ru').format('MMMM').substring(0, 3) + ', ' + moment(date).format('H:mm'));
    } else {
      return null;
    }
  }

  handleScroll = async (e) => {
    const { events, searchParameters, preloader } = this.props;
    const scroll = document.documentElement || document.body;
    if (events.count > searchParameters.max && !preloader) {
      if ((scroll.scrollTop + scroll.clientHeight) + (scroll.scrollHeight / 100) > scroll.scrollHeight) {
        this.props.setPreloader(true);
        this.props.setMin(searchParameters.max + 1);
        this.props.setMax(searchParameters.max + 15);
        let data;
        data = await this.props.loadAsyncEvent();
        if (!data) {
          // this.props.setDefaultSearch();
          if (this.state.max) {
            this.props.setMin(this.state.max - 1);
            this.props.setMax(this.state.max - 15);
          }
        } else {
          this.setState({
            max: searchParameters.max
          })
        }
      }
    }
  }

  getPlaces = (id) => {
    const { placesList } = this.props;
    if (placesList.length && id) {
      let place = ((_.find(placesList, { id: id })));
      if (place) {
        return place.descriptions[0].name;
      }
    }
    return "";
  }

  getCities = (id) => {
    const { placesList, citiesList } = this.props;
    if (placesList.length && id && citiesList.length) {
      let id_places = (_.find(placesList, { id: id })).city_id;
      let city = (_.find(citiesList, { id: id_places }));
      if (city) {
        return city.descriptions[0].name;
      }
    }
    return "";
  }

  changeEvent = (id) => {
    localStorage.setItem('activeEvent', id);
    this.props.history.push(`/event/${id}/`);
  }

  render() {
    const { preloader, eventsList } = this.props;
    return (
      <>
        <div className="events-list">
          <div className="events-table">
            <HeaderEventsList />
            <div className="events-table__tbody">
              {
                eventsList.map(item => {
                  return (
                    <div key={item.id} onClick={() => this.changeEvent(item.id)} className="events-table__row">
                      <div className="events-table__col event-date">{this.formatedData(item.date_time_from)}</div>
                      <div className="events-table__col event-name">{item.descriptions[0].name}</div>
                      <div className="events-table__col event-place">{this.getPlaces(item.place_id)}</div>
                      <div className="events-table__col event-city">{this.getCities(item.place_id)}</div>
                      <div className="events-table__col event-status">{item.event_statuses[0].name}</div>
                      <span className="btn">Выбрать</span>
                    </div>)
                })
              }
            </div>
          </div>
          {preloader ?
            <div className="loading">
              <div className="loading-box">
                <span className="icon icon-spinner"></span>
              </div>
            </div> : null}
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  events: state.event,
  eventsList: state.event.events,
  searchParameters: state.event.searchParameters,
  preloader: state.event.preloader,
  placesList: state.places.list,
  citiesList: state.cities.list,
})

const mapDispatchToProps = {
  setEvents,
  setPreloader,
  setDefaultSearch,
  setMin,
  setMax,
  loadAsyncEvent
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventsList))
