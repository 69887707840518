import React, { Component } from 'react';
import './style.scss';
import './media.scss';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import moment from 'moment';
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment'
import DayPicker, { DateUtils } from 'react-day-picker';
import Moment from 'react-moment';
import Select from 'react-select';
import { connect } from 'react-redux';
import {
  setAsyncEvent,
  clearEvents
} from '../../store/actions/report';
import ReportsList from '../ReportsList/ReportsList';
import ReportsListCategories from '../ReportsList/ReportsListCategories';

class ReportsFilter extends Component {
  constructor(props) {
    super(props);

    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleToChange = this.handleToChange.bind(this);
    this.handleDayClick = this.handleDayClick.bind(this);

    this.state = {
      from: undefined,
      to: undefined,
      isPeriod: false,
      isShowDateModal: false,
      filterSearch: {
        place: null,
        event: '',
        city: null,
        dateFrom: null,
        dateTo: null,
        page: 2,
        date_step: 'day',
      },
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, false);
    this.props.clearEvents();
    this.props.setAsyncEvent({
      name_filter: this.state.filterSearch.event || null,
      venues: this.state.filterSearch.place || null,
      select_to: this.state.filterSearch.dateTo ?
        moment(this.state.filterSearch.dateTo).format(
          "YYYY-MM-DD"
        ) : null,
      select_from:
        this.state.filterSearch.dateFrom ?
          moment(this.state.filterSearch.dateFrom).format(
            "YYYY-MM-DD"
          ) : null,
      cities: this.state.filterSearch.city || null,
      // min: (this.state.filterSearch.page * 15) - 14,
      // max: this.state.filterSearch.page * 15,
      min: 1,
      max: 15,
    });
  }

  handleClickOutside = (e) => {
    let path = e.path;
    if (!e.path) {
      path = e.composedPath();
    }

    const dateModal = document.getElementsByClassName('event-order__search-date-title')[0];
    if (!path.includes(dateModal)) {
      const searchDateIcon = document.getElementsByClassName('event-order__search-date-modal')[0];
      if (!path.includes(searchDateIcon)) {
        this.setState({
          isShowDateModal: false
        })
      }
    }
  };

  handleFromChange = (from) => {
    this.setState({
      from
    })
  };

  handleToChange = (to) => {
    this.setState({
      to
    })
  };

  handleDayClick = day => {
    const range = DateUtils.addDayToRange(day, this.state.range1);
    const { filterSearch } = this.state;
    if (range.to === null) {
      this.setState((prevState) => ({
        filterSearch: {
          ...prevState.filterSearch,
          dateTo: null
        }
      }))
    }
    if (range.from === null) {
      this.setState((prevState) => ({
        filterSearch: {
          ...prevState.filterSearch,
          dateFrom: null,
        }
      }))
    }

    if (
      range.from &&
      moment(range.from).format("YYYY-MM-DD") !== filterSearch.dateFrom
    ) {
      range.from && (
        this.setState((prevState) => ({
          filterSearch: {
            ...prevState.filterSearch,
            dateFrom: range.from
          }
        }))
      )
      range.to && (
        this.setState((prevState) => ({
          filterSearch: {
            ...prevState.filterSearch,
            dateTo: range.from
          }
        }))
      )
    }

    if (
      range.to &&
      moment(range.to).format("YYYY-MM-DD") !== filterSearch.dateTo
    ) {
      if (String(range.to) !== String(range.from)) {
        this.setState((prevState) => ({
          filterSearch: {
            ...prevState.filterSearch,
            dateTo: range.to
          }
        }))
      }
    }

    if (
      range.from !== null &&
      range.to !== null &&
      String(range.to) === String(range.from)
    ) {
      this.setState((prevState) => ({
        filterSearch: {
          ...prevState.filterSearch,
          dateTo: range.from
        }
      }))
    }
    this.setState({ range1: range });
  };

  handlePeriod = (e) => {
    if (e.target.value === 'date') {
      this.setState({
        isPeriod: true
      })
    } else {
      this.setState({
        isPeriod: false
      })
    }
  };

  _onPressSearchEvent = () => {
    this.props.clearEvents();
    this.props.setAsyncEvent({
      name_filter: this.state.filterSearch.event || null,
      venues: this.state.filterSearch.place || null,
      select_to: this.state.filterSearch.dateTo ?
        moment(this.state.filterSearch.dateTo).format(
          "YYYY-MM-DD"
        ) : null,
      select_from:
        this.state.filterSearch.dateFrom ?
          moment(this.state.filterSearch.dateFrom).format(
            "YYYY-MM-DD"
          ) : null,
      cities: this.state.filterSearch.city || null,
      // min: (this.state.filterSearch.page * 15) - 14,
      // max: this.state.filterSearch.page * 15,
      min: 1,
      max: 15,
    });
  }

  showDateModal = () => {
    this.setState({
      isShowDateModal: true
    })
  };

  render() {
    const { from, to, isPeriod } = this.state;

    const modifier = { start: from, end: to };

    const { selectDate,
      selectTickets

    } = this.props;

    const modifiers = {
      start: this.state.filterSearch.dateFrom,
      end: this.state.filterSearch.dateTo
    };

    const dynamic = [
      {id: 'year', name: "За год"},
      {id: 'week', name: "За неделю"},
      {id: 'day', name: "За день"},
      {id: 'month', name: "За месяц"},
      {id: 'quarter', name: "За квартал"}
    ];
    return (
      <>
        <div className="reports-filter reports-filter_flex">
          {
            selectDate ? (
              <div className="reports-filter__period">
                <div className="reports-filter__period-list">
                  <ul>
                    <li>
                      <div className="checkbox">
                        <input id="period-1" type="radio" name="period" defaultValue="all" defaultChecked onChange={this.handlePeriod} />
                        <label htmlFor="period-1"><b>Полный</b> Отчет с начала продаж</label>
                      </div>
                    </li>
                    <li>
                      <div className="checkbox">
                        <input id="period-2" type="radio" name="period" defaultValue="date" onChange={this.handlePeriod} />
                        <label htmlFor="period-2"><b>Период времени</b> Выберите период</label>
                      </div>
                      {isPeriod &&
                        <div className="date-range">
                          <div className="date-range__from">
                            <DayPickerInput
                              value={from}
                              placeholder='DD mm, YYYY'
                              format="DD MMM, YYYY"
                              formatDate={formatDate}
                              parseDate={parseDate}
                              dayPickerProps={{
                                locale: 'ru',
                                localeUtils: MomentLocaleUtils,
                                selectedDays: [from, { from, to }],
                                disabledDays: { after: to },
                                toMonth: to,
                                modifiers: modifier,
                                numberOfMonths: 1,
                                showOutsideDays: true,
                                onDayClick: () => this.to.getInput().focus(),
                              }}
                              onDayChange={this.handleFromChange}
                            />
                            <span className="icon icon-calendar"></span>
                          </div>
                          <div className="date-range__separator">-</div>
                          <div className="date-range__to">
                            <DayPickerInput
                              ref={el => (this.to = el)}
                              value={to}
                              placeholder='DD mm, YYYY'
                              format="DD MMM, YYYY"
                              formatDate={formatDate}
                              parseDate={parseDate}
                              dayPickerProps={{
                                locale: 'ru',
                                localeUtils: MomentLocaleUtils,
                                showOutsideDays: true,
                                selectedDays: [from, { from, to }],
                                disabledDays: { before: from },
                                modifiers: modifier,
                                month: from,
                                fromMonth: from,
                                numberOfMonths: 1,
                              }}
                              onDayChange={this.handleToChange}
                            />
                            <span className="icon icon-calendar"></span>
                          </div>
                        </div>
                      }
                    </li>
                  </ul>

                </div>
              </div>
            ) : null
          }
          {
            selectTickets && (
              <div className="reports-filter__tickets">
                <div className="reports-filter__group">
                  <div className="reports-filter__title">Билеты TicketPro</div>
                  <div className="reports-filter__group-list">
                    <ul>
                      <li>
                        <div className="checkbox">
                          <input id="ticketpro-1" type="checkbox" name="ticketpro" />
                          <label htmlFor="ticketpro-1">Продажи</label>
                        </div>
                      </li>
                      <li>
                        <div className="checkbox">
                          <input id="ticketpro-2" type="checkbox" name="ticketpro" />
                          <label htmlFor="ticketpro-2">Изготов. с ценой</label>
                        </div>
                      </li>
                      <li>
                        <div className="checkbox">
                          <input id="ticketpro-3" type="checkbox" name="ticketpro" />
                          <label htmlFor="ticketpro-3">Изготов. с ценой 0,00 руб.</label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="reports-filter__group">
                  <div className="reports-filter__title">Билеты организатора</div>
                  <div className="reports-filter__group-list">
                    <ul>
                      <li>
                        <div className="checkbox">
                          <input id="organizer-1" type="checkbox" name="organizer" />
                          <label htmlFor="organizer-1">Продажи</label>
                        </div>
                      </li>
                      <li>
                        <div className="checkbox">
                          <input id="organizer-2" type="checkbox" name="organizer" />
                          <label htmlFor="organizer-2">Изготов. с ценой</label>
                        </div>
                      </li>
                      <li>
                        <div className="checkbox">
                          <input id="organizer-3" type="checkbox" name="organizer" />
                          <label htmlFor="organizer-3">Изготов. с ценой 0,00 руб.</label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )
          }
        </div>

        {
          this.props.date_step && (
            <>
            <div className="reports-filter-line"></div>
            <div className="reports-filter">
              <div className="reports-filter__container">
                <div className="reports-filter__search-field reports-filter__search-field-full">
                  <Select
                    classNamePrefix="select"
                    placeholder="Отчет"
                    noOptionsMessage={() => "Нет данных"}
                    options={dynamic}
                    isSearchable={true}
                    isDisabled={false}
                    value={this.state.filterSearch.date_step ? dynamic.filter(item => item.id === this.state.filterSearch.date_step)[0] : null}
                    getOptionLabel={(option) => {
                      return option.name
                    }}
                    getOptionValue={(item) => item.id}
                    // defaultValue={/}
                    onChange={(e) => {
                      this.setState({
                        filterSearch: {
                          ...this.state.filterSearch,
                          date_step: e.id
                        }
                      })
                      return e
                    }}
                  />
                </div>
                </div>

                </div>
</>
          )
        }


        {this.props.eventsShow && (
          <>
            <div className="reports-filter-line"></div>
            <div className="reports-filter">
              <div className="reports-filter__container">
                <div className="reports-filter__search-field">
                  <Select
                    classNamePrefix="select"
                    placeholder="Город"
                    noOptionsMessage={() => "Нет данных"}
                    options={this.props.citiesList}
                    isSearchable={true}
                    isDisabled={false}
                    value={this.state.filterSearch.city ? this.props.citiesList.filter(item => item.id === this.state.filterSearch.city)[0] : null}
                    getOptionLabel={(option) => {
                      return option.descriptions[0].name
                    }}
                    getOptionValue={(item) => item.id}
                    onChange={(e) => {
                      this.setState({
                        filterSearch: {
                          ...this.state.filterSearch,
                          city: e.id
                        }
                      })
                      return e
                    }}
                  />

                </div>
                <div className="reports-filter__search-field">
                  <input
                    value={this.state.filterSearch.event}
                    onChange={(e) => {
                      this.setState({
                        filterSearch: {
                          ...this.state.filterSearch,
                          event: e.target.value
                        }
                      })
                    }}
                    type="text"
                    placeholder="Названия" />
                </div>
              </div>
              <div className="reports-filter__container">
                <div className={`reports-filter__search-field event-order__search-date ${this.state.isShowDateModal ? 'show' : ''}`}>
                  <div className="event-order__search-date-title" onClick={this.showDateModal}>
                    <span className="icon icon-calendar"></span>
                    {this.state.filterSearch.dateFrom && this.state.filterSearch.dateTo ?
                      (this.state.filterSearch.dateFrom.toLocaleDateString() == this.state.filterSearch.dateTo.toLocaleDateString()) ?
                        <span className="event-order__search-date-value"><Moment format="DD MMM YYYY" locale="ru">{this.state.filterSearch.dateFrom.toString()}</Moment></span>
                        : <span className="event-order__search-date-value"><Moment format="DD MMM YYYY" locale="ru">{this.state.filterSearch.dateFrom.toString()}</Moment> - <Moment format="DD MMM YYYY" locale="ru">{this.state.filterSearch.dateTo.toString()}</Moment></span>
                      : 'Виберите дату'
                    }
                  </div>
                  <div className="event-order__search-date-modal">
                    <DayPicker
                      localeUtils={MomentLocaleUtils}
                      locale="ru"
                      selectedDays={[
                        this.state.filterSearch.dateFrom
                        , {
                          from: this.state.filterSearch.dateFrom,
                          to: this.state.filterSearch.dateTo,
                        }]}
                      modifiers={modifiers}
                      onDayClick={this.handleDayClick}
                      showOutsideDays
                    />
                  </div>
                </div>

                <div className="reports-filter__search-field event-orders__filter-select">
                  <Select
                    classNamePrefix="select"
                    placeholder="Площадка"
                    noOptionsMessage={() => "Нет данных"}
                    options={this.props.placesList}
                    isSearchable={true}
                    isDisabled={false}
                    value={this.state.filterSearch.place ? this.props.placesList.filter(item => item.id === this.state.filterSearch.place)[0] : null}
                    getOptionLabel={(option) => {
                      return option.descriptions[0].name
                    }}
                    getOptionValue={(item) => item.id}
                    onChange={(e) => {
                      this.setState({
                        filterSearch: {
                          ...this.state.filterSearch,
                          place: e.id
                        }
                      })
                      return e
                    }}

                  />
                </div>
              </div>

              <div className="reports-filter__container">
                <div className="form-button">
                  <button
                    onClick={this._onPressSearchEvent}
                    className="btn btn-sm">Найти</button>
                </div>
                <div className="form-button">
                  <button
                    onClick={() => {
                      this.setState({
                        filterSearch: {
                          place: null,
                          event: '',
                          city: null,
                          dateFrom: null,
                          dateTo: null,
                        },
                      })
                    }}
                    className="btn btn-link btn-sm"><span className="icon icon-close"></span>Очистить</button>
                </div>
              </div>
            </div>
          </>
        )}
        {
          this.props.eventsShow && (
            <ReportsList
              dateFrom={isPeriod ? moment(from).format('YYYY-MM-DD HH-mm-ss') : moment(new Date).subtract(1730, 'd').format("YYYY-MM-DD") + ' ' + moment().format('HH:mm:ss')}
              dateTo={isPeriod ? to ? moment(to).format('YYYY-MM-DD HH-mm-ss') : moment(new Date).format('YYYY-MM-DD HH-mm-ss') : moment(new Date).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm:ss')}
              history={this.props.history}
              search={this.props.search}
              typeSearch={this.props.typeSearch}
              date_step={this.state.filterSearch.date_step}
            />
          )}
        {
          this.props.selectCategories && <ReportsListCategories
            dateFrom={isPeriod ? moment(from).format('YYYY-MM-DD HH-mm-ss') : moment(new Date).subtract(1730, 'd').format("YYYY-MM-DD") + ' ' + moment().format('HH:mm:ss')}
            dateTo={isPeriod ? to ? moment(to).format('YYYY-MM-DD HH-mm-ss') : moment(new Date).format('YYYY-MM-DD HH-mm-ss') : moment(new Date).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm:ss')}
            history={this.props.history}
            search={this.props.search}
            typeSearch={this.props.typeSearch}
          />
        }
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    placesList: state.places.list,
    citiesList: state.cities.list,
  }
}

const mapDispatchToProps = {
  setAsyncEvent,
  clearEvents
}

export default (connect(mapStateToProps, mapDispatchToProps)(ReportsFilter))
