import React, { Component } from 'react';
import './style.scss';
import './media.scss';
class OrderBasket extends Component {
  render() {
    return (
      <>
        <div className="order-basket">
          <div className="order-basket__list">
            <div className="order-basket__row">
              <div className="order-basket__col">Итого мероприятий</div>
              <div className="order-basket__col"><b>1</b></div>
            </div>
            <div className="order-basket__row">
              <div className="order-basket__col">На сумму</div>
              <div className="order-basket__col">
                <span className="price">{!this.props.isShowOrderBasketZero ? this.props.coast : '0.00'} BYN</span>
              </div>
            </div>
            {!this.props.isShowOrderBasketZero &&
              <div className="order-basket__row">
                <div className="order-basket__col">Скидка</div>
                <div className="order-basket__col"><b>{this.props.coastPromocodes} BYN</b></div>
              </div>
            }
          </div>
          <div className="form-button">
            <button
              className="btn"
              onClick={this.props.showOrderStep2}
              disabled={!this.props.orderType || !this.props.listIdTickets.length}
            >Оформить заказ</button>
          </div>
        </div>
      </>
    )
  }
}

export default OrderBasket;
