import React, { Component } from 'react';
import './style.scss';
import './media.scss';

class Pagination extends Component {
  render() {
    const { currentPage = 1, maxPage = 3, onPress } = this.props;
    let maxPages = [];
    if (currentPage === 1 && maxPage >= currentPage) {
      maxPages.push(currentPage)
      currentPage + 1 <= maxPage && maxPages.push(currentPage + 1)
      currentPage + 2 <= maxPage && maxPages.push(currentPage + 2)
    } else if (currentPage !== 1 && maxPage >= currentPage) {
      maxPages.push(currentPage - 1)
      maxPages.push(currentPage)
      currentPage + 1 <= maxPage ? maxPages.push(currentPage + 1) : currentPage - 2 > 0 && maxPages.unshift(currentPage - 2)
    }
    if (maxPage === 1) {
      return null;
    }
    return (
      <>
        <div className="pagination">
          <ul>
            {
              currentPage !== 1 ? (
                <li onClick={() => { onPress(((currentPage - 1) * 15) - 14, (currentPage - 1) * 15) }} className="page-prev">
                  <a onClick={e => e.preventDefault()} href=""><span className="icon icon-dd"></span></a></li>
              ) : null
            }

            {
              maxPages.map(item => {
                return (
                  <li onClick={() => onPress(((item * 15) - 14), item * 15)}><a onClick={e => e.preventDefault()} href="" className={item === currentPage && 'active'}>{item}</a></li>
                )
              })
            }
            {
              currentPage !== maxPage ? (
                <li onClick={() => { onPress(((currentPage + 1) * 15) - 14, (currentPage + 1) * 15) }} className="page-next"><a onClick={e => e.preventDefault()} href=""><span className="icon icon-dd"></span></a></li>
              ) : null
            }
          </ul>
        </div>
      </>
    )
  }
}

export default Pagination;
