import { ORDERS } from '../const'
import _ from 'lodash'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const filterParams = {
    date: {
        from: null,
        to: null,
    },
    searchInput: '',
    selectorTypes: null,
    multiSelectorTypes: [],
    id_event: null
}
const initialState = {
    searchParameters: {
        min: 1,
        max: 15,
        id_event: false,
    },
    filter: filterParams,
    currentFilter: filterParams,
    currentPage: 1,
    orders: [],
    count: 0,
    buttonEvents: 0,
    preloader: false,
    sort: {
        type: false,
    },
    loaded: true,
    searchIdEvent: false,
    currentOrder: {
        data: {
            tickets: [],
        },
        loader: false,
        error: false,
    },
};

export default persistReducer(
    {
        storage,
        key: 'contact',
        blacklist: ['preloader', 'buttonEvents', 'count', 'currentPage', 'searchParameters', 'filter', 'sort', 'currentOrder'],
        whitelist: ['totalEvent', 'totalEventId']
    },
    (state = initialState, action) => {
        switch (action.type) {
            case ORDERS.SET_CURRENT_ORDER_LOADER:
                return Object.assign({}, state, {
                    currentOrder: {
                        data: {},
                        error: false,
                        loader: true,
                    }
                });
            case ORDERS.SET_CURRENT_ORDER:
                return Object.assign({}, state, {
                    currentOrder: {
                        data: action.payload,
                        error: false,
                        loader: false,
                    }
                });
            case ORDERS.SET_CURRENT_ORDER_ERROR:
                return Object.assign({}, state, {
                    currentOrder: {
                        data: {},
                        error: true,
                        loader: false,
                    }
                });
            case ORDERS.CURRENT_ORDERS:
                return Object.assign({}, state, { totalEvent: action.event, totalEventId: action.id });
            case ORDERS.SET_SEARCH_FILTER:
                return Object.assign({}, state, { currentFilter: state.filter });
            case ORDERS.PRELOADER:
                return Object.assign({}, state, { preloader: action.preloader });
            case ORDERS.BUTTONS:
                return Object.assign({}, state, {
                    buttonEvents: action.value, searchParameters: {
                        ...state.searchInput,
                        min: action.min,
                        max: action.max,
                    }
                });
            case ORDERS.CURRENT_PAGE:
                return Object.assign({}, state, { currentPage: action.page });
            case ORDERS.SET_CLEAR_FILTER:
                return Object.assign({}, state, { filter: initialState.filter });
            case ORDERS.SET_FILTER_MULTI_TYPES:
                return Object.assign({}, state, {
                    filter: {
                        ...state.filter,
                        multiSelectorTypes: action.payload
                    }
                });
            case ORDERS.SET_FILTER_TYPES:
                return Object.assign({}, state, {
                    filter: {
                        ...state.filter,
                        selectorTypes: action.payload
                    }
                });
            case ORDERS.ADD_ORDERS:
                return Object.assign({}, state, { orders: [...state.orders, ...action.orders], loaded: action.loaded });
            case ORDERS.DEFAULT_SEARCH:
                return Object.assign({}, state, initialState);
            case ORDERS.SET_MIN:
                return Object.assign({}, state, {
                    currentPage: state.currentPage,
                    searchParameters: {
                        ...state.searchInput,
                        min: action.value,
                        max: state.searchParameters.max,
                    }
                });
            case ORDERS.SET_MAX:
                return Object.assign({}, state, {
                    currentPage: state.currentPage,
                    searchParameters: {
                        ...state.searchInput,
                        min: state.searchParameters.min,
                        max: action.value,
                    }
                });
            case ORDERS.SET_SEARCH_INPUT:
                return Object.assign({}, state, {
                    currentPage: state.currentPage,
                    filter: {
                        ...state.filter,
                        searchInput: action.payload,
                    }
                });

            case ORDERS.ADD_EVENT: 
            return Object.assign({}, state, {
                currentPage: state.currentPage,
                orders: [
                    action.payload,
                    ...state.orders
                ]
            });
            case ORDERS.SET_SEARCH_DATE:
                return Object.assign({}, state, {
                    currentPage: state.currentPage,
                    filter: {
                        ...state.filter,
                        date: {
                            ...state.filter.data,
                            ...action.payload
                        },
                    }
                });
            case ORDERS.SET_ORDERS_SORT:
                return Object.assign({}, state,
                    {
                        orders: eventSortBy(state.orders, action.payload, action.payloads)
                    }
                );
            case ORDERS.SET_ORDERS_SORT_ID:
                return Object.assign({}, state,
                    {
                        currentOrder: {
                            ...state.currentOrder,
                            data: {
                                ...state.currentOrder.data,
                                tickets: eventSortBy(state.currentOrder.data.tickets, action.payload),
                            }
                        }
                    }
                );
            case ORDERS.SET_ID_FIND:
                return Object.assign({}, state,
                    {
                        searchIdEvent: action.payload
                    }
                );
            case ORDERS.SET_SEARCH_ID_EVENT:
                return Object.assign({}, state,
                    {
                        filter: {
                            ...state.filter,
                            id_event: action.payload,
                        }
                    }
                );
            default:
                return state;
        }
    }
)

const eventSortBy = (orders, sort, state) => {
    let sortEvents = orders
    if (sort.status) {
        if (sort.type === 'date_change') {
            sortEvents = _.orderBy(orders, ['status_id', 'changed'], [sort.status]);
        }
        else if (sort.type === 'date') {
            sortEvents = _.orderBy(orders, ['inserted'], [sort.status]);
        }
        else if (sort.type === 'status') {
            sortEvents = _.orderBy(orders, ['status[0].name'], [sort.status]);
        }
        else if (sort.type === 'id') {
            sortEvents = _.orderBy(orders, ['id'], [sort.status]);
        }
        else if (sort.type === 'status') {
            sortEvents = _.orderBy(orders, ['event_statuses[0].name'], [sort.status]);
        }
        else if (sort.type === 'ticket') {
            sortEvents = _.orderBy(orders, (e) => e.tickets.length, [sort.status]);
        }
        else if (sort.type === 'pay') {
            sortEvents = _.orderBy(orders, ['ident_type.descriptions[0].name'], [sort.status]);
        }
        else if (sort.type === 'ticket_status') {
            sortEvents = _.orderBy(orders, ['tickets[0].status[0].name', 'status_id'], [sort.status]);
        }
        else if (sort.type === 'address') {
            sortEvents = _.orderBy(orders, ['ticket_name',], [sort.status]);
        }
        else if (sort.type === 'venue') {
            sortEvents = _.orderBy(orders, ['event_place.descriptions[0].name',], [sort.status]);
        }
        else if (sort.type === 'name') {
            sortEvents = _.orderBy(orders, ['event_names[0].name',], [sort.status]);
        }
        else if (sort.type === 'coast') {
            sortEvents = _.orderBy(orders, ['coast',], [sort.status]);
        }
        // else if (
        //     sort.type === 'city'
        // ) {
        //     sortEvents = _.orderBy(orders, (e) => {
        //         return getCities(e.place_id, state.places.list, state.cities.list)
        //     }, [sort.status]);

        // } 
        // else if (
        //     sort.type === 'place_id'
        // ) {
        //     sortEvents = _.orderBy(orders, (e) => {
        //         return getPlaces(e.place_id, state.places.list)
        //     }, [sort.status]);

        // }
        //  else {
        //     sortEvents = _.orderBy(orders, [sort.type], [sort.status]);
        // }
    }
    return sortEvents
}

const getDeletedTickets = (id, placesList) => {
    if (placesList.length && id) {
        let place = ((_.find(placesList, { id: id })));
        if (place) {
            return place.descriptions[0].name;
        }
    }
    return "";
}


const getPlaces = (id, placesList) => {
    if (placesList.length && id) {
        let place = ((_.find(placesList, { id: id })));
        if (place) {
            return place.descriptions[0].name;
        }
    }
    return "";
}

const getCities = (id, placesList, citiesList) => {
    if (placesList.length && id && citiesList.length) {
        let id_places = (_.find(placesList, { id: id })).city_id;
        let city = (_.find(citiesList, { id: id_places }));
        if (city) {
            return city.descriptions[0].name;
        }
    }
    return "";
}
