import store from '../../store/store';
import { AUTH } from '../../store/const'

export default class Exit {
    static exit() {

      store.dispatch({
        type: AUTH.LOGOUT,
      });

      store.dispatch({
        type: AUTH.LOADING,
        payload: false,
      });
      localStorage.removeItem('ssoclid');
    }
  }
