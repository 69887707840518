import React, {Component} from 'react';
import './style.scss';
import './media.scss';
import LayoutTicket from '../LayoutTicket/LayoutTicket';

class OrderLayoutTicket extends Component{
  render() {
    return (
      <>
        <div className="order-layout-ticket">
          <div className="modal-title">Выберите макет билета</div>
          <LayoutTicket changeTempls={this.props.changeTempls} typesTemplsList={this.props.typesTemplsList} />
          <div className="form-button">
            <button className="btn" onClick={this.props.orderCreateManufacturing}>Применить</button>
          </div>
        </div>
      </>
    )
  }
}

export default OrderLayoutTicket;
