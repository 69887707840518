import React, { Component } from "react";
import "./style.scss";
import "./media.scss";
import EventBox from "../../components/EventBox/EventBox";
import EventPanelStatistics from "../EventPanelStatistics/EventPanelStatistics";
import EventMenu from "../../components/EventMenu/EventMenu";
import EventPlanReserveTickets from "../../components/EventPlanReserveTickets/EventPlanReserveTickets";
import Reserve from "../../components/Reserve/Reserve";
import Scheme from "../../components/Sheme";
import _ from "lodash";
import { getCancelablePromise } from "../../helpers/index";
import {
  getSeatLocksList,
  seatLockReserve,
  seatUnlockReserve
} from "../../api/reserve";
import moment from "moment";
import Spinner from "../../components/Spinner";
import { setTotalEvent } from "../../store/actions/event";
import { connect } from "react-redux";
import { Link } from 'react-router-dom'
import { getSellerSvg } from "../../api/scheme";
import { formatedData } from '../../helpers/index';

class EventReserve extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowModalReserve: false,
      listIdTickets: [],
      event: [],
      listLocks: [],
      seatLock: false,
      loader: true,
      loaderModal: false,
      legendScheme: {
        name: [],
        price: null
      },
      error: false,
    };
  }

  componentDidMount() {
    this.loadedEvent();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isShowModalReserve === true &&
      this.state.isShowModalReserve === false &&
      prevState.isShowModalReserve !== this.props.isShowModalReserve) {
      this.setState({
        error: false,
      })
    }
  }

  loadedEvent = () => {
    let { match, totalEvent, idEvent } = this.props;

    if (totalEvent.event && Number(match.params.eventID) === Number(idEvent)) {
      this.setState({
        event: totalEvent
      });
      getSellerSvg(match.params.eventID).then(res => {
        if (res && res.data) {
          totalEvent.schema.path = res.data.path;
          this.setState({
            event: totalEvent,
            loader: false
          });
        }
      });
    } else {
      this.props.setTotalEvent(match.params.eventID).then(res => {
        if (res) {
          this.setState({
            event: res,
            loader: false
          });
        }
      });
    }

    const seatLocks = getCancelablePromise(getSeatLocksList());
    Promise.all([seatLocks.promise]).then(data => {
      if (data[0].status === 200) {
        data[0].data.push({ id: 0, name: "Разблокировать", color: "#fff" });
        this.setState({
          listLocks: data[0].data
        });
      }
    });
  };

  deleteTicket = id => {
    const { listIdTickets } = this.state;
    const filterId = id;
    let list = listIdTickets;
    document.querySelectorAll(`[id="${id}"]`).forEach(el => {
      let elemSelector = el.outerHTML.match(/^<(circle|rect)/)[1];
      if (elemSelector === "rect") {
        let arrayRect = _.filter(listIdTickets, { id: id });
        let newArray = arrayRect.slice(0, arrayRect.length - 1);
        let arrayCircle = _.filter(listIdTickets, function (o) {
          return o.id !== id;
        });
        this.setState({ listIdTickets: arrayCircle.concat(newArray) }, () => {
          if (!_.filter(this.state.listIdTickets, { id: filterId }).length) {
            el.removeAttribute("fill");
          }
        });
        let gsc_fl = el.getAttribute("gsc_fl");
        el.setAttribute("gsc_fl", ++gsc_fl);
      }
      if (elemSelector === "circle") {
        list = list.filter(itemID => itemID.id !== id);
        this.setState({ listIdTickets: list });
        el.removeAttribute("fill");
      }
    });
  };

  showModalReserve = () => {
    this.setState({
      isShowModalReserve: true
    });
  };

  hideModalReserve = () => {
    this.setState({
      isShowModalReserve: false,
      seatLock: false
    });
  };

  reserveTicket = (e, onlyBuy = false) => {
    let id = Number(e.target.id);
    const { listIdTickets } = this.state;
    let list = listIdTickets;
    let priceID = parseInt(e.target.parentNode.getAttribute("price_id"));
    let fill = e.target.parentNode.getAttribute("fill");
    let nameTicket = e.target.getAttribute("name");
    let gsc_fl = e.target.getAttribute("gsc_fl");
    if (e.target.id && id && fill !== "#999999") {
      if (!_.some(listIdTickets, { id: id }) || e.target.tagName === "rect") {
        if (
          (e.target.tagName === "rect" && gsc_fl !== null && gsc_fl > 0) ||
          (e.target.tagName !== "rect" && e.target.getAttribute("r") !== "3")
        ) {
          if (e.target.tagName === "rect") {
            e.target.setAttribute("gsc_fl", --gsc_fl);
          }
          e.target.setAttribute("fill", "black");
          let coast = this.state.event.prices.filter(item => item.id === priceID)

          list.push({
            id: id,
            name: nameTicket,
            price: coast.length && coast[0].price,
            priceID: priceID,
            fill: fill
          });
          this.setState({
            listIdTickets: list
          });
        }
      } else {
        // delete ticket
        if(!onlyBuy) {
          list = list.filter(idList => idList.id !== id);
          e.target.removeAttribute("fill");
          this.setState({
            listIdTickets: list
          });
        }
      }
    }
  };

  clearTickets = (clear = false) => {
    if(clear) {
    this.state.listIdTickets.map(item => {
      this.deleteTicket(item.id)
    })
  }
    this.setState({
      listIdTickets: []
    });
  };

  changeSeatLock = id => {
    this.setState({
      seatLock: id
    });
  };

  createReserveSeats = async () => {
    const { listIdTickets, seatLock } = this.state;
    let tickets_and_counts = [];
    if (seatLock) {
      this.setState({
        loaderModal: true,
        error: false,
      });
      listIdTickets.forEach(item => item.id && tickets_and_counts.push(item.id + "/1"));
      const seatLocks = getCancelablePromise(
        seatLockReserve(tickets_and_counts.join(", "), seatLock)
      );

      Promise.all([seatLocks.promise])
        .then(data => {
          let color = _.filter(this.state.listLocks, { id: seatLock })[0].color;
          if (data[0] && data[0].status === 200) {
            listIdTickets.forEach(item => {
              let tags = document.querySelectorAll(`[id="${item.id}"]`);
              Array.from(tags).forEach(function (el) {
                el.setAttribute("fill", String(color));
              });
              this.setState({
                listIdTickets: [],
                seatLock: false
              });
              this.hideModalReserve();
            });
          } else {
            this.setState({
              loaderModal: false
            });
          }
        })
        .then(() => {
          this.setState({
            loaderModal: false
          });
        })
        .catch(er => {
          this.setState({
            loaderModal: false,
            error: er.response.data.text || er.response.data.error
          });
        });
    }
    if (seatLock === 0) {
      listIdTickets.forEach(item => {
        if (item.fill !== "#FFFFFF" || true) {
          tickets_and_counts.push(item.id);
        }
      });
      if (tickets_and_counts.length) {
        this.setState({
          loaderModal: true
        });
        const unSeatLocks = getCancelablePromise(
          seatUnlockReserve(tickets_and_counts.join(","), seatLock)
        );
        Promise.all([unSeatLocks.promise])
          .then(data => {
            if (data[0] && data[0].status === 200) {
              listIdTickets.forEach(item => {
                let tags = document.querySelectorAll(`[id="${item.id}"]`);
                Array.from(tags).forEach(function (el) {
                  el.setAttribute("fill", String("#fff"));
                });
                this.setState({
                  listIdTickets: [],
                  seatLock: false
                });
                this.hideModalReserve();
              });
            }
            this.setState({
              loaderModal: false
            });
          })
          .catch(er => {
            this.setState({
              loaderModal: false,
              error: er.response.data.text || er.response.data.error
            });
          });
      }
    }
  };

  render() {
    const { isShowModalReserve, listLocks, event } = this.state;

    return (
      <>
        <main className="main">
          <div className="event-panel">
            <div className="event-panel__head">
              <div className="event-panel__head-left">
                <Link className="event-back" to="/">
                  <span className="icon icon-arr-left"></span>
                </Link>
                {event.event ? (
                  <EventBox
                    date={formatedData(event.event.date_time_from)}
                    time={moment(event.event.date_time_from).format("H:mm")}
                    name={event.event.descriptions[0].name}
                    location={event.venue.descriptions[0].name}
                  />
                ) : (
                    <Spinner />
                  )}
              </div>
              <div className="event-panel__head-right">
                <EventPanelStatistics />
              </div>
            </div>
            <EventMenu history={this.props.history} currentMenu={2} />
          </div>
          <div className="event">
            <div className="event-map">
              {this.state.loader === false ? (
                <Scheme
                  match={this.props.match}
                  history={this.props.history}
                  prices={this.state.event.prices}
                  planList={this.props.planList}
                  event={this.state.event}
                  buyTicket={this.reserveTicket}
                  planTickets={
                    <EventPlanReserveTickets
                    clearTickets={() => this.clearTickets(true)}

                      planList={this.props.planList}
                      prices={this.state.event.prices}
                      listIdTickets={this.state.listIdTickets}
                      deleteTicket={this.deleteTicket}
                      showReserve={() => {
                        this.showModalReserve();
                      }}
                    />
                  }
                />
              ) : (
                  <Spinner />
                )}
            </div>
          </div>
        </main>
        {isShowModalReserve && (
          <Reserve
            createReserveSeats={this.createReserveSeats}
            changeSeatLock={this.changeSeatLock}
            seatLock={this.state.seatLock}
            listLocks={listLocks}
            hideReserve={() => this.hideModalReserve()}
            loaderModal={this.state.loaderModal}
            error={this.state.error}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  totalEvent: state.event.totalEvent,
  idEvent: state.event.totalEventId,
  planList: state.seatScheme.list
});

const mapDispatchToProps = {
  setTotalEvent
};

export default connect(mapStateToProps, mapDispatchToProps)(EventReserve);
