import React, {Component} from 'react';
import './style.scss';
import './media.scss';

class OrderPaymentBasket extends Component{
  render() {
    const { coast, coastPromocodes, listIdTickets, deals } = this.props;
    return (
      <>
        <div className="order-payment-basket">
          <div className="order-payment-basket__bg">
            <span className="icon icon-dual-card"></span>
          </div>
          <div className="order-payment-basket__box">
            <div className="modal-heading">Оплата</div>
            <div className="order-payment-basket__list">
              <div className="order-payment-basket__row">
                <div className="order-payment-basket__col">Итого мероприятий</div>
                <div className="order-payment-basket__col"><b>1 шт</b></div>
              </div>
              <div className="order-payment-basket__row">
                <div className="order-payment-basket__col">На сумму</div>
                <div className="order-payment-basket__col"><span className="price">{coast} BYN</span></div>
              </div>
              <div className="order-payment-basket__row">
                <div className="order-payment-basket__col">Скидка</div>
                <div className="order-payment-basket__col"><b>{coastPromocodes} BYN</b></div>
              </div>
            </div>
          </div>
          <div className="order-payment-basket__box">
            <div className="modal-title">Оплачено:</div>
            <div className="order-payment-basket__list">
              <div className="order-payment-basket__row">
                <div className="order-payment-basket__col">Наличные:</div>
                <div className="order-payment-basket__col"><b>00,00 BYN</b></div>
              </div>
              <div className="order-payment-basket__row">
                <div className="order-payment-basket__col">Банковская карта:</div>
                <div className="order-payment-basket__col"><b>0,00 BYN</b></div>
              </div>
              <div className="order-payment-basket__row">
                <div className="order-payment-basket__col">Подарочный сертификат:</div>
                <div className="order-payment-basket__col"><b>{deals.coast_sertificate} BYN</b></div>
              </div>
              <div className="order-payment-basket__row">
                <div className="order-payment-basket__col">Бонусные баллы:</div>
                <div className="order-payment-basket__col"><b>{deals.coast_cods} BYN</b></div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default OrderPaymentBasket;
