import { CITIES } from '../const';

const initialState = {
    list: [],
};

export default function cities(state = initialState, action) {

    switch (action.type) {
        case CITIES.SET:
            return Object.assign({}, state, { list: action.payload });
        default:
            return state;
    }
}