import React, { Component } from 'react';
import './style.scss';
import './media.scss';
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import PerfectScrollbar from "react-perfect-scrollbar";

class OrderCashless extends Component {
  state = {
    loader: false,
    error: false,
  }
  render() {
    const validateForm = Yup.object().shape({
      name: Yup.string()
        .required('Обязательное поле')
        .nullable(),
      unp: Yup.string()
        .required('Обязательное поле')
        .nullable(),
      account: Yup.string()
        .required('Обязательное поле')
        .nullable(),
      bank_code: Yup.string()
        .required('Обязательное поле')
        .nullable(),
      legal_address: Yup.string()
        .required('Обязательное поле')
        .nullable(),
      email: Yup.string()
        .required('Обязательное поле')
        .nullable(),
      signatory_position: Yup.string()
        .required('Обязательное поле')
        .nullable(),
      signatory_fio: Yup.string()
        .required('Обязательное поле')
        .nullable(),
      short_name: Yup.string()
        .required('Обязательное поле')
        .nullable(),
      mailing_address: Yup.string()
        .required('Обязательное поле')
        .nullable(),
    })
    return (
      <>
        <div className="order-cashless">
          <div className="modal-title">Заказ для безналичной оплаты</div>
          <Formik
            validationSchema={validateForm}
            onSubmit={async (values, formik) => {
              this.setState({
                loader: true,
                error: false,
              })
              await this.props.orderCreateCashless(values)
                .catch(er => {
                  this.setState({
                    error: er.response.data.text,
                    loader: false,
                  })
                })
            }}
            initialValues={{
              is_salecashless: true,
              lang: 'ru',
              legal_eq_mailing_address: false,
              email: '',
              fax: '',
              phone: '',
              signatory_authority: '',
              signatory_position: '',
              signatory_fio: '',
              bank_code: '',
              mailing_address: '',
              legal_address: '',
              short_name: '',
              name: '',
              unp: '',
              account: '',
            }}
            render={({
              values,
              errors,
              setFieldValue,
              handleSubmit,
              isSubmitting,
              touched
              /* and other goodies */
            }) => (
                <Form
                  className="form-control-content-contacts"
                  onSubmit={handleSubmit}
                >
                  <PerfectScrollbar
                    options={{
                      wheelSpeed: 0.3,
                      wheelPropagation: false
                    }}
                  >
                    <div className="form-scroll">
                      <div className="form-group form-flex">
                        <label>Учётный номер плательщика (9-цифр)</label>
                        <div className="form-field">
                          <input
                            className={(touched.unp && errors.unp) ? 'form-field-error' : ''}
                            onChange={(e) => setFieldValue('unp', e.target.value)}
                            disabled={this.state.loader}
                            value={values.unp}
                            type="text" />
                        </div>
                      </div>
                      <div className="form-group form-flex">
                        <label>Наименование плательщика</label>
                        <div className="form-field">
                          <input
                            className={(touched.name && errors.name) ? 'form-field-error' : ''}
                            onChange={(e) => setFieldValue('name', e.target.value)}
                            value={values.name}
                            disabled={this.state.loader}
                            type="text" />
                        </div>
                      </div>
                      <div className="form-group form-flex">
                        <label>Сокращённое наименование плательщика</label>
                        <div className="form-field">
                          <input
                            className={(touched.short_name && errors.short_name) ? 'form-field-error' : ''}
                            onChange={(e) => setFieldValue('short_name', e.target.value)}
                            disabled={this.state.loader}
                            value={values.short_name}
                            type="text" />
                        </div>
                      </div>
                      <div className="form-group form-flex">
                        <label>Адрес регистрации</label>
                        <div className="form-field">
                          <input
                            className={(touched.legal_address && errors.legal_address) ? 'form-field-error' : ''}
                            onChange={(e) => setFieldValue('legal_address', e.target.value)}
                            disabled={this.state.loader}
                            value={values.legal_address}
                            type="text" />
                        </div>
                      </div>



                      <div className="form-group form-flex">
                        <label>Почтовый адрес юридический</label>
                        <div className="form-field">
                          <input
                            className={(touched.mailing_address && errors.mailing_address) ? 'form-field-error' : ''}
                            onChange={(e) => setFieldValue('mailing_address', e.target.value)}
                            disabled={this.state.loader}
                            value={values.mailing_address}
                            type="text" />
                        </div>
                      </div>
                      <div className="form-group form-flex">
                        <label>Расчётный счёт</label>
                        <div className="form-field">
                          <input
                            className={(touched.account && errors.account) ? 'form-field-error' : ''}
                            onChange={(e) => setFieldValue('account', e.target.value)}
                            disabled={this.state.loader}
                            value={values.account}
                            type="text" />
                        </div>
                      </div>
                      <div className="form-group form-flex">
                        <label>Код банка в котором открыт расчётный счёт</label>
                        <div className="form-field">
                          <input
                            className={(touched.bank_code && errors.bank_code) ? 'form-field-error' : ''}
                            onChange={(e) => setFieldValue('bank_code', e.target.value)}
                            disabled={this.state.loader}
                            value={values.bank_code}
                            type="text" />
                        </div>
                      </div>
                      <div className="form-group form-flex">
                        <label>ФИО лица, подписывающего договор</label>
                        <div className="form-field">
                          <input
                            className={(touched.signatory_fio && errors.signatory_fio) ? 'form-field-error' : ''}
                            onChange={(e) => setFieldValue('signatory_fio', e.target.value)}
                            disabled={this.state.loader}
                            value={values.signatory_fio}
                            type="text" />
                        </div>
                      </div>
                      <div className="form-group form-flex">
                        <label>Должность лица, подписывающего договор</label>
                        <div className="form-field">
                          <input
                            className={(touched.signatory_position && errors.signatory_position) ? 'form-field-error' : ''}
                            onChange={(e) => setFieldValue('signatory_position', e.target.value)}
                            disabled={this.state.loader}
                            value={values.signatory_position}
                            type="text" />
                        </div>
                      </div>
                      <div className="form-group form-flex">
                        <label>Действует на основании</label>
                        <div className="form-field">
                          <input
                            className={(touched.signatory_authority && errors.signatory_authority) ? 'form-field-error' : ''}
                            onChange={(e) => setFieldValue('signatory_authority', e.target.value)}
                            disabled={this.state.loader}
                            value={values.signatory_authority}
                            type="text" />
                        </div>
                      </div>
                      <div className="form-group form-flex">
                        <label>Адрес электронной почты</label>
                        <div className="form-field">
                          <input
                            className={(touched.email && errors.email) ? 'form-field-error' : ''}
                            onChange={(e) => setFieldValue('email', e.target.value)}
                            disabled={this.state.loader}
                            value={values.email}
                            type="text" />
                        </div>
                      </div>
                      <div className="form-group form-flex">
                        <label>Факс</label>
                        <div className="form-field">
                          <input
                            className={(touched.fax && errors.fax) ? 'form-field-error' : ''}
                            onChange={(e) => setFieldValue('fax', e.target.value)}
                            disabled={this.state.loader}
                            value={values.fax}
                            type="text" />
                        </div>
                      </div>
                      <div className="form-group form-flex">
                        <label>Телефон</label>
                        <div className="form-field">
                          <input
                            className={(touched.phone && errors.phone) ? 'form-field-error' : ''}
                            onChange={(e) => setFieldValue('phone', e.target.value)}
                            disabled={this.state.loader}
                            value={values.phone}
                            type="text" />
                        </div>
                      </div>
                      <div className="form-info">Все поля являются обязательными для заполнения</div>
                      {
                        this.state.error && (
                          <div className="form-error">
                            {this.state.error}
                          </div>
                        )
                      }
                      <div className="form-button">
                        <button className="btn" type="submit"
                          disabled={this.state.loader}
                        >Создать заказ</button>
                        <button
                          disabled={this.state.loader}

                          className="btn btn-link"><span className="icon icon-cart">
                          </span>Анулировать заказ</button>
                      </div>
                    </div>
                  </PerfectScrollbar>

                </Form>
              )}
          />

          {/* <div className="form-group form-flex">
            <label>Юридическое название</label>
            <div className="form-field">
              <input type="text"/>
            </div>
          </div>
          <div className="form-group form-flex">
            <label>УНП</label>
            <div className="form-field">
              <input type="text" className="input-number"/>
            </div>
          </div>
          <div className="form-group form-flex">
            <label>Расчетный счет</label>
            <div className="form-field">
              <input type="text"/>
            </div>
          </div>
          <div className="form-group form-flex">
            <label>Банк</label>
            <div className="form-field">
              <input type="text"/>
            </div>
          </div>
          <div className="form-group form-flex">
            <label>БИК</label>
            <div className="form-field">
              <input type="text" className="input-number"/>
            </div>
          </div>
          <div className="form-info">Все поля являются обязательными для заполнения</div>
          <div className="form-button">
            <button className="btn" onClick={this.props.showOrderStep3}>Создать заказ</button>
            <button className="btn btn-link"><span className="icon icon-cart"></span>Анулировать заказ</button>
          </div> */}
        </div>
      </>
    )
  }
}

export default OrderCashless;
