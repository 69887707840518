import React, { Component } from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import moment from 'moment';
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment'
import DayPicker, { DateUtils } from 'react-day-picker';
import Moment from 'react-moment';
import { jsPDF } from "jspdf";
import html2Canvas from 'html2canvas'
import ExportJsonExcel from "js-export-excel"

export default class header extends Component {

    state = {
        from: undefined,
        to: undefined,
        checked: 'all',
        loader: false,
    }

    handlePeriod = (e) => {
        if (e.target.value === 'date') {
            this.setState({
                isPeriod: true
            })
        } else {
            this.setState({
                isPeriod: false
            })
        }
    };

    handleFromChange = (from) => {
        this.setState({
            from
        })
        if (from && this.state.to) {
            this.props.searchDate(from, this.state.to)
        }
    };

    handleToChange = (to) => {
        this.setState({
            to
        })
        if (this.state.from && to) {
            this.props.searchDate(this.state.from, to)
        }
    };

    PDF_CREATE = (itsSave = true) => {
        this.setState({
            loader: true,
        }, () => {
            html2Canvas(document.getElementById('pdf_page'), {
                allowTaint: true
            }).then(function (canvas) {
                let contentWidth = canvas.width //+ 300
                let contentHeight = canvas.height //+ 300
                let pageHeight = contentWidth / 592.28 * 841.89
                let leftHeight = contentHeight
                let position = 0
                let imgWidth = 592.28
                let imgHeight = 592.28 / contentWidth * contentHeight
                let pageData = canvas.toDataURL('image/jpeg', 0.9)
                let PDF = new jsPDF('', 'pt', 'a4')
                // debugger
                if (leftHeight < pageHeight) {
                    PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
                } else {
                    while (leftHeight > 0) {
                        PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
                        leftHeight -= pageHeight
                        position -= 841.89
                        if (leftHeight > 0) {
                            PDF.addPage()
                        }
                    }
                }

                if (itsSave) {
                    PDF.save('Analytic_TicketPro.pdf');
                } else {
                    PDF.autoPrint();
                    PDF.output('dataurlnewwindow');
                }
            })
                .finally(() => {
                    this.setState({
                        loader: false,
                    })
                })
        })


    }

    xlsx_create = () => {
        this.setState({
            loader: true,
        })
        var option = {};

        option.fileName = "Analytic_TicketPro_excel";

        let datasBySource = {
            sheetHeader: ["Название", "Процент", "кол-во операция", "Виджет название"],
            sheetData: this.props.data.bySource || [],
            sheetName: "By Source",
            sheetFilter: ["source_name", "prc", 'operation_count', 'widget_code_name'],
            columnWidths: [20, 20],
        }

        let datasByCountry = {
            sheetHeader: ["Страна", "кол-во билетов", "Процент"],
            sheetData: this.props.data.ByCountries || [],
            sheetName: "By Country",
            sheetFilter: ["country_name", 'ticket_count', 'prc'],
            columnWidths: [20, 20],
        }

        let datasByPrice = {
            sheetHeader: ["Тип", "ID price", "цена", "Кол-во", "Процент"],
            sheetData: this.props.data.byPrice || [],
            sheetName: "By Price",
            sheetFilter: ["price_name_ru", 'price_id', 'cost_price', 'operation_count', 'operation_count_prc'],
            columnWidths: [20, 20],
        }

        let datasByDynamic = {
            sheetHeader: ["Дата", "Пригласительные", "Не опл. наличными кол-во", "Кол-во сума", "Кол-во операций", "Возвращено сума", "Возвращено кол-во", 'Наличными', 'проданные'],
            sheetData: this.props.data.byDynamic || [],
            sheetName: "By Dynamic",
            sheetFilter: ['date',
                "inv_count", "not_payed_cashless_count", "operation_amount", "operation_count", "return_operation_amount", "return_operation_count", 'sold_cashless_count', 'sold_count'
            ],
            columnWidths: [20, 20],
        }

        let datasbyPeriod = {
            sheetHeader: ['Период',
                "Пригласительные", "Изготовленные", "Не опл. наличными", "Кол-во сума", "Кол-во операций", "Возвращено сума", 'Возвращено кол-во', 'Проданные'
            ],
            sheetData: this.props.data.byPeriod || [],
            sheetName: "By Period",
            sheetFilter: ['period',
                "inv_count", "mnf_count", "not_payed_cashless_count", "operation_amount", "operation_count", "return_operation_amount", 'return_operation_count', 'sold_count'
            ],
            columnWidths: [20, 20],
        }

        option.datas = [
            datasBySource,
            datasByCountry,
            datasByPrice,
            datasByDynamic,
            datasbyPeriod
        ];

        var toExcel = new ExportJsonExcel(option); 
        toExcel.saveExcel(); 
        this.setState({
            loader: !true,
        })
    }

    render() {
        const { from, to, isPeriod } = this.state;
        const modifier = { start: from, end: to };

        return (
            <div className="filter">

                <div className="filter-btns">
                    <button
                        disabled={this.state.loader}
                        onClick={() => { this.PDF_CREATE(true) }} className="btn btn-sm">Скачать PDF</button>
                    <button
                        disabled={this.state.loader}

                        onClick={() => { this.PDF_CREATE(false) }} className="btn btn-sm">Печать PDF</button>
                    <button
                        disabled={this.state.loader}

                        onClick={() => {
                            this.xlsx_create()
                        }} className="btn btn-sm">Скачать xlsx</button>

                </div>

                <div>
                    <ul onChange={e => {
                        this.props.onChangeDate(e.target.value)
                        this.setState({
                            checked: e.target.value
                        })
                        if ('date' === e.target.value) {
                            this.setState({
                                from: null,
                                to: null,
                            })
                        }
                    }}>
                        <li>
                            <div className="checkbox">
                                <input id="period-1" type="radio" name="period" defaultValue="today" checked={this.state.checked === 'today'} onChange={this.handlePeriod} />
                                <label htmlFor="period-1"><b>Сегодня</b></label>
                            </div>
                        </li>
                        <li>
                            <div className="checkbox">
                                <input id="period-3" type="radio" name="period" defaultValue="week" checked={this.state.checked === 'week'} onChange={this.handlePeriod} />
                                <label htmlFor="period-3"><b>Неделю</b> </label>
                            </div>
                        </li>
                        <li>
                            <div className="checkbox">
                                <input id="period-4" type="radio" name="period" defaultValue="month" checked={this.state.checked === 'month'} onChange={this.handlePeriod} />
                                <label htmlFor="period-4"><b>Месяц</b> </label>
                            </div>
                        </li>
                        <li>
                            <div className="checkbox">
                                <input id="period-5" type="radio" name="period" defaultValue="all" checked={this.state.checked === 'all'} onChange={this.handlePeriod} />
                                <label htmlFor="period-5"><b>Все время</b> </label>
                            </div>
                        </li>
                        <li>
                            <div className="checkbox">
                                <input id="period-2" type="radio" name="period" defaultValue="date" checked={this.state.checked === 'date'} onChange={this.handlePeriod} />
                                <label htmlFor="period-2"><b>Период времени</b> Выберите период</label>
                            </div>
                            {isPeriod &&
                                <div className="date-range">
                                    <div className="date-range__from">
                                        <DayPickerInput
                                            value={from}
                                            placeholder='DD mm, YYYY'
                                            format="DD MMM, YYYY"
                                            formatDate={formatDate}
                                            parseDate={parseDate}
                                            dayPickerProps={{
                                                locale: 'ru',
                                                localeUtils: MomentLocaleUtils,
                                                selectedDays: [from, { from, to }],
                                                disabledDays: { after: to },
                                                toMonth: to,
                                                modifiers: modifier,
                                                numberOfMonths: 1,
                                                showOutsideDays: true,
                                                onDayClick: () => this.to.getInput().focus(),
                                            }}
                                            onDayChange={this.handleFromChange}
                                        />
                                        <span className="icon icon-calendar"></span>
                                    </div>
                                    <div className="date-range__separator">-</div>
                                    <div className="date-range__to">
                                        <DayPickerInput
                                            ref={el => (this.to = el)}
                                            value={to}
                                            placeholder='DD mm, YYYY'
                                            format="DD MMM, YYYY"
                                            formatDate={formatDate}
                                            parseDate={parseDate}
                                            dayPickerProps={{
                                                locale: 'ru',
                                                localeUtils: MomentLocaleUtils,
                                                showOutsideDays: true,
                                                selectedDays: [from, { from, to }],
                                                disabledDays: { before: from },
                                                modifiers: modifier,
                                                month: from,
                                                fromMonth: from,
                                                numberOfMonths: 1,
                                            }}
                                            onDayChange={this.handleToChange}
                                        />
                                        <span className="icon icon-calendar"></span>
                                    </div>
                                </div>
                            }
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}
