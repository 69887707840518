import React, { Component } from 'react'
import './index.scss';

export default class ModalGroupBuyTickets extends Component {

    state = {
        quantity: 0,
    }
    increaseQuantityHandler = () => {
        if (this.state.quantity < +this.props.modalGroupBuy?.maxCount) {
            this.setState({
                quantity: this.state.quantity + 1,
            })
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.active !== this.props.active) {
            this.setState({
                quantity: 0,
            })
        }
    }

    decreaseQuantityHandler = () => {
        if (this.state.quantity > 0) {

            this.setState({
                quantity: this.state.quantity - 1,
            })
        }
    }

    render() {
        const { modalGroupBuy } = this.props;
        return (
            <div className={`scheme-ticket scheme-ticket-${this.props.active ? 'active' : 'disabled'}`}>
                <div className="scheme-ticket__left">
                    <div className="scheme-ticket__title">
                        Title

                    </div>
                    <div className="scheme-ticket__text">Укажите нужно количество билетов</div>
                    {/* <div className="scheme-ticket__text">Максимальное кол-во билетов - {modalGroupBuy?.maxCount}</div> */}
                    <div className="scheme-ticket__count">
                        <div className="count">
                            <button
                                className="count-dec btn btn-orange"
                                onClick={this.decreaseQuantityHandler} >
                                −
                  </button>
                            <input
                                type="text"
                                className="count-field"
                                // disabled={this.state.quantity > +modalGroupBuy?.maxCount}
                                value={this.state.quantity}
                                onChange={(e) => {
                                    this.setState({
                                        quantity: +this.props.modalGroupBuy?.maxCount > Number(e.target.value) ? Number(e.target.value) : +this.props.modalGroupBuy?.maxCount
                                    })
                                }}
                            />
                            <button
                                className="count-inc btn btn-orange"
                                onClick={this.increaseQuantityHandler} >
                                +
                  </button>
                        </div>
                    </div>
                </div>
                <div className="scheme-ticket__right">
                    <div className="scheme-ticket__table">
                        <div className="scheme-ticket__row">
                            <div className="scheme-ticket__col">Тариф:</div>
                            <div className="scheme-ticket__col">
                                <div className="color">
                                    {/* <span className="color-value" style={{ backgroundColor:      }}></span> */}
                                    <span>{modalGroupBuy.coast}</span>
                                </div>
                            </div>
                        </div>
                        <div className="scheme-ticket__row">
                            <div className="scheme-ticket__col">Сумма:</div>
                            <div className="scheme-ticket__col">{modalGroupBuy?.coast * this.state.quantity}</div>
                        </div>
                    </div>
                    <div className="scheme-ticket__button">
                        <button
                            className="btn btn-orange"
                            onClick={() => this.props.buyTickets(this.state.quantity)} >
                            <span className="icon icon-backet"></span>
                  В корзину
                </button>
                    </div>
                </div>
            </div>
        )
    }
}
