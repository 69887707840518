import instance from './index';
import serviceApi from './service';

export const clientInfo = () =>
    serviceApi(`/ticket-wp-api/v1/get-client-info/`)
        .then(function (response) {
            return { error: false, response: response.data }
        })
        .catch(function (error) {
            return { error: true }
        })