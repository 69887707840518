import React, {Component} from 'react';
import './style.scss';
import './media.scss';

class OrderReservationEmail extends Component{
  render() {
    return (
      <>
        <div className="order-reservation-email">
          <div className="modal-title">Введите Email</div>
          <div className="form-group">
            <input type="email" defaultValue="example@email.com"/>
          </div>
          <div className="form-button">
            <button className="btn">Отправить</button>
          </div>
        </div>
      </>
    )
  }
}

export default OrderReservationEmail;
