export const AUTH = {
    LOGIN: 'AUTH_LOGIN',
    GET_CLIENT: 'GET_CLIENT',
    LOGOUT: 'LOGOUT',
    LOADING: 'LOADING',
};

export const EVENT = {
    ADD_EVENTS: 'ADD_EVENTS',
    SET_SEARCH_INPUT: 'SET_SEARCH_INPUT',
    DEFAULT_SEARCH: 'DEFAULT_SEARCH',
    SET_EVENTS: 'SET_EVENTS',
    SET_VENUES: 'SET_VENUES',
    SET_CATEGORIES: 'SET_CATEGORIES',
    SET_SELECT_TO: 'SET_SELECT_TO',
    SET_SELECT_FROM: 'SET_SELECT_FROM',
    SET_CITIES: 'SET_CITIES',
    SET_MIN: 'SET_MIN',
    SET_MAX: 'SET_MAX',
    CURRENT_PAGE: 'CURRENT_PAGE',
    BUTTONS: 'BUTTONS',
    PRELOADER: 'PRELOADER',
    ERROR: 'ERROR',
    LOAD_EVENTS: 'LOAD_EVENTS',
    CURRENT_EVENT: 'CURRENT_EVENT',
    SET_EVENTS_SORT: 'SET_EVENTS_SORT',
    SET_CURRENT_EVENT: 'SET_CURRENT_EVENT'
};

export const PLACES = {
    SET: 'SET_PLACES_LIST',
    SEARCH_INPUT: 'SEARCH_INPUT',
};

export const CITIES = {
    SET: 'SET_CITIES_LIST',
}

export const CATEGORIES = {
    SET: 'SET_CATEGORIES_LIST',
}
export const SEAT = {
    SET: "SET_seatScheme"
}

export const ORDERS = {
    ADD_ORDERS: 'ADD_ORDERS',
    SET_SEARCH_INPUT: 'SET_SEARCH_INPUT_ORDER',
    SET_SEARCH_DATE: 'SET_SEARCH_DATE_ORDER',
    DEFAULT_SEARCH: 'DEFAULT_SEARCH_ORDER',
    SET_ORDERS: 'SET_ORDERS',
    SET_VENUES: 'SET_VENUES_ORDER',
    SET_CATEGORIES: 'SET_CATEGORIES_ORDER',
    SET_SELECT_TO: 'SET_SELECT_TO_ORDER',
    SET_SELECT_FROM: 'SET_SELECT_FROM_ORDER',
    SET_CITIES: 'SET_CITIES_ORDER',
    SET_MIN: 'SET_MIN_ORDER',
    SET_MAX: 'SET_MAX_ORDER',
    CURRENT_PAGE: 'CURRENT_PAGE_ORDER',
    BUTTONS: 'BUTTONS_ORDER',
    PRELOADER: 'PRELOADER_ORDER',
    ERROR: 'ERROR_ORDER',
    LOAD_ORDERS: 'LOAD_ORDERS',
    CURRENT_ORDER: 'CURRENT_ORDER',
    SET_ORDERS_SORT: 'SET_ORDERS_SORT',
    SET_SEARCH_ID_EVENT: 'SET_SEARCH_ID_EVENT_ORDER',
    SET_FILTER_MULTI_TYPES: 'SET_FILTER_MULTI_TYPES_ORDER',
    SET_FILTER_TYPES: 'SET_FILTER_TYPES_ORDER',
    SET_CLEAR_FILTER: 'SET_CLEAR_FILTER_ORDER',
    SET_SEARCH_FILTER: 'SET_SEARCH_FILTER_ORDER',
    SET_ID_FIND: 'SET_ID_FIND_ORDER',
    SET_CURRENT_ORDER: 'SET_CURRENT_ORDER',
    SET_CURRENT_ORDER_ERROR: 'SET_CURRENT_ORDER_ERROR',
    SET_CURRENT_ORDER_LOADER: 'SET_CURRENT_ORDER_LOADER',
    SET_ORDERS_SORT_ID: 'SET_ORDERS_SORT_ID',
    ADD_EVENT: 'ADD_EVENT_ORDER'
};

export const REPORT = {
    BUTTONS_EVENTS: "REPORT_BUTTONS_EVENTS",
    ADD_EVENTS: "REPORT_ADD_EVENTS",
    PRELOADER_EVENTS: "REPORT_PRELOADER_EVENTS",
    CLEAR_EVENTS: "REPORT_CLEAR_EVENTS"
}