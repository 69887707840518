import { Dimmer, Loader } from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';

export default function DimmerLoader(props) {
  return (
    <Dimmer active={props.active} inverted>
      <Loader />
    </Dimmer>);
}

DimmerLoader.propTypes = {
  active: PropTypes.bool,
};
