import './style.scss';
import './media.scss';
import Logo from '../../assets/img/logo.svg';
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { getXML } from '../../server/implementation';
import { login, clientAuth, loading } from '../../store/actions/auth';
import { clientInfo } from '../../api/auth';
import DimmerLoader from '../../components/DimmerLoader';

const Login = ({ login, clientAuth, loading }) => {

  async function auth(e) {
    e.preventDefault();

    if (showLoginAuth && showPasswordAuth) {
      setTryToLogin(true);
      let tryToLogin = (await getXML(
        showLoginAuth,
        showPasswordAuth,
      ).then());
      if (tryToLogin.ssoc) {
        if (tryToSaveAuth === true) {
          localStorage.setItem('saveLogin', true);
        }
        localStorage.setItem('loginAuth', showLoginAuth);
        localStorage.setItem('passwordAuth', showPasswordAuth);
        login(tryToLogin.ssoc);
        let sso = await (clientInfo(tryToLogin.ssoc));
        if (!sso.error) {
          clientAuth(sso.response);
          loading(true);
        } else {
          setErrorAuth("error server");
          setTryToLogin(false);
        }
      }
      else {
        setTryToLogin(false);
        setErrorAuth(tryToLogin.error);
      }
    }
  }


  useEffect(() => {
    if (localStorage.getItem('saveLogin') !== null) {
      const loginAuth = localStorage.getItem('loginAuth');
      const passwordAuth = localStorage.getItem('passwordAuth');
      if (loginAuth !== null && passwordAuth !== null) {
        setLoginAuth(loginAuth);
        setPasswordAuth(passwordAuth);
      }
      }
  });;

  async function forgotPassed(e) {
    e.preventDefault();
    if (showForgotValuePassOne && showForgotValuePassTwo && showForgotValueMail) {
      if (showForgotValuePassOne === showForgotValuePassTwo) {
        console.log('успех')
      } else {
        setErrorForgotPass('Пароли не совпадают!')
      }
    }
  }

  const [forgotPass, setForgotPass] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showForgotPassOne, setShowPassOne] = useState(false);
  const [showForgotValuePassOne, setForgotValuePassOne] = useState("");
  const [showForgotValuePassTwo, setForgotValuePassTwo] = useState("");
  const [showForgotValueMail, setForgotValueMail] = useState("");
  const [errorForgotPass, setErrorForgotPass] = useState("");

  const [showForgotPassTwo, setShowPassTwo] = useState(false);
  const [showLoginAuth, setLoginAuth] = useState('');
  const [showPasswordAuth, setPasswordAuth] = useState('');
  const [tryToSaveAuth, setSaveAuth] = useState(false);


  const [showErrorAuth, setErrorAuth] = useState('');
  const [tryToLogin, setTryToLogin] = useState(false);
  return (
    <div className="login">
      <div className={`login-box ${forgotPass ? 'show' : ''}`}>
        <div className="form-login">
          <form action="">
            <div className="logo">
              <img src={Logo} alt="" />
            </div>
            <div className="form-title">Войдите в свой аккаунт</div>
            <div className="form-group">
              <input onChange={(e) => setLoginAuth(e.target.value)} value={showLoginAuth} type="text" name="name" placeholder="Имя пользователя" />
            </div>
            <div className="form-group">
              <input type={showPass ? 'text' : 'password'} onChange={(e) => setPasswordAuth(e.target.value)} value={showPasswordAuth} name="pass" placeholder="Пароль" />
              <button type="button" className="show-pass" onClick={() => setShowPass(!showPass)}>
                {showPass ?
                  <span className="icon icon-view-off"></span>
                  : <span className="icon icon-view-on"></span>
                }
              </button>
            </div>
            <div className="form-flex">
              <a onClick={() => setForgotPass(!forgotPass)}>Забыли пароль?</a>
              <div className="checkbox">
                <input onClick={() => setSaveAuth(!tryToSaveAuth)} id="forgot-pass" type="checkbox" name="forgot" />
                <label htmlFor="forgot-pass">Запомнить</label>
              </div>
            </div>
            <div className="form-button">
              <button onClick={auth} type="submit" className="btn">Войти</button>
            </div>
          </form>
        </div>
        <div className="form-password">
          <form onSubmit={forgotPassed} action="">
            <div className="logo">
              <img src={Logo} alt="" />
            </div>
            <div className="form-title">Забыли пароль?</div>
            <div className="form-group">
              <input
                type="email"
                name="email"
                placeholder="Ваш email"
                value={showForgotValueMail}
                onChange={(e) => setForgotValueMail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <input
                type={showForgotPassOne ? 'text' : 'password'}
                name="pass"
                value={showForgotValuePassOne}
                placeholder="Новый пароль"
                onChange={e => setForgotValuePassOne(e.target.value)}
              />
              <button type="button" className="show-pass" onClick={() => setShowPassOne(!showForgotPassOne)}>
                {showForgotPassOne ?
                  <span className="icon icon-view-off"></span>
                  : <span className="icon icon-view-on"></span>
                }
              </button>
            </div>
            <div className="form-group">
              <input
                type={showForgotPassTwo ? 'text' : 'password'}
                name="pass-confirm"
                value={showForgotValuePassTwo}
                placeholder="Новый пароль (еще раз)"
                onChange={e => setForgotValuePassTwo(e.target.value)}
              />
              <button type="button" className="show-pass" onClick={() => setShowPassTwo(!showForgotPassTwo)}>
                {showForgotPassTwo ?
                  <span className="icon icon-view-off"></span>
                  : <span className="icon icon-view-on"></span>
                }
              </button>
            </div>
            <div className="form-button">
              <button type="submit" className="btn">Восстановить пароль</button>
            </div>
          </form>
        </div>
      </div> <DimmerLoader active={tryToLogin} />
    </div>
  )
}

const mapStateToProps = (state) => ({
  auth: state.auth
});

const mapDispatchToProps = {
  login,
  clientAuth,
  loading
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
