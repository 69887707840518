import instance from './index';
import store from '../store/store';
import Exit from '../containers/Exit/index';

export const serviceApi = async (method, parameters) => {
    let params = null;
    if (method !== '/ticket-wp-api/v1/get-client-info/') {
        params = Object.assign({}, { client_id: store.getState().auth.client_id }, parameters);
    }
    const data = instance.get(`${method}${localStorage.getItem('ssoclid')}`, { params })
    .catch((er) => {
        er?.response?.data && er?.response.data?.error === 801 && Exit.exit();
        throw er;
    });
    return data;
}

export default serviceApi;
