import React, { Component } from 'react';
import ReportsFilter from '../../../components/ReportsFilter/ReportsFilter';
import ReportsList from '../../../components/ReportsList/ReportsList';

class Reports extends Component {
    render() {
        return (
            <>
                <main className="main">
                    <div className="container">
                        <section className="reports">
                            <ReportsFilter
                                selectDate={true}
                                selectCategories={true}
                                typeSearch="geo"
                                search={(event, date_from, date_to) => `event_category_id=${event}&date_from=${date_from}&date_to=${date_to}`}
                            />
                        </section>
                    </div>
                </main>
            </>
        )
    }
}

export default Reports;
