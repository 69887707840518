import React, { Component } from 'react';
import './style.scss';
import './media.scss';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/ru';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { searchNameEvent, searchDateEvent, searchIdEvent, setClearFilter, searchOrderFilter, setIdFindOrders, getListOrders, setCurrentOrder } from '../../store/actions/orders';

class EventOrdersSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      from: undefined,
      to: undefined,
      isShowDateModal: false,
    };

    this.handleDayClick = this.handleDayClick.bind(this);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, false);
  }

  componentDidMount() {
    this.props.searchIdEvent(
      this.props.eventTotal
    )
    document.addEventListener('click', this.handleClickOutside, false);
  }

  handleClickOutside = (e) => {
    let path = e.path;
    if (!e.path) {
      path = e.composedPath();
    }

    const dateModal = document.getElementsByClassName('event-order__search-date-title')[0];
    if (!path.includes(dateModal)) {
      const searchDateIcon = document.getElementsByClassName('event-order__search-date-modal')[0];
      if (!path.includes(searchDateIcon)) {
        this.setState({
          isShowDateModal: false
        })
      }
    }
  };

  handleDayClick = (day) => {
    const range = DateUtils.addDayToRange(day, this.props.filter.date);
    this.props.searchDateEvent(range)
  };

  showDateModal = () => {
    this.setState({
      isShowDateModal: true
    })
  };

  render() {
    const { isShowDateModal } = this.state;
    let from = this.props.filter.date.from;
    let to = this.props.filter.date.to;
    const modifiers = {
      start: this.props.filter.date.from,
      end: this.props.filter.date.to
    };
    return (
      <>
        <div className="event-order__search">
          <form action="#" autoComplete="off">
            <div className="event-order__search-form">
              <div className={`event-order__search-date ${isShowDateModal ? 'show' : ''}`}>
                <div className="event-order__search-date-title" onClick={this.showDateModal}>
                  <span className="icon icon-calendar"></span>
                  {from && to ?
                    (from.toLocaleDateString() == to.toLocaleDateString()) ?
                      <span className="event-order__search-date-value"><Moment format="DD MMM YYYY" locale="ru">{from.toString()}</Moment></span>
                      : <span className="event-order__search-date-value"><Moment format="DD MMM YYYY" locale="ru">{from.toString()}</Moment> - <Moment format="DD MMM YYYY" locale="ru">{to.toString()}</Moment></span>
                    : 'Виберите дату'
                  }
                </div>
                <div className="event-order__search-date-modal">
                  <DayPicker
                    localeUtils={MomentLocaleUtils}
                    locale="ru"
                    selectedDays={[
                      this.props.filter.date.from
                      , {
                        from: this.props.filter.date.from,
                        to: this.props.filter.date.to,
                      }]}
                    onDayClick={this.handleDayClick}
                    modifiers={modifiers}
                    showOutsideDays
                  />
                </div>
              </div>
              <div className="event-order__search-field">
                <input
                  value={this.props.filter.searchInput}
                  onChange={(e) => {
                    this.props.searchNameEvent(e.target.value)
                    return e
                  }}
                  type="text"
                  placeholder="№ заказа" />
                <span className="icon icon-search"></span>
              </div>
              <div className="form-button">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.searchOrderFilter(this.props.filter.searchInput);
                  }
                  }
                  className="btn btn-sm">Найти</button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    if (this.props.searchIdEventOrder) {
                      // this.props.setIdFindOrders(false);
                      this.props.getListOrders();
                    }
                    this.props.setClearFilter();
                    this.props.searchOrderFilter();
                  }}
                  className="btn btn-link btn-sm"><span className="icon icon-close"></span>Очистить</button>
              </div>
            </div>
          </form>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  orders: state.orders.orders,
  filter: state.orders.filter,
  preloader: state.orders.preloader,
  eventTotal: state.event.totalEvent.event,
  searchIdEventOrder: state.orders.searchIdEvent
});

const mapDispatchToProps = {
  searchNameEvent,
  searchDateEvent,
  searchIdEvent,
  setClearFilter,
  searchOrderFilter,
  setIdFindOrders,
  getListOrders,
  setCurrentOrder
};

export default connect(mapStateToProps, mapDispatchToProps)(EventOrdersSearch);
