import React, { Component } from 'react';
import moment from "moment";
import { connect } from "react-redux";
import { getListOrders, setDefaultSearch, setIdFindOrders } from '../../store/actions/orders';
import EventOrdersListHeader from '../EventOrdersListHeader';
import { newFilteredArrayOrders } from "../../store/reducers/OrdersResellect";
import { withRouter } from 'react-router-dom';
import { uniqBy } from 'lodash';
import { setTotalEvent } from '../../store/actions/event';
import './style.scss';
import './media.scss';

class EventOrdersList extends Component {
  componentDidMount = async () => {
    this.props.setDefaultSearch();
    if (this.props.match.params.eventID) {
      this.props.setIdFindOrders(this.props.match.params.eventID)
    }
    if (+this.props.totalEvent?.id !== +this.props.match.params.eventID && this.props.match.params.eventID) {
      this.props.setTotalEvent(this.props.match.params.eventID)
    }
    this.props.getListOrders(this.props.match.params.eventID);
    document.addEventListener('scroll', this.handleScroll, false);
    document.addEventListener('wheel', this.handleScroll, false);
  }

  componentDidUpdate(prevProps) {
    if(this.props.match.params.eventID !== prevProps.match.params.eventID) {
      this.props.setDefaultSearch();
      if (this.props.match.params.eventID) {
        this.props.setIdFindOrders(this.props.match.params.eventID)
      }
      if (+this.props.totalEvent?.id !== +this.props.match.params.eventID && this.props.match.params.eventID) {
        this.props.setTotalEvent(this.props.match.params.eventID)
      }
      this.props.getListOrders(this.props.match.params.eventID);
    }
  }

  componentWillUnmount() {
    this.props.setDefaultSearch();
    document.removeEventListener('scroll', this.handleScroll, false);
    document.removeEventListener('wheel', this.handleScroll, false);
  }

  handleScroll = async () => {
    const { preloader } = this.props;
    const scroll = document.documentElement || document.body;
    if (!preloader) {
      if ((scroll.scrollTop + scroll.clientHeight) + (scroll.scrollHeight / 100) > scroll.scrollHeight) {
        this.props.getListOrders()
      }
    }
  }

  formatedData = (date) => {
    if (date) {
      return (date.substring(8, 10) + ' ' + moment(date).locale('ru').format('MMMM').substring(0, 3) +', '+ 
      moment(date).locale('ru').format('YYYY') + ', ' + moment(date).format('H:mm'));
    } else {
      return null;
    }
  }

  render() {
    const { orders } = this.props;
    return (
      <>
        <div className="event-orders__list">
          <div className="event-orders-table">
            <EventOrdersListHeader clearIdFind={this.clearIdFind} />
            <div className="event-orders-table__tbody">
              {
                uniqBy(orders, 'id').map(item => {
                  return (
                    <div key={item.id} className="event-orders-table__row">
                      <div className="event-orders-table__col event-orders-number">{item.id}</div>
                      <div className="event-orders-table__col event-orders-status">{item.status[0].name}</div>
                      <div className="event-orders-table__col event-orders-date">{this.formatedData(item.inserted)}</div>
                      <div className="event-orders-table__col event-orders-date">{item.status_id === -2 && this.formatedData(item.changed)}</div>
                      <div className="event-orders-table__col event-orders-tickets">{item.tickets.length}</div>
                      <div className="event-orders-table__col event-orders-payment">
                        {
                          item.status_id === 4 ? item.ident_type.id === 'MS' ? "Безнал" : "Наличными" : null
                        }
                      </div>
                      <div className="event-orders-table__col event-orders-print">
                        {
                          item.email ? (<>Отправка на <b>{item.email}</b></>) : "Печать"
                        }
                      </div>
                      <span onClick={() => {
                        this.props.history.push(`/order/${item.id}`)
                      }} className="btn">Смотреть</span>
                    </div>
                  )
                })
              }
            </div>
          </div>
          {
            this.props.preloader && (
              <div className="loading">
                <div className="loading-box">
                  <span className="icon icon-spinner"></span>
                </div>
              </div>
            )
          }
        </div>
      </>
    )
  }
}

const makeMapStateToProps = () => {
  const getNewFilteredArray = newFilteredArrayOrders();
  const mapStateToProps = state => {
    return {
      orders: getNewFilteredArray(state),
      preloader: state.orders.preloader,
      totalEvent: state.event.totalEvent?.event
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = {
  getListOrders,
  setDefaultSearch,
  setIdFindOrders,
  setTotalEvent
};

export default withRouter(connect(makeMapStateToProps, mapDispatchToProps)(EventOrdersList));
