import React, { Component } from 'react';
import './style.scss';
import './media.scss';
import EventsSidebar from '../../components/EventsSidebar/EventsSidebar';
import EventsList from '../../components/EventsList/EventsList';
import { connect } from 'react-redux';

class Events extends Component {
  render() {
    return (
      <main className="main">
        <div className="container">
          <section className="events">
            <EventsList />
            <EventsSidebar />
          </section>
        </div>
      </main>
    )
  }
}

const mapStateToProps = (state) => {
  // console.log(state.event.events)
  return {
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Events)
