import axios from 'axios';
import { encodeXML, parseXML } from '../../helpers/index';
import moment from 'moment';
import { getApiUrl } from '../config';

export const getXML = (
  login, password,
  errorLine = 'Error ErrorLine') => (
    axios.get(`${getApiUrl}/ticket-wc/xml_online.admin?XML=${encodeXML(
      `<BS_Request>
    <TerminalId>SSO_Client_Web_Prom</TerminalId>
    <TerminalTime>${moment().format("YYYYMMDDhhmmss")}</TerminalTime>
    <RequestType>Login</RequestType>
    <Subsystem>ClientAuth</Subsystem>
    <Login Type="PWD">
      <Parameter Id="Login">${login}</Parameter>
      <Parameter Id="Password">${password}</Parameter>
    </Login>
    </BS_Request>`
    )}`)
      .then(async (resp) => {
        const result = parseXML(resp.data, 'Login SID');
        if (result) {
          localStorage.setItem('ssoclid', result);
          return { ssoc: result }
        }
        return { ssoc: false, error: parseXML(resp.data, errorLine) };
      })
  );  
