import React, { Component } from "react";
import "./style.scss";
import "./media.scss";
import OrderStep from "../OrderStep/OrderStep";
import OrderAction from "../OrderAction/OrderAction";
import OrderList from "../OrderList/OrderList";
import OrderInfo from "../OrderInfo/OrderInfo";
import EventBox from "../EventBox/EventBox";
import OrderBasket from "../OrderBasket/OrderBasket";
import OrderPayment from "../OrderPayment/OrderPayment";
import OrderPaymentBasket from "../OrderPaymentBasket/OrderPaymentBasket";
import OrderPrint from "../OrderPrint/OrderPrint";
import OrderLayoutTicket from "../OrderLayoutTicket/OrderLayoutTicket";
import OrderCashless from "../OrderCashless/OrderCashless";
import OrderCashlessSuccess from "../OrderCashlessSuccess/OrderCashlessSuccess";
import OrderReservation from "../OrderReservation/OrderReservation";
import OrderReservationSuccess from "../OrderReservationSuccess/OrderReservationSuccess";
import OrderReservationEmail from "../OrderReservationEmail/OrderReservationEmail";
import Spinner from "../Spinner";
import _ from "lodash";
import {
  seatLock,
  orderCreate,
  getTypesTemlpsList,
  orderPayMakeOrder
} from "../../api/reserve";
import Modal from "../Header/ModalError";
import { orderDelete, payOrderTwoSum, orderSuccess } from '../../api/order'
import { withRouter } from 'react-router-dom';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';

const defaultState = {
  isShowSidebar: false,
  isShowOrderAction: false,
  isShowOrderList: false,
  isShowOrderListZero: false,
  isShowOrderInfo: false,
  isShowEventBox: false,
  isShowOrderBasket: false,
  isShowOrderBasketZero: false,
  isShowOrderPayment: false,
  isShowOrderPaymentBasket: false,
  isShowOrderPrint: false,
  isShowOrderLayoutTicket: false,
  isOrderCashless: false,
  isOrderCashlessSuccess: false,
  isOrderReservation: false,
  isOrderReservationSuccess: false,
  isOrderReservationEmail: false
};

class Order extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...defaultState,
      orderType: "",
      orderStep: 1,
      typesTemplsList: [],
      orderId: false,
      orderData: {},
      typeTicketId: false,
      ticket_template_type_id: false,
      active: true,
      loader: false,
      modal: {
        error: false,
        text: ""
      },
      deals: {
        coast_cods: 0,
        coast_sertificate: 0
      },
      dataOrderInfo: {
        id: false
      },
      orderStepAccess: 1,
      coast: 0,
      coastPromocodes: 0
    };
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.listIdTickets, this.props.listIdTickets)) {
      let coastTickets = 0
      let coastPromocodes = 0
      this.props.listIdTickets.map(item => {
        (coastTickets = +coastTickets.toFixed(2) + +((item.price)).toFixed(2))
        if (item.hasOwnProperty('info')) {
          coastPromocodes = +coastPromocodes.toFixed(2) + ((item.info.discount))
        }
      });
      if (!this.state.orderId && this.props.listIdTickets.length) {
        this.setState({
          coast: coastTickets.toFixed(2),
          coastPromocodes: coastPromocodes.toFixed(2),
        })
      }
    }
  }

  async componentDidMount() {
    this.setState({
      loader: true,
    })
    const { listIdTickets } = this.props;
    let promises = []
    let tickets = {
      tickets_and_counts: [],
      prices: []
    };
    let result = {};

    this.getTempls()
    let coastTickets = 0
    let coastPromocodes = 0
    this.props.listIdTickets.map(item => {
      (coastTickets = +coastTickets.toFixed(2) + +((item.price)).toFixed(2))
      if (item.hasOwnProperty('info')) {
        coastPromocodes = +coastPromocodes.toFixed(2) + ((item.info.discount))
      }
    });
    this.setState({
      coast: coastTickets.toFixed(2),
      coastPromocodes: coastPromocodes.toFixed(2),
    })
    this.showOrder();
    window.addEventListener(
      "beforeunload",
      e => this.handleClickOutside(e),
      false
    );
    listIdTickets.forEach(function (item) {
      if (item.TicketReserved !== true) {
        result[item.id] = { count: (result[item.id]?.count || 0) + 1 };
      }
    });
    for (var key in result) {
      tickets.tickets_and_counts.push(`${key}/${result[key].count}`);
    }
    _.uniqBy(listIdTickets, "name").map(item => {
      if (item.TicketReserved !== true) {
        tickets.prices.push(item.priceID);
      }
    });
    promises.push(
      seatLock({
        tickets_and_counts: tickets.tickets_and_counts.join(","),
        prices: tickets.prices.join(","),
      })
    )
    Promise.allSettled(promises).then((res) => {
      this.props.getBasket()
      if (!_.some(res, { status: 'rejected' })) {
      } else {
        res.map(item => {
         ToastsStore.error(item?.reason?.response?.data?.text || item?.reason?.response?.data?.error)
        })
      }
    })
      .finally(() => {
        this.setState({
          loader: false,
        })
      })
    // const addTicketPromise = getCancelablePromise(addTicket(seatID, priceID, quantity));
  }

  componentWillUnmount = () => {
    window.removeEventListener(
      "beforeunload",
      e => this.handleClickOutside(e),
      false
    );
  };

  orderDisabled = () => {
    this.setState({
      orderId: false
    }, () => {
      this.props.hideOrder()
    });
  };

  handleClickOutside(e) {
    e.preventDefault();
    if (this.state.orderId) {
      e.returnValue = true;
    }
  }

  changeTempls = e => {
    this.setState({
      typeTicketId: e.type?.id,
      ticket_template_type_id: e.id,
    });
  };

  showOrder = () => {
    this.setState({
      ...defaultState,
      isShowSidebar: true,
      isShowOrderAction: true,
      isShowOrderList: true,
      isShowOrderInfo: true,
      isShowEventBox: true,
      isShowOrderBasket: true
    });
  };

  showOrderZero = () => {
    this.setState({
      ...defaultState,
      isShowSidebar: true,
      isShowOrderAction: true,
      isShowOrderList: true,
      isShowOrderListZero: true,
      isShowOrderInfo: true,
      isShowEventBox: true,
      isShowOrderBasket: true,
      isShowOrderBasketZero: true
    });
  };

  showOrderLayoutTicket = () => {
    this.setState({
      ...defaultState,
      isShowOrderBasket: true,
      isShowOrderLayoutTicket: true
    });
  };


  setShowPage = (...ids) => {
    let newObject = {}
    ids.map(item => {
      newObject[item] = true;
    })
    this.setState({
      ...defaultState,
      ...newObject
    });
  }

  getTypeOrder = type => {
    switch (type.value) {
      case "oplata":
        this.showOrder();
        break;
      case "price":
        this.showOrder();
        break;
      case "pricezero":
        this.showOrderZero();
        break;
      case "cashless":
        this.showOrder();
        break;
      case "reserve":
        this.showOrder();
        break;
    }
    this.setState({
      orderType: type.value
    });
  };

  showOrderStep1 = () => {
    this.setState({
      orderStep: 1,
      orderType: "oplata"
    });
    this.showOrder();
  };

  getTempls = () => {
    getTypesTemlpsList(this.props.eventID)
      .then(res => {
        let data = res.data.map(item => {
          item.value = item.name;
          item.label = item.name;
          return item;
        });
        this.setState({
          typesTemplsList: data,
          typeTicketId: data[0].type.id,
          ticket_template_type_id: data[0].id
        });
      })
      .catch(er => {
        // error
      });
  };

  orderCreateSell = (data) => {
    this.setState({
      active: false,
      loader: true
    });
    data.ticket_template_type = this.state.typeTicketId;

    return orderCreate(data)
      .then(resOrder => {
        this.props.clearTickets();
        this.setState({
          loader: false,
          orderId: resOrder.data.id,
          orderData: resOrder.data,
        });
        this.setDataOrderInfo(resOrder.data)
        this.setState({
          coast: resOrder.data.sale_amount.toFixed(2),
        })

        return resOrder
      })
      .catch(er => {
        this.setState({
          loader: false
        });
        throw er
      });
  }

  orderCreatePayment = (data) => {
    return payOrderTwoSum(this.state.orderId, data)
      .then(res => {
        this.showOrderStep3()
        return res
      })
  }


  orderCreateCashless = (data) => {
    return orderCreate(data)
      .then(resOrder => {
        this.props.clearTickets();
        if (resOrder.status === 200) {
          this.setDataOrderInfo(resOrder.data)
          this.setState({
            orderId: resOrder.data.id
          });
        }
        this.showOrderStep3()
        return resOrder
      })
      .catch(er => {
        throw er
      });
  }

  updateOrder = (data) => {
    let coast_cods = 0;
    let coast_sertificate = 0;
    data.used_analogs.forEach(item => {
      if(item.analog_type_id === 2) {
          coast_cods = Number(coast_cods) + Number(item.used_amount)
      } else {
        coast_sertificate = Number(coast_sertificate) + Number(item.used_amount)
      }
    })
    this.setState({
      coast: data.sale_amount.toFixed(2),
      deals: {
        coast_cods: coast_cods.toFixed(2),
        coast_sertificate: coast_sertificate.toFixed(2)
      }
    })
  }

  orderCreateManufacturing = async () => {
    const { listIdTickets } = this.props;
    if (
      (this.state.orderType === "pricezero" ||  this.state.orderType === 'price') &&
      listIdTickets.length &&
      this.state.active
    ) {
      // this.getTempls();
      this.setState({
        active: false,
        loader: true,
      });
      let data = {
        ticket_template_type: this.state.typeTicketId,
      }
      if(this.state.orderType === "pricezero") {
        data.is_invitation = true
      } else {
        data.is_manufacturing = true
      }
      await orderCreate(data)
        .then(async resOrder => {
          this.props.clearTickets();
          this.setState({
            loader: false
          });
          if (resOrder.status === 200) {
            this.setState({
              orderId: resOrder.data.id
            })
            if(this.state.orderType === 'price') {
              await orderSuccess({
                order_id: resOrder.data.id,
                active: true,
              })
              .then(() => {
                this.showOrderStep3()
              })
            } else {
              this.setState({
                active: true,
              })
              this.showOrderStep3()
            }
          }
        })
        .catch(er => {
          // cant make order
          this.setState({
            loader: false
          });
        });
      //   }
      // })
      // .catch(er => {
      //   // cant reserver
      //   this.setState({
      //     loader: false
      //   });
      // });
    } else if (this.state.orderType === 'oplata') {
      this.setShowPage('isShowSidebar', 'isShowOrderPayment', 'isShowOrderPaymentBasket')
    }

  }
  
  showOrderStep2 = async () => {
    const type = this.state.orderType;
    switch (type) {
      case "oplata": //'isShowSidebar', 'isShowOrderPayment', 'isShowOrderPaymentBasket'
        this.setShowPage('isShowOrderLayoutTicket', 'isShowSidebar')
        break;
      case "price":
        this.setShowPage('isShowOrderBasket', 'isShowOrderLayoutTicket')
        break;
      case "pricezero":
        this.setShowPage('isShowOrderBasket', 'isShowOrderLayoutTicket')
        break;
      case "cashless":
        this.setShowPage('isOrderCashless')
        break;
      case "reserve":
        this.setShowPage('isOrderReservation')
        break;
    }
  };

  setAccessSteps = (step = 1) => {
    if (this.state.orderStepAccess <= step) {
      this.setState({
        orderStepAccess: step,
      })
    }
  }

  orderDelete = () => {
    this.setState({
      loader: true
    });
    orderDelete({ order_id: this.state.orderId })
      .then(res => {
        this.setState({
          active: true,
          coast: "0.00",
          coastPromocodes: "0.00",
          dataOrderInfo: { id: false },
          isOrderCashless: false,
          isOrderCashlessSuccess: false,
          isOrderReservation: false,
          isOrderReservationEmail: false,
          isOrderReservationSuccess: false,
          isShowEventBox: true,
          isShowOrderAction: true,
          isShowOrderBasket: true,
          isShowOrderBasketZero: false,
          isShowOrderInfo: true,
          isShowOrderLayoutTicket: false,
          isShowOrderList: true,
          isShowOrderListZero: false,
          isShowOrderPayment: false,
          isShowOrderPaymentBasket: false,
          isShowOrderPrint: false,
          isShowSidebar: true,
          loader: false,
          modal: { error: false, text: "" },
          orderId: false,
          orderStep: 1,
          orderStepAccess: 1,
          orderType: "",
          typeTicketId: false,
        });
        return res;
      })
      .catch(er => {
        this.setState({
          loader: false
        });
        throw er;
      })
  }

  showOrderStep3 = async () => {
    if (this.state.orderType === "pricezero" && this.state.orderId) {
      this.setState({
        loader: true
      });
      await orderPayMakeOrder(this.state.orderId)
        .then(res => {
          this.setState({
            loader: false
          });
        })
        .catch(er => {
          this.setState({
            loader: false
          });
        });
    }

    this.setState({
      orderStep: 3
    });

    const type = this.state.orderType;
    switch (type) {
      case "oplata":
        this.setShowPage('isShowOrderBasket', 'isShowOrderPrint')
        break;
      case "price":
        this.setShowPage('isShowOrderBasket', 'isShowOrderPrint')
        break;
      case "pricezero":
        this.setShowPage('isShowOrderBasket', 'isShowOrderPrint')
        break;
      case "cashless":
        this.setShowPage('isOrderCashlessSuccess')
        break;
      case "reserve":
        this.setShowPage('isOrderReservationSuccess')
        break;
    }
  };

  activeLoader = () => {
    this.setState({
      loader: true,
    })
  }

  hideLoader = () => {
    this.setState({
      loader: false,
    })
  }

  checkOrder = () => {
    this.props.hideOrder();
    return;
    if (window.confirm("Вы уверены что хотите выйти?")) {
    // if (!this.state.orderId) {
      this.props.hideOrder();
    } else {
    //   this.setState({
    //     modal: {
    //       error: true,
    //       text: "Необходимо закончить заказ!"
    //     }
    //   });
    }
  };

  closeModal = () => {
    if (window.confirm("Необходимо сбросить заказ, вы уверены что хотите выйти")) {
      // window.open("exit.html", "Thanks for Visiting!");
    }
    this.setState({
      modal: {
        error: false,
        text: ""
      }
    });
  };

  setDataOrderInfo = (data) => {
    this.setState({
      dataOrderInfo: data,
    })
  }

  render() {
    const {
      orderStep,
      orderStepAccess,
      isShowSidebar,
      isShowOrderAction,
      isShowOrderList,
      isShowOrderInfo,
      isShowEventBox,
      isShowOrderBasket,
      isShowOrderPayment,
      isShowOrderPaymentBasket,
      isShowOrderPrint,
      isShowOrderListZero,
      isShowOrderBasketZero,
      isShowOrderLayoutTicket,
      isOrderCashless,
      isOrderCashlessSuccess,
      isOrderReservation,
      isOrderReservationSuccess,
      isOrderReservationEmail
    } = this.state;

    let modalSize = "";
    if (isShowOrderPrint || isOrderCashlessSuccess || isOrderReservationEmail) {
      modalSize = "modal-xs";
    }
    if (isOrderCashlessSuccess || isOrderReservationSuccess) {
      modalSize = "modal-sm";
    }
    if (isShowOrderLayoutTicket || isOrderCashless || isOrderReservation) {
      modalSize = "modal-md";
    }

    return (
      <>
        <div className="modal">
          <div className={`modal-box ${modalSize}`}>
            {this.state.loader && (
              <div className="modal_loader">
                <Spinner />
              </div>
            )}
            <>
              <div className="modal-wrapper">
                <section className="modal-section">
                  {" "}
                  <>
                    <OrderStep
                      orderStep={orderStep}
                      orderStepAccess={orderStepAccess}
                      showOrderStep1={() => this.showOrderStep1()}
                      showOrderStep2={() => this.showOrderStep2()}
                      showOrderStep3={() => this.showOrderStep3()}
                    />
                    {isShowOrderAction && (
                      <OrderAction typeOrder={this.getTypeOrder} />
                    )}
                    {isShowOrderList && (
                      <OrderList
                        eventPrices={this.props.event.prices}
                        isShowOrderListZero={isShowOrderListZero}
                        listIdTickets={this.props.listIdTickets}
                        planList={this.props.planList}
                        updateBasket={this.props.getBasket}
                      />
                    )}
                    {isShowOrderPayment && (
                      <OrderPayment
                        showOrderStep3={() => this.showOrderStep3()}
                        typesTemplsList={this.state.typesTemplsList}
                        changeTempls={this.changeTempls}
                        typeTicketId={this.state.typeTicketId}
                        orderCreateSell={this.orderCreateSell}
                        orderId={this.state.orderId}
                        orderDelete={this.orderDelete}
                        coast={this.state.coast}
                        orderCreatePayment={this.orderCreatePayment}
                        activeLoader={this.activeLoader}
                        hideLoader={this.hideLoader}
                        updateOrder={this.updateOrder}
                      />
                    )}
                    {isShowOrderPrint && (
                      <OrderPrint
                        orderId={this.state.orderId}
                        orderDisabled={this.orderDisabled}
                        typeTicketId={this.state.typeTicketId}
                        ticket_template_type_id={this.state.ticket_template_type_id}
                        hideOrder={this.checkOrder}
                        activeLoader={this.activeLoader}
                        hideLoader={this.hideLoader}
                      />
                    )}
                    {isShowOrderLayoutTicket && (
                      <OrderLayoutTicket
                        typesTemplsList={this.state.typesTemplsList}
                        showOrderStep3={() => this.showOrderStep3()}
                        changeTempls={this.changeTempls}
                        orderCreateManufacturing={this.orderCreateManufacturing}
                      />
                    )}
                    {isOrderCashless && (
                      <OrderCashless
                        showOrderStep3={() => this.showOrderStep3()}
                        orderCreateCashless={this.orderCreateCashless}
                        setDataOrderInfo={this.setDataOrderInfo}
                        dataOrderInfo={this.state.dataOrderInfo}
                      />
                    )}
                    {isOrderCashlessSuccess && <OrderCashlessSuccess
                      dataOrderInfo={this.state.dataOrderInfo}
                    />}
                    {isOrderReservation && (
                      <OrderReservation
                        setReserved={this.setDataOrderInfo}
                        setAccessSteps={this.setAccessSteps}
                        reserved={this.state.dataOrderInfo}
                        showOrderStep3={() => this.showOrderStep3()}
                        activeLoader={this.activeLoader}
                        hideLoader={this.hideLoader}
                      />
                    )}
                    {isOrderReservationSuccess && (
                      <OrderReservationSuccess
                        reserved={this.state.dataOrderInfo}
                        checkOrder={this.checkOrder}
                        showOrderReservationEmail={() =>
                          this.setShowPage('isOrderReservationEmail')
                        }
                      />
                    )}
                    {isOrderReservationEmail && <OrderReservationEmail />}
                  </>
                </section>
                {isShowSidebar && (
                  <aside className="modal-sidebar">
                    {isShowOrderInfo && <OrderInfo />}
                    {isShowEventBox && (
                      <EventBox
                        date={this.props.eventDate}
                        time={this.props.eventTime}
                        name={this.props.eventName}
                        location={this.props.eventLocation}
                      />
                    )}
                    {isShowOrderBasket && (
                      <OrderBasket
                        coast={this.state.coast}
                        orderType={this.state.orderType}
                        coastPromocodes={this.state.coastPromocodes}
                        isShowOrderBasketZero={isShowOrderBasketZero}
                        listIdTickets={this.props.listIdTickets}
                        showOrderStep2={() => this.showOrderStep2()}
                      />
                    )}
                    {isShowOrderPaymentBasket && <OrderPaymentBasket
                      coast={this.state.coast}
                      coastPromocodes={this.state.coastPromocodes}
                      listIdTickets={this.props.listIdTickets}
                      deals={this.state.deals}
                    />}
                  </aside>
                )}
              </div>
              <div className="modal-close" onClick={this.checkOrder}>
                <span className="icon icon-close"></span>
              </div>
            </>
          </div>
        </div>
        {this.state.modal.error && (
          <Modal isClose hideModal={this.closeModal}>
            <div>{this.state.modal.text}</div>
          </Modal>
        )}
                <ToastsContainer position={ToastsContainerPosition.TOP_RIGHT} store={ToastsStore} />
      </>
    );
  }
}

export default withRouter(Order);
