import { error } from "../containers/const";
import serviceApi from "./service";

export const TicketReserve = (ticket) =>
  serviceApi(`/ticket-wp-api/v1/ticket-reserve/`, ticket)
    .then(function (response) {
      return response.data;
    })
    .catch(err => {
      return error.notFound;
    });
export const TicketDelete = basket_id =>
  serviceApi(`/ticket-wp-api/v1/ticket-delete/`, {
    basket_id
  })
    .then(function (response) {
      return response.data;
    })

export const SetPromoTicket = (basket_id, discount, promo) => {
  return serviceApi(`/ticket-wp-api/v1/ticket-check-promo/`, {
    basket_id: basket_id,
    discount: discount,
    promo
  })
}