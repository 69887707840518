import { createSelector } from "reselect";
// import _ from 'lodash';

const getSearchInput = state => state.places.search;
const getPlacesArray = state => state.places.list;

export const newFilteredArrayPlaces = () => {
    return createSelector(
        [getSearchInput, getPlacesArray],
        (searchInput, arrayPlaces) => {
            let sortedCities = (arrayPlaces.filter(str => str.descriptions[0].name.toLowerCase().includes(searchInput.toLowerCase())));
            return sortedCities;
        }
    )
}
